import React, { useEffect, useState } from "react";
import Header from "../../Template/Header";
import { Link, useNavigate } from "react-router-dom";
import {useFormik } from "formik";
import { staffSchemaValidation } from "../../Validations/FormValidation";
import Swal from "sweetalert2";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import PreviewImage from "../../Common/PreviewImage";
import Select from "react-select";

const initialStaffValues = {
  FirmID: "",
  DepartmentId: "",
  SubDepartmentId: "",
  EmployeeName: "",
  EmployeeEmail: "",
  EmployeeMobile: "",
  EmployeeAddress: "",
  VisaId: "",
  VisaExpiration: "",
  EmpPhoto: "",
  EmpIdProof: "",
  RmId: "",
  Designation: "",
  Salary: "",
  OfficeTime: "",
};

const AddStaff = () => {

  const [allFirmsData, setAllFirmsData] = React.useState([]);
  const [allDepartmentsData, setAllDepartmentsData] = React.useState([]);
  const [allSubDepartmentsData, setAllSubDepartmentsData] = React.useState([]);
  const [allStaffData, setAllStaffData] = React.useState([]);
  const [subDepartmentOption, setSubDepartmentOption] = React.useState([]);
  
  const navigate = useNavigate();

  /** FETCH ALL FIRMS */
  const listFirms = async () => {
		try {
			const response = await axios.get(
				`${constant.SERVER_BASE_URL}firm/get-firms`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        }
			);
			setAllFirmsData(response.data);
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

  /** FETCH ALL DEPARTMENTS */
  const listDepartments = async () => {
		try {
			const response = await axios.get(
				`${constant.SERVER_BASE_URL}department/get-departments`, {
        headers: {
          "Content-Type":"application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        }
			);
			setAllDepartmentsData(response.data);
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

  /** FETCH ALL SUB DEPARTMENTS */
  const listSubDepartments = async () => {
    try {
      const response = await axios.get(
        `${constant.SERVER_BASE_URL}subdepartment/get-subdepartments`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        }
      );
      setAllSubDepartmentsData(response.data);
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong!!", {
            position: "top-center",
            className: "text-center",
        })
    }
  }

  const saveStaff = useFormik(
    {
      initialValues: initialStaffValues,
      validationSchema: staffSchemaValidation,
      onSubmit: (values, action) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to save this Staff!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Save it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
              const formData = new FormData();
              formData.append("EmpPhoto", values.EmpPhoto);
              formData.append("EmpIdProof", values.EmpIdProof);
              formData.append("FirmID", values.FirmID.value);
              formData.append("DepartmentId", values.DepartmentId.value);
              formData.append("SubDepartmentId", values.SubDepartmentId.value);
              formData.append("EmployeeName", values.EmployeeName);
              formData.append("EmployeeEmail", values.EmployeeEmail);
              formData.append("EmployeeMobile", values.EmployeeMobile);
              formData.append("EmployeeAddress", values.EmployeeAddress);
              formData.append("VisaId", values.VisaId);
              formData.append("VisaExpiration", values.VisaExpiration);
              formData.append("Designation", values.Designation);
              formData.append("RmId", values.RmId);
              formData.append("Salary", values.Salary);
              formData.append("OfficeTime", values.OfficeTime);

            await axios
            .post(`${constant.SERVER_BASE_URL}staff/save-employee`, formData,{
              headers:{
                "Content-Type":"multipart/form-data",
                authorization:`Bearer ${localStorage.getItem('token')}`
              }
            }).then((resp) => {
                console.log('form data uploaded',resp.data);
                if ("_id" in resp.data) {
                  toast.success(
                    `The Staff ${values.EmployeeName} added successfully`,
                    {
                      position: "top-center",
                    }
                  );
                  action.resetForm();
                  navigate("/list-staff");
                }
              }).catch((error) => {
                console.log(error);
                toast.error("Something went wrong!!", {
                  position: "top-center"
                });
              });
          }
        });
      },
    },
    []
  );

  const listStaffs = async () => {
      
    try {
        await axios.get(
            `${constant.SERVER_BASE_URL}staff/get-employees`, {
                headers: {
                    "Content-Type":"application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }).then((resp)=>{
                setAllStaffData(resp.data);
            });
        
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong!!", {
            position: "top-center",
            className: "text-center",
        });
    }
};

  useEffect(() => {
    listFirms();
    listDepartments();
    listSubDepartments();
    listStaffs();
  }, []);  

  var departmentOptions=allDepartmentsData.map(item=>({
    value:item._id,
    label:item.departmentName
  }));
 

  var firmOptions=allFirmsData.map(item=>({
    value:item._id,
    label:item.firmName
  }));



  var checkDesignation = ["ADMIN", "SUPER-ADMIN","MANAGER","TEAM-LEADER","DEPOSIT","WITHDRAWAL","CALLER"];
  
  if(checkDesignation.indexOf(saveStaff.values.Designation)<2){
    saveStaff.values.isFirm=false;
    saveStaff.values.isDepartment=false;
    saveStaff.values.isSubDepartment=false;
  }else{
    saveStaff.values.isFirm=true;
    saveStaff.values.isDepartment=true;
    saveStaff.values.isSubDepartment=true;
  }
  
  const handleDepartmentChange=(e)=>{
    var deptId=e.target.value.value;
      let filteredSubDepart = allSubDepartmentsData.filter((item) => {
        return item.departmentId._id===deptId;
    });
    var  subDeptOption=filteredSubDepart.map(item=>({
      value:item._id,
      label:item.subDepartmentName
    }));

    setSubDepartmentOption(subDeptOption);
  }

  return (
    <div className="content">
      <Header />

      <div className="card mb-3">
        <div className="card-header">
          <div className="row flex-between-end">
            <div className="col-auto align-self-center">
              <h5 className="mb-0" data-anchor="data-anchor" id="example">
                Add Staff
                <a
                  className="anchorjs-link "
                  aria-label="Anchor"
                  data-anchorjs-icon="#"
                  href="#example"
                  style={{
                    marginLeft: "0.1875em",
                    paddingRight: "0.1875em",
                    paddingLeft: "0.1875em",
                  }}
                />
              </h5>
            </div>
            <div className="col-auto ms-auto">
              <div
                className="nav nav-pills nav-pills-falcon flex-grow-1"
                role="tablist"
              >
                <Link className="btn btn-sm btn-outline-info" to="/list-staff">
                  {" "}
                  <i className="fas fa-user"></i> List Staff
                </Link>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={saveStaff.handleSubmit}>
          <div className="card-body bg-body-tertiary">
            <div className="tab-content">
              <div
                className="tab-pane preview-tab-pane active"
                role="tabpanel"
                aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
                id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
              >
                <div className="row">
                    <div className="col">
                        
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="exampleFormControlTextarea1"
                          >
                            Designation
                          </label>
                          <select
                            className="form-control"
                            name="Designation"
                            value={saveStaff.values.Designation}
                            onChange={saveStaff.handleChange}
                          >
                            <option value="">Select</option>
                            <option value={"SUPER-ADMIN"}>SUPER ADMIN</option>
                            <option value={"ADMIN"}>ADMIN</option>
                            <option value={"MANAGER"}>MANAGER</option>
                            <option value={"TEAM-LEADER"}>TEAM-LEADER</option>
                            <option value={"DEPOSIT"}>DEPOSIT</option>
                            <option value={"WITHDRAWAL"}>WITHDRAWAL </option>
                            <option value={"CALLER"}>CALLER</option>
                            <option value={"CHECKER"}>CHECKER</option>
                            <option value={"GRAPHICS DESIGNER"}>GRAPHICS DESIGNER</option>
                          </select>
                          { saveStaff.errors.Designation && saveStaff.touched.Designation ? (
                            <div
                              className="alert alert-danger small alert-dismissible p-1"
                              role="alert"
                            >
                              {saveStaff.errors.Designation}
                            </div>
                          ) : null}
                        </div>
                      </div>
                </div>
                { checkDesignation.indexOf(saveStaff.values.Designation)>1?
                <div className="row">
                  
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Select Firm
                      </label>

                      <Select 
                          name="FirmID"
                          id="FirmID"
                          placeholder="Select"
                          onBlur={saveStaff.handleBlur}
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "FirmID",
                                value: selectedOption,
                              },
                            };
                            saveStaff.handleChange(event);
                          }}
                          options={firmOptions}
                        />

                      {saveStaff.errors.FirmID && saveStaff.touched.FirmID ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.FirmID.label}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Select Sub-Department(optional)
                      </label>
                      <Select 
                          name="SubDepartmentId"
                          id="SubDepartmentId"
                          placeholder="Select"
                          onBlur={saveStaff.handleBlur}
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "SubDepartmentId",
                                value: selectedOption,
                              },
                            };
                            saveStaff.handleChange(event);
                          }}
                          options={subDepartmentOption}
                        />


                     
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Select Department
                      </label>
                     
                        <Select 
                          name="DepartmentId"
                          id="DepartmentId"
                          placeholder="Select"
                          onBlur={saveStaff.handleBlur}
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "DepartmentId",
                                value: selectedOption,
                              },
                            };
                            saveStaff.handleChange(event);
                            handleDepartmentChange(event);
                          }}
                          options={departmentOptions}
                        />
                      {saveStaff.errors.DepartmentId && saveStaff.touched.DepartmentId ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.DepartmentId.label}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                  :null
                }
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Employee Name
                      </label>
                      <input
                        className="form-control"
                        placeholder="i.e. John Doe"
                        name="EmployeeName"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.EmployeeName}
                      />
                      {saveStaff.errors.EmployeeName && saveStaff.touched.EmployeeName ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.EmployeeName}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Employee Mobile No.
                      </label>
                      <input
                        className="form-control"
                        placeholder="i.e. 8078990989"
                        name="EmployeeMobile"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.EmployeeMobile}
                      />
                      {saveStaff.errors.EmployeeMobile && saveStaff.touched.EmployeeMobile ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.EmployeeMobile}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Employee Email
                      </label>
                      <input
                        className="form-control"
                        placeholder="john.doe@gmail.com"
                        name="EmployeeEmail"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.EmployeeEmail}
                      />
                      {saveStaff.errors.EmployeeEmail && saveStaff.touched.EmployeeEmail ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.EmployeeEmail}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Employee Address
                      </label>
                      <input
                        className="form-control"
                        placeholder="i.e. 76/980, Smart City, USA,110000"
                        name="EmployeeAddress"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.EmployeeAddress}
                      />
                      {saveStaff.errors.EmployeeAddress && saveStaff.touched.EmployeeAddress ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.EmployeeAddress}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        VISA Id
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter Your VISA Id"
                        name="VisaId"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.VisaId}
                      />
                      {saveStaff.errors.VisaId && saveStaff.touched.VisaId ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.VisaId}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        VISA Valid Till
                      </label>
                      <input
                        className="form-control"
                        name="VisaExpiration"
                        type="date"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.VisaExpiration}
                      />
                      {saveStaff.errors.VisaExpiration && saveStaff.touched.VisaExpiration ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.VisaExpiration}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Upload Photo
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        name="EmpPhoto"
                        formEncType="multipart/form-data"
                        onChange={(event) => {
                          saveStaff.setFieldValue(
                            "EmpPhoto",
                            event.currentTarget.files[0]
                          );
                        }}
                        onBlur={saveStaff.handleBlur}
                      />
                      {/* saveStaff.errors.EmpPhoto && saveStaff.touched.EmpPhoto ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.EmpPhoto}
                        </div>
                      ) : null */}
                      <div className="input-group-append">
                    {saveStaff.values.EmpPhoto?<PreviewImage file={saveStaff.values.EmpPhoto} />:null}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Upload Id Proof
                      </label>
                      <input
                        className="form-control"
                        name="EmpIdProof"
                        type="file"
                        formEncType="multipart/form-data"
                        onChange={(event) => {
                          saveStaff.setFieldValue(
                            "EmpIdProof",
                            event.currentTarget.files[0]
                          );
                        }}
                        onBlur={saveStaff.handleBlur}
                      />
                      {/* saveStaff.errors.EmpIdProof && saveStaff.touched.EmpIdProof ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.EmpIdProof}
                        </div>
                      ) : null */}
                      <div className="input-group-append">
                    {saveStaff.values.EmpIdProof?<PreviewImage file={saveStaff.values.EmpIdProof} />:null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        R.M.
                      </label>
                      <select
                        className="form-control"
                        name="RmId"
                        value={saveStaff.values.RmId}
                        onChange={saveStaff.handleChange}
                      >
                        <option value="">Select</option>
                        {allStaffData
													? allStaffData.map((item) =>
																	<option key={item._id} value={item._id}>
																	{item.EmployeeName}
																	</option>										
													  )
													: null}
                        
                      </select>
                      {/* saveStaff.errors.RmId && saveStaff.touched.RmId ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.RmId}
                        </div>
                      ) : null */}
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Salary
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="Salary"
                        placeholder="Enter Your Salary"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.Salary}
                      />
                      {saveStaff.errors.Salary && saveStaff.touched.Salary ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.Salary}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Office From Time
                      </label>
                      <input
                        className="form-control"
                        name="OfficeTime"
                        type="time"
                        onChange={saveStaff.handleChange}
                        onBlur={saveStaff.handleBlur}
                        value={saveStaff.values.OfficeTime}
                      />
                      {saveStaff.errors.OfficeTime && saveStaff.touched.OfficeTime ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveStaff.errors.OfficeTime}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <button
                        className="btn btn-sm btn-outline-info"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStaff;
