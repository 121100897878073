import React, { useEffect } from "react";
import Header from "../../Template/Header";
import * as constant from "../../../Constant";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Skeleton from "../../Template/Skeleton";


const ListCaller =  () => {
	const [callers,setCallers]=React.useState([]);
	let [serialNo, setSerialNo] = React.useState(1);
	const [viewskeleton,setViewskeleton]=React.useState(true);

	
	const getCallerDetails= async ()=>{
	await axios.get(`${constant.SERVER_BASE_URL}staff/get-caller-details`,
        {
			headers:{
				"Content-Type":"application/json",
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
	}).then((resp)=>{
        if(resp.data.status==="SUCCESS"){
			setViewskeleton(false);
            setCallers(resp.data.value);
        }else{
			setViewskeleton(false);

			
            toast.error(resp.data.message, {
                position: "top-center",
                className: "text-center",
              });
        }
		
	});
}

const deleteCaller=(uuid,caller_name)=>{
	// console.log(uuid);
	Swal.fire({
        title: 'Are you sure?',
        text: `You want to delete caller ${caller_name}!!`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
        }).then(async (result) => {
        if (result.isConfirmed) {

			await axios.delete(`${constant.SERVER_BASE_URL}staff/delete-caller`,{
				
				params:{uuid:uuid},
				headers:{
					"Content-Type":"application/json",
					authorization:`Bearer ${localStorage.getItem('token')}`
				}
				
			}).then((resp)=>{
				console.log(resp);
				if(resp.data.status==="success"){
					getCallerDetails();
					toast.success(resp.data.message, {
						position: "top-center",
						className: "text-center",
					  });
					  
				}else{
					toast.error(resp.data.message, {
						position: "top-center",
						className: "text-center",
					  });
				}
			})

		}
	});
}

    

	useEffect(()=>{
		getCallerDetails();
	},[]);

	return (
		<div className="content">
			<Header />
	<div className="card">
		<div className="card-header border-bottom">
			<div className="row flex-between-end">
				<div className="col-auto align-self-center">
					<h5	className="mb-0" >List Caller</h5>
				</div>
                <div className="col-auto align-self-center">
					<Link to={'/add-caller'}	className="btn btn-outline-primary btn-sm" ><i className="fas fa-plus-circle"></i> Add Caller</Link>
				</div>
			</div>
		</div>
		<div className="card-body pt-0">
			<div className="tab-content">
				<div
					className="tab-pane preview-tab-pane active">
						{viewskeleton?<Skeleton />:
					<div className="table-responsive scrollbar">
						<table className="table table-hover table-striped overflow-hidden">
							<thead>
								<tr>
									<th scope="col">S.No.</th>
									<th scope="col">Name</th>
									<th scope="col">Mobile No.</th>
									<th scope="col">UUID</th>
									<th scope="col">Extension</th>
									<th scope="col">Commission_Type</th>
									<th scope="col">Commission_Value</th>
									<th	scope="col"	className="text-nowrap text-center">Action</th>
								</tr>
							</thead>
							<tbody>

                            { callers.length>0?callers.map((item)=>
								<tr	className="align-middle" key={item._id}>
									<td className="text-nowrap">
										<div className="d-flex align-items-center">
											<div className="ms-2">{serialNo++}</div>
										</div>
									</td>
									<td className="text-nowrap">{item.caller_name}</td>
									<td className="text-nowrap">{item.caller_number}</td>
									<td className="text-nowrap">{item.uuid}</td>
									<td className="text-nowrap">{item.extension}</td>
									<td className="text-nowrap">{item.commissiontype}</td>
									<td className="text-nowrap">{item.commissiontype==="FIXED_AMOUNT"?item.fixedAmount:item.percentage}</td>
									<td>
                                        <div className="d-inline-flex">
                                       		 <Link to={`/edit-caller/${item._id}`} className="btn btn-outline-info btn-sm me-2"><i className="fas fa-edit"></i></Link>
											 <button className="btn btn-outline-danger btn-sm" onClick={deleteCaller.bind(this,`${item.uuid}`,`${item.caller_name}`)}><i className="fa fa-trash"></i></button>
                                        </div>
                                    </td>
								</tr>
                            ):
								<tr key="">
									<td colSpan={8}>No records found</td>
								</tr>
                                }


							</tbody>
						</table>
					</div>
				}
				</div>
			</div>
		</div>
	</div>
</div>
);
};

export default ListCaller;
