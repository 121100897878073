import React from 'react'
import Header from "./Template/Header";
import * as constant from "../Constant";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Profile = () => {
  const [profile,setProfile]=React.useState({});
  const auth = localStorage.getItem("adminLogin");
 const authJSON=JSON.parse(auth);
 const redirect=useNavigate();

  const profiledata=async ()=>{

      await axios.get(`${constant.SERVER_BASE_URL}staff/get-employee/${authJSON._id}`,
        {
          headers:{
            "Content-Type":"application/json",
            authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }).then((resp)=>{
          setProfile(resp.data);
        });
  };

  useEffect(()=>{
    profiledata();
  },[]);


  
  return (
    <div className="content">
      <Header />
      <div className=" mb-3">


      <div className="card mb-3">
            <div className="card-header position-relative min-vh-25 mb-7">
              <img src="" alt="" />
              <div className="bg-holder profile-bg rounded-3 rounded-bottom-0">
                <img src={`${constant.CLIENT_BASE_URL}assets/img/generic/profile-bg.jpg`} style={{height:'200px',width:'100%'}} alt="" />
              </div>
              <div className="avatar avatar-5xl avatar-profile">
                <img className="rounded-circle img-thumbnail shadow-sm" src={`${constant.SERVER_BASE_URL}images/${profile.EmployeePhoto}`} width="200" alt=""/></div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="mb-1"> {profile.EmployeeName?profile.EmployeeName:null}<span data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Verified" data-bs-original-title="Verified"><svg className="svg-inline--fa fa-check-circle fa-w-16 text-primary" data-fa-transform="shrink-4 down-2" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{transformOrigin: "0.5em 0.625em;"}}><g transform="translate(256 256)"><g transform="translate(0, 64)  scale(0.75, 0.75)  rotate(0 0 0)"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" transform="translate(-256 -256)"></path></g></g></svg></span></h4>
                  <h5 className="fs-9 fw-normal">{profile.Designation?profile.Designation:null}</h5>
                  <p className="text-500">{profile.EmployeeAddress?profile.EmployeeAddress:null}</p><button className="btn btn-falcon-primary btn-sm px-3" type="button">History</button><button className="btn btn-falcon-default btn-sm px-3 ms-2" type="button">Salary Bifurcation</button>
                  <div className="border-bottom border-dashed my-4 d-lg-none"></div>
                </div>
                <div className="col ps-2 ps-lg-3">
                 
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/email.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.EmployeeEmail?profile.EmployeeEmail:null}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/phone-call.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.EmployeeMobile?profile.EmployeeMobile:null}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/location.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.EmployeeAddress?profile.EmployeeAddress:null}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/office.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.DepartmentId?profile.DepartmentId.departmentName:null}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/office.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.SubDepartmentId?profile.SubDepartmentId.subDepartmentName:'---'}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/firm.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.FirmID?profile.FirmID.firmName:'---'}</h6>
                    </div>
                  </div>
                 
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/id-card.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.VisaId?profile.VisaId:'---'}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/id-card.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.VisaExpiration?profile.VisaExpiration:'---'}</h6>
                    </div>
                  </div>
                  
                  <div className="d-flex align-items-center mb-2">
                    <img className="align-self-center me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/time.png`} alt="Generic placeholder image" width="30"/>
                    <div className="flex-1">
                      <h6 className="mb-0">{profile.OfficeTime?profile.OfficeTime:'---'}</h6>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>

      </div>
    </div>
  )
}

export default Profile;
