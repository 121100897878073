import React from "react";
import Header from "../../Template/Header";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import {useFormik} from "formik";
import {addFirmValidation} from "../../Validations/FormValidation";

const initialFirmValues={
  firmName:"",
  firmAddress:""
};

const AddFirm=()=>{

  const navigate = useNavigate();

  const saveFirm=useFormik({
    initialValues:initialFirmValues,
    validationSchema:addFirmValidation,
    onSubmit:(values,action)=>{
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to save this Firm!!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post(
            `${constant.SERVER_BASE_URL}firm/save-firm`,
            values, {
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
          ).then((resp)=>{
            console.log(resp.data);
            if("_id" in resp.data){
              toast.success(`The Firm ${values.firmName} added successfully`,{
                position: "top-center"
              });
              action.resetForm();
              navigate("/list-firm");
            } else if (resp.data === '') {
              throw new Error();
            }
          }).catch((error)=>{
            console.log(error);
            toast.error("Something went wrong!!", {
              position: "top-center",
              className: "text-center"
            });
          }) 
        }
      });

    }
  },[]);


    return (
        <div className="content">
             <Header />
  
  <div className="card mb-3">
    <form className="form-horizontal" onSubmit={saveFirm.handleSubmit}>
    <div className="card-header">
      <div className="row flex-between-end">
        <div className="col-auto align-self-center">
          <h5 className="mb-0" data-anchor="data-anchor" id="example">Add Firm<a className="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#example" style={{marginLeft: '0.1875em', paddingRight: '0.1875em', paddingLeft: '0.1875em'}} /></h5>
        </div>
        <div className="col-auto ms-auto">
          <div className="nav nav-pills nav-pills-falcon flex-grow-1" role="tablist">
             <Link className="btn btn-sm btn-outline-info" to="/list-firm" > <i className="fas fa-user"></i> List Firm</Link>
            </div>
        </div>
      </div>
    </div>

    <div className="card-body bg-body-tertiary">
      <div className="tab-content">
        <div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e" id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e">
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">Firm Name</label>
            <input className="form-control" id="firm_name" value={saveFirm.values.firmName} onBlur={saveFirm.handleBlur} onChange={saveFirm.handleChange} name="firmName" type="text" placeholder="Enter Firm Name" />
            {
                saveFirm.errors.firmName && saveFirm.touched.firmName?<div class="alert alert-danger small alert-dismissible" role="alert">{saveFirm.errors.firmName}</div>:null
              }
            </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">Firm Address</label>
            <input className="form-control" onBlur={saveFirm.handleBlur} onChange={saveFirm.handleChange} value={saveFirm.values.firmAddress} id="firmAddress" name="firmAddress" type="text" placeholder="Enter Firm Address" />
            {
                saveFirm.errors.firmAddress && saveFirm.touched.firmAddress?<div class="alert alert-danger small alert-dismissible" role="alert">{saveFirm.errors.firmAddress}</div>:null
              }
            </div>
          <button className="btn btn-sm btn-outline-info" type="submit"> <i className="fas fa-save"></i> Save</button>
        </div>
      
      </div>
    </div>
    </form>

  </div>
  
  <footer className="footer">
    <div className="row g-0 justify-content-between fs-10 mt-4 mb-3">
      <div className="col-12 col-sm-auto text-center">
        <p className="mb-0 text-600">Thank you for creating with Falcon <span className="d-none d-sm-inline-block">| </span><br className="d-sm-none" /> 2024 © <a href="https://themewagon.com">Themewagon</a></p>
      </div>
      <div className="col-12 col-sm-auto text-center">
        <p className="mb-0 text-600">v3.21.0</p>
      </div>
    </div>
  </footer>

</div>

    );
};

export default AddFirm;