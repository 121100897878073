import React, { useEffect } from "react";
import Header from "../../Template/Header";
import * as constant from "../../../Constant";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
const ListDepartment = () => {

	const [subDepartmentRecords, setSubDepartmentRecords] = React.useState([]);
	let [serialNo, setSerialNo] = React.useState(1);

	/** FETCH ALL DEPARTMENTS */
	const listSubDepartments = async () => {
		try {
			const response = await axios.get(
				`${constant.SERVER_BASE_URL}subdepartment/get-subdepartments`,
				{
					headers: {
						"Content-Type":"application/json",
						authorization: `Bearer ${localStorage.getItem("token")}`,
					}
				}).then((resp)=>{
					setSubDepartmentRecords(resp.data);
				});
			
		} catch (error) {
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};
	/** DELETE DEPARTMENT */
	const deleteSubDepartment = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You want to delete this sub-department!!",
			icon: "error",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await axios.patch(
					`${constant.SERVER_BASE_URL}subdepartment/delete-subdepartment/${id}`,
					{
						isDeleted: true,
					},
					{
						headers: {
							"Content-Type":"application/json",
							authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					}
				).then((resp) => {
					
					if (resp.data.status==="SUCCESS") {
						Swal.fire(
							"Deleted!",
							"Sub-Department has deleted.",
							"success"
						);
						listSubDepartments();
					} else {
						toast.error("Can not deleted!!", {
							position: "top-center",
							className: "text-center",
						})
					}
				}).catch((error) => {
					console.log(error);
					toast.error("Something went wrong!!", {
						position: "top-center",
						className: "text-center",
					});
				})
			}
		}).catch((error) => {
			console.log(error);
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		});
	};

	useEffect(() => {
		listSubDepartments();
	}, []);

	// console.log(allDepartmentsData)

	return (
		<div className="content">
			<Header />
			<div className="card">
				<div className="card-header border-bottom">
					<div className="row flex-between-end">
						<div className="col-auto align-self-center">
							<h5
								className="mb-0"
								data-anchor="data-anchor"
								id="responsive-table">
								List Sub-Department
								<a
									className="anchorjs-link "
									aria-label="Anchor"
									data-anchorjs-icon="#"
									href="#responsive-table"
									style={{
										marginLeft: "0.1875em",
										paddingRight: "0.1875em",
										paddingLeft: "0.1875em",
									}}
								/>
							</h5>
						</div>
						<div className="col-auto ms-auto">
							<div
								className="nav nav-pills nav-pills-falcon flex-grow-1 mt-2"
								role="tablist"
							>
								<Link
									className="btn btn-sm btn-outline-info"
									to="/add-department"
								>
									<i className="fas fa-plus-circle"></i> Add Sub
									Department
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body pt-0">
					<div className="tab-content">
						<div
							className="tab-pane preview-tab-pane active"
							role="tabpanel"
							aria-labelledby="tab-dom-1c5a7061-098a-4658-a94b-061184a7cbbb"
							id="dom-1c5a7061-098a-4658-a94b-061184a7cbbb"
						>
							<div className="table-responsive scrollbar">
								<table className="table table-hover table-striped overflow-hidden">
									<thead>
										<tr>
											<th scope="col">S.No.</th>
											<th scope="col">Department</th>
											<th scope="col">Sub-Department</th>
											<th scope="col" className="text-nowrap text-center">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{subDepartmentRecords
											? subDepartmentRecords.map((item) =>
										
															<tr
																className="align-middle"
																key={
																	item._id
																}
															>
																<td className="text-nowrap">
																	<div className="d-flex align-items-center">
																		<div className="ms-2">
																			{
																				serialNo++
																			}
																		</div>
																	</div>
																</td>
																
																<td className="text-nowrap">
																	{
																		item.departmentId.departmentName
																	}
																</td>
																<td className="text-nowrap">
																	{
																		item.subDepartmentName
																	}
																</td>
																<td>
																	<span className="badge badge rounded-pill d-block pr-2">
																		<Link
																			className="btn btn-sm btn-outline-info"
																			to={`/edit-sub-department/${item._id}`}
																		>
																			<i className="fas fa-edit"></i>
																		</Link>{" "}
																		<button
																			className="btn btn-sm btn-outline-danger"
																			onClick={deleteSubDepartment.bind(
																				this,
																				item._id
																			)}
																		>
																			<i className="fas fa-trash"></i>
																		</button>
																	</span>
																</td>
															</tr>
														
											)
											: null}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListDepartment;
