import React, { useEffect } from "react";
import Header from "../../Template/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { staffSchemaValidation } from "../../Validations/FormValidation";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import PreviewImage from "../../Common/PreviewImage";

const initialStaffValues = {
	FirmId: "",
	DepartmentId: "",
	SubDepartmentId: "",
	EmployeeName: "",
	EmployeeEmail: "",
	EmployeeMobile: "",
	EmployeeAddress: "",
	VisaId: "",
	VisaExpiration: "",
	EmpPhoto: null,
	EmpIdProof: null,
	RmId: "",
	Designation: "",
	Salary: "",
	OfficeTime: "",
};

const EditStaff = () => {

    const {staffId} = useParams();
	const [allFirmsData, setAllFirmsData] = React.useState([]);
	const [allDepartmentsData, setAllDepartmentsData] = React.useState([]);
	const [allSubDepartmentsData, setAllSubDepartmentsData] = React.useState([]);
	const [imagedata,setImagedata]=React.useState({});
	const [allStaffData, setAllStaffData] = React.useState([]);
	const navigate = useNavigate();

	/** FETCH ALL FIRMS */
	const listFirms = async () => {
		try {
			await axios.get(
				`${constant.SERVER_BASE_URL}firm/get-firms`,{
					headers:{
						"Content-Type":"application/json",
						authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					setAllFirmsData(resp.data);
				});
			
		} catch (error) {
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

	/** FETCH ALL DEPARTMENTS */
	const listDepartments = async () => {
		try {
			await axios.get(
				`${constant.SERVER_BASE_URL}department/get-departments`,{
					headers:{
						"Content-Type":"application/json",
						authorization:	`Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					setAllDepartmentsData(resp.data);
				});
		} catch (error) {
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

    const getCurrentStaff = async () => {
        try {
            await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employee/${staffId}`,{
					headers:{
						"Content-Type":"application/json",
						authorization:`Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					console.log(resp.data);

					setImagedata({photo:resp.data.EmployeePhoto,idproof:resp.data.EmployeeIdProof});
					editStaff.setValues({
						FirmId: resp.data.FirmID?resp.data.FirmID._id:null,
						DepartmentId: resp.data.DepartmentId?resp.data.DepartmentId._id:null,
						SubDepartmentId: resp.data.SubDepartmentId,
						EmployeeName: resp.data.EmployeeName,
						EmployeeEmail: resp.data.EmployeeEmail,
						EmployeeMobile: resp.data.EmployeeMobile,
						EmployeeAddress: resp.data.EmployeeAddress,
						VisaId: resp.data.VisaId,
						VisaExpiration: resp.data.VisaExpiration,
						RmId: resp.data.RmId,
						Designation: resp.data.Designation,
						Salary: resp.data.Salary,   
						OfficeTime: resp.data.OfficeTime,
					});

				});
				
            
        } catch (error) {
            console.log(error);
        }
    }
	/** FETCH ALL SUB DEPARTMENTS */
	const listSubDepartments = async () => {
		try {
			const response = await axios.get(
				`${constant.SERVER_BASE_URL}subdepartment/get-subdepartments`
			);
			setAllSubDepartmentsData(response.data);
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

	const editStaff = useFormik(
		{
			initialValues: initialStaffValues,
			validationSchema: "",
			onSubmit: (values, action) => {
				Swal.fire({
					title: "Are you sure?",
					text: "You want to update this Staff!!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, Save it!",
				})
				.then(async (result) => {
					if (result.isConfirmed) {
					
						const formData = new FormData();
						formData.append("EmpPhoto", values.EmpPhoto);
						formData.append("EmpIdProof", values.EmpIdProof);
						formData.append("FirmID", values.FirmId);
						formData.append("DepartmentId", values.DepartmentId);
						formData.append("SubDepartmentId", values.SubDepartmentId);
						formData.append("EmployeeName", values.EmployeeName);
						formData.append("EmployeeEmail", values.EmployeeEmail);
						formData.append("EmployeeMobile", values.EmployeeMobile);
						formData.append("EmployeeAddress", values.EmployeeAddress);
						formData.append("VisaId", values.VisaId);
						formData.append("VisaExpiration", values.VisaExpiration);
						formData.append("Designation", values.Designation);
						formData.append("RmId", values.RmId);
						formData.append("Salary", values.Salary);
						formData.append("OfficeTime", values.OfficeTime);

					await axios.patch(`${constant.SERVER_BASE_URL}staff/update-employee/${staffId}`, values,{
						headers:{
							"Content-Type":"multipart/form-data",
							authorization: `Bearer ${localStorage.getItem('token')}`
						}
					}).then(async(resp) => {
							if(resp.data.status==="SUCCESS"){
								toast.success(resp.data.message, {
									position: "top-center",
									className: "text-center",
								});
							}else if(resp.data.status==="ERROR"){
								toast.error(resp.data.message, {
									position: "top-center",
									className: "text-center",
								});
							}
					})
					}
				});
			},
		},
		[]);

	const listStaffs = async () => {
		try {
			await axios.get(
				`${constant.SERVER_BASE_URL}staff/get-employees`, {
					headers: {
						"Content-Type":"application/json",
						authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}).then((resp)=>{
					setAllStaffData(resp.data);
				});
			
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

	useEffect(() => {
		listFirms();
		listDepartments();
		// listSubDepartments();
		listStaffs();
        getCurrentStaff();
	}, []);

	var checkDesignation = ["ADMIN", "SUPER-ADMIN","MANAGER","TEAM-LEADER","DEPOSIT","WITHDRAWAL","CALLER"];

	return (
		<div className="content">
			<Header />

			<div className="card mb-3">
				<div className="card-header">
					<div className="row flex-between-end">
						<div className="col-auto align-self-center">
							<h5
								className="mb-0"
								data-anchor="data-anchor"
								id="example"
							>
								Edit Staff
								<a
									className="anchorjs-link "
									aria-label="Anchor"
									data-anchorjs-icon="#"
									href="#example"
									style={{
										marginLeft: "0.1875em",
										paddingRight: "0.1875em",
										paddingLeft: "0.1875em",
									}}
								/>
							</h5>
						</div>
						<div className="col-auto ms-auto">
							<div
								className="nav nav-pills nav-pills-falcon flex-grow-1"
								role="tablist"
							>
								<Link
									className="btn btn-sm btn-outline-info"
									to="/list-staff"
								>
									{" "}
									<i className="fas fa-user"></i> List Staff
								</Link>
							</div>
						</div>
					</div>
				</div>
				<form onSubmit={editStaff.handleSubmit}>
					<div className="card-body bg-body-tertiary">
						<div className="tab-content">
							<div
								className="tab-pane preview-tab-pane active"
								role="tabpanel"
								aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
								id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
							>
								<div className="row">

								<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Designation
											</label>
											<select
												className="form-control"
												name="Designation"
												value={
													editStaff.values.Designation
												}
												onChange={
													editStaff.handleChange
												}
											>
												<option value="">Select</option>
												<option value={"SUPER-ADMIN"}>SUPER ADMIN</option>
												<option value={"ADMIN"}>ADMIN</option>
												<option value={"MANAGER"}>MANAGER</option>
												<option value={"TEAM-LEADER"}>TEAM-LEADER</option>
												<option value={"DEPOSIT"}>DEPOSIT</option>
												<option value={"WITHDRAWAL"}>WITHDRAWAL </option>
												<option value={"CALLER"}>CALLER</option>
											</select>
											{editStaff.errors.Designation &&
											editStaff.touched.Designation ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.Designation
													}
												</div>
											) : null}
										</div>
									</div>


								</div>

								{ checkDesignation.indexOf(editStaff.values.Designation)>1?
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlInput1"
											>
												Select Firm
											</label>
											<select
												className="form-control"
												name="FirmId"
												value={editStaff.values.FirmId}
												onChange={
													editStaff.handleChange
												}
											>
												<option value="">Select</option>
												{allFirmsData
													? allFirmsData.map(
															(firm) =>
																firm.isDeleted ===
																	false && (
																	<option
																		key={
																			firm._id
																		}
																		value={
																			firm._id
																		}
																	>
																		{
																			firm.firmName
																		}
																	</option>
																)
													  )
													: null}
											</select>
											{editStaff.errors.FirmId &&
											editStaff.touched.FirmId ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{editStaff.errors.FirmId}
												</div>
											) : null}
										</div>

										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Select Sub-Department(optional)
											</label>
											<select
												className="form-control"
												name="SubDepartmentId"
												value={
													editStaff.values
														.SubDepartmentId
												}
												onChange={
													editStaff.handleChange
												}
											>
												<option value="">Select</option>
												{allSubDepartmentsData
													? allSubDepartmentsData.map(
															(subDepartment) =>
																subDepartment.isDeleted ===
																	false && (
																	<option
																		key={
																			subDepartment._id
																		}
																		value={
																			subDepartment._id
																		}
																	>
																		{
																			subDepartment.subDepartmentName
																		}
																	</option>
																)
													  )
													: null}
											</select>
										
										</div>
									</div>

									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Select Department
											</label>
											<select
												className="form-control"
												name="DepartmentId"
												value={
													editStaff.values
														.DepartmentId
												}
												onChange={
													editStaff.handleChange
												}
											>
												<option value="">Select</option>
												{allDepartmentsData
													? allDepartmentsData.map(
															(department) =>
																	<option key={department._id} value={department._id} >{department.departmentName}</option>
																
													  )
													: null}
											</select>
											{editStaff.errors.DepartmentId &&
											editStaff.touched.DepartmentId ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.DepartmentId
													}
												</div>
											) : null}
										</div>
									</div>
								</div>
									:null
								}

								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Employee Name
											</label>
											<input
												className="form-control"
												placeholder="i.e. John Doe"
												name="EmployeeName"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={
													editStaff.values
														.EmployeeName
												}
											/>
											{editStaff.errors.EmployeeName &&
											editStaff.touched.EmployeeName ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.EmployeeName
													}
												</div>
											) : null}
										</div>

										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Employee Mobile No.
											</label>
											<input
												className="form-control"
												placeholder="i.e. 8078990989"
												name="EmployeeMobile"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={
													editStaff.values
														.EmployeeMobile
												}
											/>
											{editStaff.errors.EmployeeMobile &&
											editStaff.touched.EmployeeMobile ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.EmployeeMobile
													}
												</div>
											) : null}
										</div>
									</div>

									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Employee Email
											</label>
											<input
												className="form-control"
												placeholder="john.doe@gmail.com"
												name="EmployeeEmail"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={
													editStaff.values
														.EmployeeEmail
												}
											/>
											{editStaff.errors.EmployeeEmail &&
											editStaff.touched.EmployeeEmail ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.EmployeeEmail
													}
												</div>
											) : null}
										</div>

										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Employee Address
											</label>
											<input
												className="form-control"
												placeholder="i.e. 76/980, Smart City, USA,110000"
												name="EmployeeAddress"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={
													editStaff.values
														.EmployeeAddress
												}
											/>
											{editStaff.errors.EmployeeAddress &&
											editStaff.touched
												.EmployeeAddress ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.EmployeeAddress
													}
												</div>
											) : null}
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												VISA Id
											</label>
											<input
												className="form-control"
												placeholder="Enter Your VISA Id"
												name="VisaId"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={editStaff.values.VisaId}
											/>
											{editStaff.errors.VisaId &&
											editStaff.touched.VisaId ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{editStaff.errors.VisaId}
												</div>
											) : null}
										</div>
									</div>
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												VISA Valid Till
											</label>
											<input
												className="form-control"
												name="VisaExpiration"
												type="date"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={
													editStaff.values
														.VisaExpiration
												}
											/>
											{editStaff.errors.VisaExpiration &&
											editStaff.touched.VisaExpiration ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.VisaExpiration
													}
												</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Upload Photo
											</label>
											<input
												className="form-control"
												type="file"
												name="EmpPhoto"
												onChange={(event) => {
													editStaff.setFieldValue(
														"EmpPhoto",
														event.currentTarget
															.files[0]
													);
												}}
												onBlur={editStaff.handleBlur}
											/>
											{editStaff.errors.EmpPhoto &&
											editStaff.touched.EmpPhoto ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{editStaff.errors.EmpPhoto}
												</div>
											) : null}
											<div className="input-group-append">
												{editStaff.values.EmpPhoto ? (
													<PreviewImage
														file={
															editStaff.values
																.EmpPhoto
														}
													/>
												) : null}
											</div>

											<div className="input-group-append">
												{imagedata.photo && !editStaff.values.EmpPhoto ? (
													<img src={`${constant.SERVER_BASE_URL}images/${imagedata.photo}`} width={`100px`} />
												) : null}
											</div>
										</div>
									</div>
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Upload Id Proof
											</label>
											<input
												className="form-control"
												name="EmpIdProof"
												type="file"
												onChange={(event) => {
													editStaff.setFieldValue(
														"EmpIdProof",
														event.currentTarget
															.files[0]
													);
												}}
												onBlur={editStaff.handleBlur}
											/>
											{editStaff.errors.EmpIdProof &&
											editStaff.touched.EmpIdProof ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.EmpIdProof
													}
												</div>
											) : null}
											<div className="input-group-append">
												{editStaff.values.EmpIdProof ? (
													<PreviewImage
														file={
															editStaff.values
																.EmpIdProof
														}
													/>
												) : null}
											</div>

											<div className="input-group-append">
												{(imagedata.idproof && !editStaff.values.EmpIdProof) ? (
													<img src={`${constant.SERVER_BASE_URL}images/${imagedata.idproof}`} width={`100px`} />
												) : null}
											</div>

										</div>
									</div>
								</div>

								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												R.M.
											</label>

											<select className="form-control" name="RmId" value={editStaff.values.RmId} onChange={editStaff.handleChange}>
												<option value="">Select</option>
												{allStaffData
												? allStaffData.map((item) =>
																<option key={item._id} value={item._id}>
																{item.EmployeeName}
																</option>										
												)
												: null}
											</select>

											{editStaff.errors.RmId &&
											editStaff.touched.RmId ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{editStaff.errors.RmId}
												</div>
											) : null}
										</div>
									</div>
									
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Salary
											</label>
											<input
												className="form-control"
												type="text"
												name="Salary"
												placeholder="Enter Your Salary"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={editStaff.values.Salary}
											/>
											{editStaff.errors.Salary &&
											editStaff.touched.Salary ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{editStaff.errors.Salary}
												</div>
											) : null}
										</div>
									</div>
									<div className="col">
										<div className="mb-3">
											<label
												className="form-label"
												htmlFor="exampleFormControlTextarea1"
											>
												Office Time
											</label>
											<input
												className="form-control"
												name="OfficeTime"
												type="time"
												onChange={
													editStaff.handleChange
												}
												onBlur={editStaff.handleBlur}
												value={
													editStaff.values.OfficeTime
												}
											/>
											{editStaff.errors.OfficeTime &&
											editStaff.touched.OfficeTime ? (
												<div
													className="alert alert-danger small alert-dismissible p-1"
													role="alert"
												>
													{
														editStaff.errors
															.OfficeTime
													}
												</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<button
												className="btn btn-sm btn-outline-info"
												type="submit"
											>
												<i className="fas fa-save"></i>{" "}
												Save
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

			<footer className="footer">
				<div className="row g-0 justify-content-between fs-10 mt-4 mb-3">
					<div className="col-12 col-sm-auto text-center">
						<p className="mb-0 text-600">
							Thank you for creating with Falcon{" "}
							<span className="d-none d-sm-inline-block">| </span>
							<br className="d-sm-none" /> 2024 ©{" "}
							<a href="https://themewagon.com">Themewagon</a>
						</p>
					</div>
					<div className="col-12 col-sm-auto text-center">
						<p className="mb-0 text-600">v3.21.0</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default EditStaff;
