import React,{useEffect} from "react";
import Header from "../Template/Header";
import axios from "axios";
import * as constant from "../../Constant";
import Swal from "sweetalert2";
import { toast } from "react-toastify";



const Dashboard =()=>{
  // const [mobilerequests,setMobilerequests]=React.useState([]);
  const [profile,setProfile]=React.useState({});
  const [accessdashboard,setAccessdashboard]=React.useState(false);


    const isFluid = JSON.parse(localStorage.getItem('isFluid'));
    if (isFluid) {
        var container = document.querySelector('[data-layout]');
        container.classList.remove('container');
        container.classList.add('container-fluid');
    }

   /*  const getMobileNoShowRequest=async ()=>{
        axios.get(`${constant.SERVER_BASE_URL}dashboard/get-mobileno-request`,{
          headers:{
            "Content-Type":"application/json",
            authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }).then((resp)=>{
          // console.log(resp.data);
          if(resp.data.status==="SUCCESS"){
            setMobilerequests(resp.data.value);
          }
        });
    } */

    /* const approveToShowMobileNo=async (obj)=>{
      Swal.fire({
        title: 'Are you sure?',
        text: `You want to approve for mobile no. ${obj.mobileno}!!`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approve it!'
        }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.get(`${constant.SERVER_BASE_URL}dashboard/approve-mobile-request`,{
            params:obj,
            headers:{
              "Content-Type":"application/json",
              authorization:`Bearer ${localStorage.getItem('token')}`
            }
          }).then((resp)=>{
            if(resp.data.status==="SUCCESS"){
              toast.success(`${resp.data.message}`, {
                position: "top-center"
                });
                // getMobileNoShowRequest();
            }else if(resp.data.status==="ERROR"){
              toast.error(`${resp.data.message}`, {
                position: "top-center"
                });
            }
          });
        }
      });
    }; */
    const auth = localStorage.getItem("adminLogin");
    const authJSON=JSON.parse(auth);

    const profiledata=async ()=>{

      await axios.get(`${constant.SERVER_BASE_URL}staff/get-employee/${authJSON._id}`,
        {
          headers:{
            "Content-Type":"application/json",
            authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }).then((resp)=>{
          var designation=resp.data.Designation;
          var desgArray=["ADMIN","SUPER-ADMIN"];
          if(desgArray.includes(designation)){
            setAccessdashboard(true);
          }else{
            setAccessdashboard(false);
          }
          console.log(resp.data);
          setProfile(resp.data);
        });
  };



    useEffect(()=>{
      // getMobileNoShowRequest();
      profiledata();
    },[]);

    return (
      
            
    <div className="content">
        <Header />
      
      <div>
        { accessdashboard?
        <div className="row g-3 mb-3">

          <div className="col-12">
                <div className="card bg-100 shadow-none border">
                  <div className="row gx-0 flex-between-center">
                  <div className="col-sm-auto d-flex align-items-center"><img className="ms-n2" src="/assets/img/generic/crm-bar-chart.png" width={90} />
                      <div>
                      <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
                      <h5 className="text-primary fw-bold mb-0">{profile.Designation?profile.Designation:null} Dashboard</h5>
                      </div>
                      <img className="ms-n4 d-md-none d-lg-block" src="/assets/img/generic/crm-line-chart.png" width={150} />
                  </div>
                  
                  </div>
              </div>
          </div>

        <div className="col-lg-6 pe-lg-2 mb-3">
            <div className="card h-lg-100 overflow-hidden">
              <div className="card-header bg-200 border-top border-info">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="mb-0"></h6>
                  </div>
                
                </div>
              </div>
              <div className="card-body p-0">
                
             
                      
               
               
              </div>
             
            </div>
          </div>

          <div className="col-lg-6 pe-lg-2 mb-3">
            <div className="card h-lg-100 overflow-hidden">
              <div className="card-header bg-200 border-top border-info">
              <div className="row align-items-center">
                  <div className="col">
                    <h6 className="mb-0"></h6>
                  </div>
                
                </div>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
               
              </div>
            </div>
          </div>
        
      
        </div>
        :
        <div className="row g-3 mb-3">

        <div className="col-md-12">
         <div className="card bg-100 shadow-none border">
             <div className="row gx-0 flex-between-center">
             <div className="col-sm-auto d-flex align-items-center"><img className="ms-n2" src="/assets/img/generic/crm-bar-chart.png" width={90} />
                 <div>
                 <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
                 <h5 className="text-primary fw-bold mb-0">{profile.DepartmentId?profile.DepartmentId.departmentName:null} </h5>
                 </div>
                 <img className="ms-n4 d-md-none d-lg-block" src="/assets/img/generic/crm-line-chart.png" width={150} />
             </div>
            
             </div>
         </div>
         </div>
    
          
         </div>

        }
      
     
     
    
        <footer className="footer">
          <div className="row g-0 justify-content-between fs-10 mt-4 mb-3">
            <div className="col-12 col-sm-auto text-center">
              <p className="mb-0 text-600"> 2024 © <a href="https://www.level777.com/">Level777</a></p>
            </div>
            <div className="col-12 col-sm-auto text-center">
              <p className="mb-0 text-600">v3.19.0</p>
            </div>
          </div>
        </footer>
      </div>
    
  


</div>

    );
}

export default Dashboard;