import React,{useState,useEffect} from "react";
import Header from "../../Template/Header";
import {useFormik } from "formik";
import Swal from "sweetalert2";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import Select from "react-select";
import {Link,useNavigate} from  "react-router-dom";
import {addCallerValidation} from "../../Validations/FormValidation";
import Spinner from 'react-bootstrap/Spinner';


const initialAddCommissionValues = {
    staffid:"",
    caller_number:"",
    extension:"",
    commissiontype:"",
    fixedAmount: "",
    percentage: "",
  };
const AddCaller=()=>{
    const redirect=useNavigate();
    const [callStaff,setCallerStaff]=React.useState([]);
    const [loader,setLoader]=React.useState(false);


    const saveCaller=useFormik({
        initialValues:initialAddCommissionValues,
        validationSchema:addCallerValidation,
        onSubmit:(values,action)=>{
          Swal.fire({
            title: "Are you sure?",
            text: "You want to update commission!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update it!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              setLoader(true);
                
              if(values.commissiontype==="FIXED_AMOUNT"){
                values.percentage=0;
              }

              if(values.commissiontype==="PERCENTAGE"){
                values.fixedAmount=0;
              }
                await axios.post(`${constant.SERVER_BASE_URL}staff/save-caller`,
                  values,
                {
                  headers:{
                    "Content-Type":"application/json",
                    authorization:`Bearer ${localStorage.getItem('token')}`
                  }
                }).then((resp)=>{
                  if(resp.data.status==="SUCCESS"){
                    setLoader(false);
                    toast.success(resp.data.message, {
                      position: "top-center",
                      className: "text-center",
                    });
                    action.resetForm();
                  }else if(resp.data.status==="ERROR"){
                    setLoader(false);
                    toast.error(resp.data.message, {
                      position: "top-center",
                      className: "text-center",
                    });
                  }else if(resp.data.status==="TOKEN_EXPIRED"){
                    setLoader(false);
                    localStorage.removeItem("adminLogin");
                    localStorage.removeItem("token");
                    redirect("/login");
                    window.location.reload();
                  }
                }).then((err)=>{
                  setLoader(false);
                  toast.error(err, {
                    position: "top-center",
                    className: "text-center",
                  });
                });
    
            }
          });
        }
      });

      const listStaffs = async () => {
      
        try {
      


              await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employees`, {
                    headers: {
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((resp)=>{
                  var staffArray=resp.data;
                  let filteredUsers = staffArray.filter((user) => {
                    return user.Designation==="CALLER";
                });
                    setCallerStaff(filteredUsers);
                });


            
        
           
            
        } catch (error) {
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };

    useEffect(()=>{
        listStaffs();
    },[]);

const optionStaff=callStaff.map((item)=>({
    value:item._id,
    label:item.EmployeeName

}));

const ChangeCaller=(e)=>{

  let event={
    target: {
      name: "staffid",
      value: e,
    },
  };
  saveCaller.handleChange(event);
};

  return (
    <div className="content">
         <Header />
<div className="card mb-3">
<div className="card-header border-bottom">
<div className="row flex-between-end">
    <div className="col-auto align-self-center">
        <h5 className="mb-0"    data-anchor="data-anchor"  id="responsive-table">
            Add Caller          
        </h5>
    </div>
    <div className="col-auto ms-auto">
        <div
            className="nav flex-grow-1 mt-2"
            role="tablist"
        >
            <Link
                className="btn btn-sm btn-outline-info"
                to="/list-caller"
            >
                
                <i className="fas fa-plus-circle"></i> List Caller
            </Link>
        </div>
    </div>
</div>
</div>
    

<form className="form-horizontal" onSubmit={saveCaller.handleSubmit}>


<div className="card-body bg-body-tertiary">


<div className="tab-content">

    
<div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e" id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e">
              <div className="row">
                    <div className="col">
                      <label className="form-label" htmlFor="exampleFormControlInput1"> Select Caller</label>

                        <Select 
                          name="staffid"
                          id="staffid"
                          placeholder="Select"
                          onBlur={saveCaller.handleBlur}
                          onChange={ChangeCaller.bind(this)}
                          options={optionStaff}
                        />

                        {saveCaller.errors.staffid && saveCaller.touched.staffid ? (
                        <div className="alert alert-danger small alert-dismissible p-1" role="alert">
                          {saveCaller.errors.staffid.label}
                        </div>
                      ) : null}

                    </div>
                   
                    <div className="col">
                    <div className="">
                      <label className="form-label" htmlFor="exampleFormControlTextarea1">Mobile Number</label>
                      <input className="form-control" value={saveCaller.values.caller_number} onChange={saveCaller.handleChange} onBlur={saveCaller.handleBlur} type="text" name="caller_number" id="caller_number" placeholder="Mobile No." />
                    </div>
                    {saveCaller.errors.caller_number && saveCaller.touched.caller_number ? (
                        <div className="alert alert-danger small alert-dismissible p-1" role="alert">
                          {saveCaller.errors.caller_number}
                        </div>
                      ) : null}
                  </div>

                  <div className="col">
                    <div className="">
                      <label className="form-label" htmlFor="exampleFormControlTextarea1">Extension</label>
                      <input className="form-control" onChange={saveCaller.handleChange} onBlur={saveCaller.handleBlur} value={saveCaller.values.extension} type="text" name="extension" id="extension" placeholder="i.e.10-99" />
                    </div>
                    {saveCaller.errors.extension && saveCaller.touched.extension ? (
                        <div className="alert alert-danger small alert-dismissible p-1" role="alert">
                          {saveCaller.errors.extension}
                        </div>
                      ) : null}
                  </div>

               </div>

               <div className="row py-3 mt-3">
                      <div className="col-md-auto">
                       <label className="form-label" htmlFor="exampleFormControlTextarea1">Commission Type</label>
                        <div>
                            
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" checked={saveCaller.values.commissiontype==="FIXED_AMOUNT"} id="amount"  type="radio" name="commissiontype" value="FIXED_AMOUNT" onChange={saveCaller.handleChange} onBlur={saveCaller.handleBlur} />
                            <label className="form-check-label small" htmlFor="amount">Fixed Amount</label>
                        </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" checked={saveCaller.values.commissiontype==="PERCENTAGE"}   id="commision" type="radio"  name="commissiontype" value="PERCENTAGE" onChange={saveCaller.handleChange} onBlur={saveCaller.handleBlur} />
                                <label className="form-check-label small" htmlFor="commision">Variable Amount</label>
                            </div>
                        </div>


                        { saveCaller.values.commissiontype==="FIXED_AMOUNT"?
                        <div className="mb-3">
                            <label className="form-label" htmlFor="exampleFormControlTextarea2">Amount</label>
                            <input type="text" value={saveCaller.values.fixedAmount} onBlur={saveCaller.handleBlur} onChange={saveCaller.handleChange} id="fixedAmount" name="fixedAmount" className="form-control" placeholder="Enter Amount" /></div>:null
                        }

                        { saveCaller.values.commissiontype==="PERCENTAGE"?
                            <div className="mb-3">
                                <label className="form-label" htmlFor="exampleFormControlTextarea2">Percentage</label>
                                <input type="text" value={saveCaller.values.percentage} onBlur={saveCaller.handleBlur} onChange={saveCaller.handleChange} id="percentage" name="percentage" className="form-control" placeholder="Enter Percentage"/></div>:null
                        }

                        {saveCaller.errors.commissiontype && saveCaller.touched.commissiontype ? (
                        <div className="alert alert-danger small alert-dismissible p-1" role="alert">
                          {saveCaller.errors.commissiontype}
                        </div>
                      ) : null}
                        </div>
                        </div>

                      {
                      loader===false?
                      <button className="btn btn-sm btn-outline-info mt-3" type="submit">
                      <i className="fas fa-save"></i> Save
                    </button>
                    :null
                    }

                    {
                      loader===true?
                    <button className="btn btn-sm btn-outline-info mt-3 disabled" type="submit">
                      Loading...
                      <Spinner animation="border" variant="info" size="sm" />
                    </button>
                    :null
                    }
                    

</div>
</div>
</div>
</form>

</div>


</div>
  )
}

export default AddCaller;
