import React, { useEffect, useState } from "react";
import Header from "../Template/Header";
import { ErrorMessage, Field, FieldArray, Formik, useFormik } from "formik";
import { addSubMenuValidation,addMainMenuValidation, validationSchemaNav} from "../Validations/FormValidation";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../Constant";
import Select from "react-select";
import { Link,useNavigate } from "react-router-dom";
import { Button, Form, Modal } from 'react-bootstrap';
import * as Yup from "yup";
const initialSubMenuValues = {
  mainmenuid: "",
  MenuUrl: "",
  haveSubMenu: false,
  SubMenu:'',
  submenuicon:'',
  showinsidebar:false
};
const initialMainMenuValues = {
  MainMenu: "",
  mainmenuicon:"",
  rank:""
};

// Define validation schema with Yup

const AddNav = () => {
  const redirect = useNavigate();

  const [mainMenuRecords,setMainMenuRecords]=React.useState([]);
  const [submenuRecords,setSubmenuRecords]=React.useState([]);
  let [serialNo, setSerialNo] = React.useState(1);
  let [srno, setSrno] = React.useState(1);

  const [show, setShow] = React.useState(false);
  const [show_navigationmodela,setNavigationmodela]=useState(false)

  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const saveSubmenu =useFormik({
        initialValues: initialSubMenuValues,
        validationSchema: addSubMenuValidation,
        onSubmit: (values, action) => {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to save this Sub-menu!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Save it!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              
              let result = await axios
                .post(`${constant.SERVER_BASE_URL}privilege/save-sub-menu`, values,{
                  headers:{
                    "Content-Type":"application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`
                  }
                })
                .then((resp) => {
                  if ("_id" in resp.data) {
                    toast.success(
                      `The Sub ${values.SubMenu} added successfully`,
                      {
                        position: "top-center",
                      }
                    );
                    action.resetForm();
                    listSubMenu();
                  }
                });
            }
          });
        },
      },[]);

  const saveMainMenu = useFormik(
    {
      initialValues: initialMainMenuValues,
      validationSchema: addMainMenuValidation,
      onSubmit: (values, action) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to save this Main Menu!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Save it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios
              .post(
                `${constant.SERVER_BASE_URL}privilege/save-main-menu`,
                values,
                {
                  headers:{
                    "Content-Type":"application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`
                  }
                }
              )
              .then((resp) => {
                
                if ("_id" in resp.data) {
                  toast.success(
                    `The Main menu ${values.MainMenu} added successfully`,
                    {
                      position: "top-center",
                    }
                  );
                  action.resetForm();
                  listMainMenu();
                }
              });
          }
        });
      },
    },
    []
  );

  


  const editMainMenu=(item)=>{
    setShow(true);
    updateMainMenu.values.MainMenu=item.MainMenu;
    updateMainMenu.values.mainmenuicon=item.mainmenuicon;
    updateMainMenu.values.rank=item.rank;
    updateMainMenu.values.id=item._id;
  } 


  const updateMainMenu = useFormik(
    {
      initialValues: initialMainMenuValues,
      validationSchema: addMainMenuValidation,
      onSubmit: (values, action) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to update this Main Menu!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            
            await axios
              .patch(
                `${constant.SERVER_BASE_URL}privilege/update-main-menu/${values.id}`,
                values,
                {
                  headers:{
                    "Content-Type":"application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`
                  }
                }
              )
              .then((resp) => {
                
                if (resp.data.status==="SUCCESS"){
                  toast.success(
                    `${resp.data.message}`,
                    {
                      position: "top-center",
                    }
                  );
                  setShow(false);
                  listMainMenu();
                }else if(resp.data.status==="ERROR"){
                  toast.success(
                    `${resp.data.message}`,
                    {
                      position: "top-center",
                    }
                  );
                }else if(resp.data.status==="TOKEN_EXPIRED"){
                  localStorage.removeItem("adminLogin");
                  localStorage.removeItem("token");
                  redirect("/login");
                  window.location.reload();
                }
              });
          }
        });
      },
    },
    []
  );

  const listMainMenu=async ()=>{
    try{
      await axios.get(`${constant.SERVER_BASE_URL}privilege/get-main-menu`,{
        headers:{
          "Content-Type":"application/json",
          authorization:`Bearer ${localStorage.getItem("token")}`
        }
      }).then((resp)=>{
        setMainMenuRecords(resp.data);
      }).catch((err)=>{
        toast.error(
          err,
          {
            position: "top-center",
          }
        );
      });
    }catch(err){
      toast.error(
        err,
        {
          position: "top-center",
        }
      );
    }
  };


  const listSubMenu=async ()=>{
    try{
      await axios.get(`${constant.SERVER_BASE_URL}privilege/get-sub-menu`,{
        headers:{
          "Content-Type":"application/json",
          authorization:`Bearer ${localStorage.getItem("token")}`
        }
      }).then((resp)=>{
        setSubmenuRecords(resp.data);
      }).catch((err)=>{
        toast.error(
          err,
          {
            position: "top-center",
          }
        );
      });
    }catch(err){
      toast.error(
        err,
        {
          position: "top-center",
        }
      );
    }
  };
  

  const deleteSubMenu=async(id)=>{

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this sub-menu records!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try{
          await axios.get(`${constant.SERVER_BASE_URL}privilege/delete-sub-menu/${id}`,{
            headers:{
              "Content-Type":"application/json",
              authorization:`Bearer ${localStorage.getItem("token")}`
            }
          }).then((resp)=>{
          let result=resp.data;
          if(result.acknowledged===true && result.deletedCount===1){
            Swal.fire(
                  "Deleted!",
                  "Sub-menu has been deleted.",
                  "success"
                );
                  listSubMenu();
              }else{
                Swal.fire(
                  "Can not delete!",
                  "Something went wrong, Please try again",
                  "info"
                );
              }
          }).catch((err)=>{
            toast.error(
              err,
              {
                position: "top-center",
              }
            );
          });
        }catch(err){
          toast.error(
            err,
            {
              position: "top-center",
            }
          );
        }
      }
    });
          
  }
 

  const [data, setData]=useState([]);
  const [Heading, setHeading]=useState({});
  const handleAdd=()=>{
   
      const abc=[...data,[]]
      setData(abc)
  }
  const handleAddChange=(onChangeValue,i)=>{
   const inputdata=[...data]
   inputdata[i]=onChangeValue.target.value;
   setData(inputdata)
  }
  const handleDelete=(i)=>{
      const deletVal=[...data]
      deletVal.splice(i,1)
      setData(deletVal)  
  }

 
  useEffect(()=>{
    listMainMenu();
    listSubMenu();
  },[]);

  const mainmenuDropdownOptions=mainMenuRecords.map(item=>({
    value:item._id,
    label:item.MainMenu
  }));

//Navigation functionaltiy 
const handleCloseNavigation=()=>{
  setNavigationmodela(false)
  setData([])
}



if (Heading?.navinfo?.length > 0) {
  var initialnavValues = {
    data: Heading.navinfo?.map(function(object){
      return {
        navicon: object.navicon,
        navname: object.navname,
        ui_req_url: object.ui_req_url,
        api_req_url: object.api_req_url,
        submenuid: Heading?._id,
        menuid: Heading?.mainmenuid?._id
    }
  })
};
 
}else{
var initialnavValues = {
  data: [
      {
          navicon: "",
          navname: "",
          ui_req_url: "",
          api_req_url: "",
          submenuid: Heading?._id,
          menuid: Heading?.mainmenuid?._id
      }
  ]
};
}




const validationSchemaNav = Yup.object({
  data: Yup.array().of(
    Yup.object({
      navname: Yup.string().required('Nav name is required'),
      navicon: Yup.string().required('Nav icon is required'),
      ui_req_url: Yup.string().url('Invalid URL').required('Nav URL is required'),
      api_req_url: Yup.string().url('Invalid URL').required('Server URL is required')
    })
  )
});




const handleSubmit = (values, actions) => {
  // Handle form submission logic here
  console.log(values);
  actions.setSubmitting(false); // Example: reset submitting state
};

  return (
    <div className="content">
      <Header />
      <div className=" mb-3">
        <div className="row">
          <div className="col-md-5 mb-4">
            <div className="card ecommerce-card-min-width py-2 px-3">
              <div className="col-auto align-self-center">
                <h5 className="mb-2" data-anchor="data-anchor" id="example">
                  Add Main Menu
                </h5>
              </div>
              <form onSubmit={saveMainMenu.handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Main Menu
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="MainMenu"
                        placeholder="Enter Your Main Menu"
                        onChange={saveMainMenu.handleChange}
                        onBlur={saveMainMenu.handleBlur}
                        value={saveMainMenu.values.MainMenu}
                      />
                      {saveMainMenu.errors.MainMenu &&
                      saveMainMenu.touched.MainMenu ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveMainMenu.errors.MainMenu}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Font Awasome Icon 
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="mainmenuicon"
                        id="mainmenuicon"
                        placeholder="i.e. fas fa-grid"
                        onChange={saveMainMenu.handleChange}
                        onBlur={saveMainMenu.handleBlur}
                        value={saveMainMenu.values.mainmenuicon}
                      />
                      {saveMainMenu.errors.mainmenuicon &&
                      saveMainMenu.touched.mainmenuicon ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveMainMenu.errors.mainmenuicon}
                        </div>
                      ) : null}
                    </div>



                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Rank 
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="rank"
                        placeholder="i.e. 10"
                        onChange={saveMainMenu.handleChange}
                        onBlur={saveMainMenu.handleBlur}
                        value={saveMainMenu.values.rank}
                      />
                      {saveMainMenu.errors.rank &&
                      saveMainMenu.touched.rank ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveMainMenu.errors.rank}
                        </div>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <button
                        className="btn btn-sm btn-outline-info small"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="overflow-hidden">
               <div className="table-responsive scrollbar">
                  <table className="table mb-0 fs-10 border-200 table-borderless">
                    <thead className="bg-200">
                      <tr>
                        <th className="text-800 text-nowrap">Rank</th>
                        <th className="text-800 text-nowrap">
                          Main Menu
                        </th>
                        <th className="text-800 text-center text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        mainMenuRecords?mainMenuRecords.map((item)=>(
                      <tr className="border-bottom border-200" key={item._id}>
                        <td className="align-middle">{item.rank?item.rank:'--'}</td>
                        <td className="align-middle"><i className={item.mainmenuicon}></i> {item.MainMenu}</td>
                        <td className="align-middle text-end">
                          <Link className="btn btn-sm btn-outline-info text-nowrap" onClick={editMainMenu.bind(this,item)}>
                            <i className="fas fa-edit"></i>
                          </Link>
                         
                        </td>
                      </tr>
                        )):null
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card ecommerce-card-min-width py-2 px-3">
              <div className="col-auto align-self-center">
                <h5 className="mb-2" data-anchor="data-anchor" id="example">
                  Add Sub Menu
                </h5>
              </div>
              <form onSubmit={saveSubmenu.handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Select Main Menu
                      </label>
                      <Select
                        name="mainmenuid"
                        id="mainmenuid"
                        placeholder="Select"
                        onBlur={saveSubmenu.handleBlur}
                        onChange={(selectedOption) => {
                          let event = {
                            target: {
                              name: "mainmenuid",
                              value: selectedOption,
                            },
                          };
                          saveSubmenu.handleChange(event);
                        }}

                        options={mainmenuDropdownOptions}
                      />
                        
                    


                      {saveSubmenu.errors.mainmenuid && saveSubmenu.touched.mainmenuid ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveSubmenu.errors.mainmenuid.label}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <input
                            type="checkbox"
                            className="pr-2"
                            name="haveSubMenu"
                            id="haveSubMenu"
                            onChange={saveSubmenu.handleChange}
                            onBlur={saveSubmenu.handleBlur}
                            value={saveSubmenu.values.haveSubMenu}
                          />
                          <label htmlFor="haveSubMenu" className="mb-1 pl-2">
                            &nbsp; Consist Sub Menu
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <input
                            type="checkbox"
                            className="pr-2"
                            name="showinsidebar"
                            id="showinsidebar"
                            onChange={saveSubmenu.handleChange}
                            onBlur={saveSubmenu.handleBlur}
                            value={saveSubmenu.values.showinsidebar}
                          />
                          <label htmlFor="showinsidebar" className="mb-1 pl-2">
                            &nbsp; Show in sidebar
                          </label>
                        </div>
                      </div>
                    </div>

                    {saveSubmenu.values.haveSubMenu && (
                      <>
                        <div className="mb-3"> <label className="form-label" htmlFor="submenuicon" > Sub Menu Icon</label>
                        <input   className="form-control"  type="text" name="submenuicon" id="submenuicon" onChange={saveSubmenu.handleChange}
                          onBlur={saveSubmenu.handleBlur}
                          value={saveSubmenu.values.submenuicon}
                          placeholder="i.e. fas fa-plush"
                        />
                        </div>

                        <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="exampleFormControlTextarea1"
                        >
                          Sub Menu
                        </label>
                        <input
                          className="form-control" 
                          type="text"
                          name="SubMenu"
                          onChange={saveSubmenu.handleChange}
                          onBlur={saveSubmenu.handleBlur}
                          value={saveSubmenu.values.SubMenu}
                          placeholder="Enter Sub Menu"
                        />
                        </div>
                        </>

                    )}
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Menu URL
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="MenuUrl"
                        placeholder="Enter Your Sub Menu URL"
                        onChange={saveSubmenu.handleChange}
                        onBlur={saveSubmenu.handleBlur}
                        value={saveSubmenu.values.MenuUrl}
                      />
                      {saveSubmenu.errors.MenuUrl && saveSubmenu.touched.MenuUrl ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveSubmenu.errors.MenuUrl}
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="row">
                    <div className="col mt-1">
                        <div className="text-center">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={handleAdd}
                          >
                            <i className="fas fa-plus"></i> Additional Navigation
                          </button>
                        </div>
                      </div>

                      {data.map((values, i) => (
                        <div className="row p-0 m-0">
                          <div className="col-md-5 col-xs-5 p-1">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="exampleFormControlTextarea1"
                              >
                                Nav
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="NavId"
                                placeholder="Enter Your Nav Name"
                                onChange={(e) => handleAddChange(e, i)}
                              />
                            </div>
                          </div>
                          <div className="col-md-5 col-xs-5 p-1">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="exampleFormControlTextarea1"
                              >
                                URL
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="ui_req_url"
                                placeholder="Enter Your Nav Name"
                                onChange={(e) => handleAddChange(e, i)}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 col-xs-2">
                            <div className="mt-1 mt-sm-1 text-right">
                              <button
                                className="btn btn-sm btn-outline-danger mt-0 mt-sm-4 p-1"
                                type="button"
                                onClick={handleDelete}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div> */}

                    <div className="mb-3">
                      <button
                        className="btn btn-sm btn-outline-info"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <table className="table table-hover table-striped overflow-hidden">
                      <thead className="table-info">
                        <th>S.No.</th>
                        <th>Main Menu</th>
                        <th>SubMenu</th>
                        <th>URL</th>
                        <th>Action</th>
                      </thead> 
                      <tbody>
                        {submenuRecords?submenuRecords.map((item)=>(
                        <tr key={item._id}>
                          <td>{srno++}</td>
                          <td>{item.mainmenuid.MainMenu}</td>
                          <td>{("submenu" in item)?item.submenu:null}</td>
                          <td>{item.menuurl}</td>
                          <td><button className="btn btn-sm btn-outline-danger" onClick={deleteSubMenu.bind(this,item._id)}><i className="fas fa-trash"></i></button>
                          
                          <button className="btn btn-sm btn-outline-info" onClick={()=>{
                            setNavigationmodela(true)
                          
                            setHeading(item)
                         

                            
                          }}> <i className="fas fa-plus"></i></button>
                         
                          
                          </td>
                        </tr>
                      )):null
                      }
                      </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      

<Modal show={show} onHide={handleClose}>
        
        <div className="modal-body p-0">
      <div className="rounded-top-3 bg-body-tertiary py-3 ps-4 pe-6">
        <h4 className="mb-1" id="staticBackdropLabel">Edit Main Menu</h4>
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-1">
        <button onClick={handleClose} className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" />
        </div>
    
      </div>
      <div className="p-4">
      <form className="form-horizontal" onSubmit={updateMainMenu.handleSubmit}>
        <div className="row">
          <div className="col-lg-10">
         
            <div className="d-flex mb-3">
              <div className="flex-1">
                <h5 className="mb-2 fs-9">Main Menu</h5> 
                  <input type="text" onChange={updateMainMenu.handleChange} onBlur={updateMainMenu.handleBlur} value={updateMainMenu.values.MainMenu} placeholder="Main Menu" name="MainMenu"  className="form-control" />
              </div>
            </div>

            <div className="d-flex mb-3">
              <div className="flex-1">
                <h5 className="mb-2 fs-9">Icon</h5> 
                  <input type="text" onChange={updateMainMenu.handleChange} onBlur={updateMainMenu.handleBlur} value={updateMainMenu.values.mainmenuicon}  placeholder="Icon" name="mainmenuicon"  className="form-control" />
              </div>
            </div>

            <div className="d-flex mb-3">
              <div className="flex-1">
                <h5 className="mb-2 fs-9">Rank</h5> 
                  <input type="text" onChange={updateMainMenu.handleChange} onBlur={updateMainMenu.handleBlur} value={updateMainMenu.values.rank} placeholder="Rank" name="rank"  className="form-control" />
              </div>
            </div>

        <div className="d-block mt-1">
            <button  type="submit" className="btn btn-outline-info">Save</button>
              </div>
          </div>
         
        </div>
      </form>
      </div>
    
    </div>
    
           
          </Modal>

          <Modal show={show_navigationmodela} onHide={handleCloseNavigation} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>{Heading?.mainmenuid?.MainMenu}</h2>
          <label>{Heading?.submenu}</label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Formik
          initialValues={initialnavValues}
          validationSchema={validationSchemaNav}
          onSubmit={async(values)=>{
            let result = await axios
            .post(`${constant.SERVER_BASE_URL}privilege/save-information`, values,{
              headers:{
                "Content-Type":"application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`
              }
            });
            toast.success(
              result.data?.message,
              {
                position: "top-center",
              }
            );
            setNavigationmodela(false)
            listSubMenu()
         
          }}
        >
       
          {({ values, handleSubmit,handleChange }) => (
             <Form onSubmit={handleSubmit}>
         
              <FieldArray
                name="data"
                render={({ push, remove }) => (
                  <>
                    {values.data.map((item, i) => (
                      <div className="row p-0 m-0" key={i}>
                         <div className="col-md-3 col-xs-3 p-1">
                          <div className="mb-3">
                            <label className="form-label" htmlFor={`data.${i}.navicon`}>
                              Nav icon
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name={`data.${i}.navicon`}
                              placeholder="Nav name"
                              onChange={handleChange}
                              value={item.navicon}
                            />
                          </div>
                          <div className="error">
                            <ErrorMessage name={`data.${i}.navicon`} component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="col-md-3 col-xs-3 p-1">
                          <div className="mb-3">
                            <label className="form-label" htmlFor={`data.${i}.navname`}>
                              Nav
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name={`data.${i}.navname`}
                              placeholder="Nav name"
                              onChange={handleChange}
                              value={item.navname}
                            />
                          </div>
                          <div className="error">
                            <ErrorMessage name={`data.${i}.navname`} component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="col-md-3 col-xs-3 p-1">
                          <div className="mb-3">
                            <label className="form-label" htmlFor={`data.${i}.ui_req_url`}>
                              Nav URL
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name={`data.${i}.ui_req_url`}
                              placeholder="Nav url"
                              onChange={handleChange}
                              value={item.ui_req_url}
                            />
                          </div>
                          <div className="error">
                            <ErrorMessage name={`data.${i}.ui_req_url`} component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="col-md-3 col-xs-3 p-1">
                          <div className="mb-3">
                            <label className="form-label" htmlFor={`data.${i}.api_req_url`}>
                              Server URL
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name={`data.${i}.api_req_url`}
                              placeholder="Server url"
                              onChange={handleChange}
                              value={item.api_req_url}
                            />
                          </div>
                          <div className="error">
                            <ErrorMessage name={`data.${i}.api_req_url`} component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="col-md-2 col-xs-2">
                          <div className="mt-1 mt-sm-1 text-right ">
                            {(i > 0 || Heading?.navinfo?.length>0 ) &&(
                              <button
                                className="btn btn-sm btn-outline-danger mt-0 mt-sm-4 p-1"
                                type="button"
                                onClick={() => remove(i)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            )}
                            {i === 0 && (
                              <button
                                className="btn btn-sm btn-outline-info mt-0 mt-sm-4 p-1 ml-2"
                                type="button"
                                onClick={() => push({navicon: '', navname: '', ui_req_url: '', api_req_url: '' ,submenuid:Heading?._id,menuid:Heading?.mainmenuid?._id})}
                              >
                                <i className="fas fa-plus"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />
              <div className="d-block mt-1">
                <Button type="submit" className="btn btn-outline-info">
                  Save
                </Button>
              </div>
              </Form>
          )}
       </Formik>
       
      </Modal.Body>
        </Modal>
        

    </div>



  );
};

export default AddNav;
