import React, { useEffect, useState } from "react";
import Header from "../../Template/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import { useFormik } from "formik";
import { addDepartmentValidation } from "../../Validations/FormValidation";
import Select from "react-select";

const initialDepartmentValues = {
	departmentName: "",
};

const EditDepartment = () => {


	const { departmentId } = useParams();
	const navigate = useNavigate();

	const editDepartment = useFormik(
		{
			initialValues: initialDepartmentValues,
			validationSchema: "",
			onSubmit: (values, action) => {
				Swal.fire({
					title: "Are you sure?",
					text: "You want to save this Department!!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, Save it!",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await axios
							.patch(
								`${constant.SERVER_BASE_URL}department/update-department/${departmentId}`,values,{
									headers:{
										"Content-Type":"application/json",
										authorization:`Bearer ${localStorage.getItem('token')}`
									}
								})
							.then((resp) => {
								if ("_id" in resp.data) {
									toast.success(
										`The Department '${values.departmentName}' updated successfully`,
										{
											position: "top-center",
											className: "text-center",
										}
									);
									action.resetForm();
									navigate("/list-department");
								}
							}).catch((error) => {
								toast.error("Something went wrong!!", {
									position: "top-center",
									className: "text-center",
								})
							})
					}
				});
			},
		},
		[]
	);

	const getCurrentDepartment = async () => {
			await axios.get(
				`${constant.SERVER_BASE_URL}department/get-department-by-id/${departmentId}`,
				{
					headers:{     
						"Content-Type":"application/json",
						authorization:`Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					console.log("response",resp.data);
					if(resp.data.status==="SUCCESS"){
					editDepartment.setValues({
						departmentName: resp.data.value.departmentName,
					});
					}else if(resp.data.status==="ERROR"){
						toast.error("Something went wrong!!", {
							position: "top-center",
							className: "text-center",
						});
					}
				});
	};


	useEffect(() => {
		getCurrentDepartment();
	},[]);

	return (
		<div className="content">
			<Header />

			<div className="card mb-3">
				<form
					className="form-horizontal"
					onSubmit={editDepartment.handleSubmit}
				>
					<div className="card-header">
						<div className="row flex-between-end">
							<div className="col-auto align-self-center">
								<h5
									className="mb-0"
									data-anchor="data-anchor"
									id="example"
								>
									Edit Department
									<a
										className="anchorjs-link "
										aria-label="Anchor"
										data-anchorjs-icon="#"
										href="#example"
										style={{
											marginLeft: "0.1875em",
											paddingRight: "0.1875em",
											paddingLeft: "0.1875em",
										}}
									/>
								</h5>
							</div>
							<div className="col-auto ms-auto">
								<div
									className="nav nav-pills nav-pills-falcon flex-grow-1"
									role="tablist"
								>
									<Link
										className="btn btn-sm btn-outline-info"
										to="/list-department"
									>
										{" "}
										<i className="fas fa-user"></i> List
										Departments
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div className="card-body bg-body-tertiary">
						<div className="tab-content">
							<div
								className="tab-pane preview-tab-pane active"
								role="tabpanel"
								aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
								id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
							>
								

								{/* Department Name */}
								<div className="mb-3">
									<label
										className="form-label"
										htmlFor="exampleFormControlInput2"
									>
										Department Name
									</label>
									<input
										className="form-control"
										id="departmentName"
										value={
											editDepartment.values.departmentName
										}
										onBlur={editDepartment.handleBlur}
										onChange={editDepartment.handleChange}
										name="departmentName"
										type="text"
										placeholder="Enter Department Name"
									/>
									{editDepartment.errors.departmentName &&
									editDepartment.touched.departmentName ? (
										<div
											class="alert alert-danger small alert-dismissible"
											role="alert"
										>
											{
												editDepartment.errors
													.departmentName
											}
										</div>
									) : null}
								</div>

								


								<button
									className="btn btn-sm btn-outline-info"
									type="submit"
								>
									{" "}
									<i className="fas fa-save"></i> Update
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default EditDepartment;
