import React, { useEffect } from "react";
import Header from "../../Template/Header";
import * as constant from "../../../Constant";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from 'moment';
import Skeleton from "../../Template/Skeleton";
const ListSheets = () => {

    const [allDatasheets, setAllDatasheets] = React.useState([]);
	let [serialNo, setSerialNo] = React.useState(1);
	const [showskeleton,setShowskeleton]=React.useState(true);

    /** FETCH ALL DATASHEETS */
    const fetchDatasheets = async () => {
        try {
            await axios.get(
				`${constant.SERVER_BASE_URL}datasheet/get-sheets`,
				{
					headers: {
						authorization: `Bearer ${localStorage.getItem("token")}`,
					}
				}
			).then((response)=>{
				setShowskeleton(false);
				setAllDatasheets(response.data);
			});
			
        } catch (error) {
            console.log(error);
        }
    }

	

	
	const deleteDatasheet=async (id)=>{
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You want to delete this Sheet!!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, Save it!'
		}).then(async (result) => {
		  if (result.isConfirmed) {
			await axios.delete(`${constant.SERVER_BASE_URL}datasheet/delete-datasheet/${id}`,{
				headers:{
					"Content-Type":"application/json",
					authorization:`Bearer ${localStorage.getItem('token')}`
				}
			}).then((resp)=>{
				if(resp.data.status==="SUCCESS"){
					toast.success(`${resp.data.message}`, {
						position: "top-center"
					  });
					  fetchDatasheets();
				}else{
					toast.error(`${resp.data.message}`, {
						position: "top-center"
					  });
				}
			});
		  }
		});
	  }


	useEffect(() => {
        fetchDatasheets();
		
	}, []);
	const takenAt=(datetime)=>{
		return  moment(datetime).format('D MMM YYYY HH:mm:ss');
	};

	return (
		<div className="content">
			<Header />
			<div className="card">
				<div className="card-header border-bottom">
					<div className="row flex-between-end">
						<div className="col-auto align-self-center">
							<h5
								className="mb-0"
								
								id="responsive-table"
							>
								List Sheets
								
							</h5>
						</div>
						<div className="col-auto ms-auto" style={{zIndex:999}}>
							<div className="nav flex-grow-1 mt-2"
								
							>
								<Link className="btn btn-sm btn-outline-info" to="/add-sheet">
									
									<i className="fas fa-plus-circle"></i> Upload Sheet
								</Link>
							</div>
						</div>
					</div>
				</div>
				{showskeleton === true?
					<Skeleton />:null
				} 
				{allDatasheets.length > 0?
				<div className="card-body pt-0">
					<div className="tab-content">
						<div
							className="tab-pane preview-tab-pane active"
							role="tabpanel"
							aria-labelledby="tab-dom-1c5a7061-098a-4658-a94b-061184a7cbbb"
							id="dom-1c5a7061-098a-4658-a94b-061184a7cbbb"
						>
							<div className="table-responsive scrollbar">
								<table className="table table-hover table-striped overflow-hidden">
									<thead>
										<tr>
											<th scope="col">S.No.</th>
											<th scope="col">Date</th>
											<th scope="col">Files</th>
											<th scope="col">TakenBy</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody>
										{allDatasheets.length > 0
											? allDatasheets.map(
													(datasheet) =>
														(
															<tr
																className="align-middle"
																key={datasheet._id}
															>
																<td className="text-nowrap">
																	<div className="d-flex align-items-center">
																		<div className="ms-2">
																			{
																				serialNo++
																			}
																		</div>
																	</div>
																</td>
																
																<td className="text-nowrap">
																	{datasheet.date}
																</td>
																<td className="text-nowrap">
																	<div>
																	<Link to={`/datasheet-details/${datasheet._id}`}> {datasheet.filename} </Link>
																	</div>
																	
																</td>

																<td className="text-nowrap">
																<div className="flex-1 ms-3">
																<h6 className="mb-0 fw-semi-bold">
																	<a className="stretched-link text-900 position-relative" href="#">{datasheet.allocatedto?datasheet.allocatedto.EmployeeName:'--'}</a>
																	</h6>
																<p className="text-500 fs-11 mb-0">{datasheet.allocateddatetime?takenAt(datasheet.allocateddatetime):'--'}</p>
																</div>
																	
																</td>

																<td className="text-nowrap">
																	<button onClick={deleteDatasheet.bind(this,datasheet._id)} className="btn btn-sm btn-outline-danger" type="button"><i className="fa fa-trash"></i></button>
																</td>
															</tr>
														)
											)
											: null}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				:
				<div className="card-body pt-0">
						<p>There is no records found</p>
				</div>
				}
					
			</div>
		</div>
	);
};

export default ListSheets;
