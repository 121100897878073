import Header from "../../Template/Header";
import Swal from "sweetalert2";
import axios  from "axios";
import { toast } from "react-toastify";
import * as constant from "../../../Constant";
import {Link,useParams,useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import { useFormik } from "formik";
import moment from 'moment';
import { resetStaffPassword } from "../../Validations/FormValidation";
const initialResetData={
  password:'',
  confirmPassword:''
};

const StaffSatting=()=>{
  const [staffrecords,setStaffrecords]=useState({});
    const {staffid} = useParams();
    const redirect = useNavigate();

      const singleEmployee=async ()=>{
          await axios.get(`${constant.SERVER_BASE_URL}staff/get-employee/${staffid}`,{
            headers:{
              "Content-Type":"application/json",
              authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }).then((resp)=>{
              if(typeof resp.data === "object"){
                setStaffrecords(resp.data);
              }else{
                toast.error("Staff records are not getting, Something went wrong. Please try again later", {
                  position: "top-center",
                  className: "text-center",
                });
              }
             
          });
      }

      const regeneratePassword=(e)=>{
        
        Swal.fire({
          title: "Are you sure?",
          text: `You want to generate a new password for ${staffrecords.EmployeeName}!!`,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Generate it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            axios.get(`${constant.SERVER_BASE_URL}staff/regenerate-password`,{
              params:{staffid:staffid},
              headers:{
                "Content-Type":"application/json",
                 authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }).then((resp)=>{
                if(resp.data.status==="SUCCESS"){
                  toast.success(`${resp.data.message}`, {
                    position: "top-center"
                    });
                }else if(resp.data.status==="ERROR"){
                  toast.error(`${resp.data.message}`, {
                    position: "top-center"
                    });
                }else if(resp.data.status==="TOKEN_EXPIRED"){
                  localStorage.removeItem("adminLogin");
                  localStorage.removeItem("token");
                  redirect("/login");
                  window.location.reload();
              }else{
                  toast.error(`${"Something went wrong, Please try again later"}`, {
                    position: "top-center"
                    });
                }
            });
          }
        })
      }


      const resetpassword=useFormik({
        initialValues:initialResetData,
        validationSchema:resetStaffPassword,
        onSubmit:(values,action)=>{
          Swal.fire({
            title: "Are you sure?",
            text: `You want to reset a new password for ${staffrecords.EmployeeName}!!`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Reset it!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              
                values.staffid=staffrecords._id;
                console.log(values);
                   await axios.post(`${constant.SERVER_BASE_URL}staff/reset-staff-password`,values,{
                      headers:{
                        "Content-Type":"application/json",
                        authorization:`Bearer ${localStorage.getItem('token')}`
                      }
                   }).then((resp)=>{
                      if(resp.data.status==='SUCCESS'){
                        toast.success(`${resp.data.message}`, {
                          position: "top-center"
                          });
                          action.resetForm();
                      }else{
                        toast.error(`${resp.data.message}`, {
                          position: "top-center"
                          });
                      }
                   });
            } 
          }); 
        }
      });


      useEffect(()=>{
        singleEmployee();
      },[])

      var degArray=["ADMIN","SUPER-ADMIN"];


  return (
    <div className="content">
      <Header />
      <div className=" mb-3">
      
      <div className="row g-0">
  <div className="col-lg-6 pe-lg-2 mb-3">
    <div className="card overflow-hidden" style={{height:'100%'}}>
      <div className="card-header bg-200 border-top border-info shadow">
        <div className="row align-items-center">

          <div className="col">
            <h6 className="mb-0 d-flex">Staff Details</h6>
          </div>

          <div className="col-auto ms-auto">
            <div className="nav nav-pills nav-pills-falcon flex-grow-1 mt-2" role="tablist">
                <Link className="btn btn-sm btn-outline-info" to="/list-staff"> <i className="fas fa-user"></i> List Staff </Link>
            </div>
          </div>
          
        </div>
      </div>
      <div className="card-body p-0">
      
      <div className="row align-items-center m-2">
        <div className="">

    

        <div className="table-responsive scrollbar">
  <table className="table table-hover">
    <tbody>
    {

      !degArray.includes(staffrecords.Designation)?
      <>
       <tr className="hover-actions-trigger pull-right">
        <td className="align-middle text-nowrap"> <strong> Firm:</strong></td>
        <td className="align-middle text-nowrap">{staffrecords.FirmID?staffrecords.FirmID.firmName:null}</td>
      </tr>
      
      <tr className="hover-actions-trigger">

        <td className="align-middle text-nowrap"> <strong>Department:</strong> </td>

        <td className="align-middle text-nowrap">{staffrecords.DepartmentId?staffrecords.DepartmentId.departmentName:null}</td>
      </tr>
      </>
        :null
}
      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Name:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.EmployeeName?staffrecords.EmployeeName:null}</td>
      </tr>
      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Email:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.EmployeeEmail?staffrecords.EmployeeEmail:null}</td>
      </tr>
      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Mobile:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.EmployeeMobile?staffrecords.EmployeeMobile:null}</td>
      </tr>

      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Address:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.EmployeeAddress?staffrecords.EmployeeAddress:null}</td>
      </tr>

      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Visa ID:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.VisaId?staffrecords.VisaId:null}</td>
      </tr>
      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Visa Validity:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.VisaExpiration?moment(staffrecords.VisaExpiration).format('DD-MM-YYYY'):null}</td>
      </tr>

      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Designation:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.Designation?staffrecords.Designation:null}</td>
      </tr>

      <tr className="hover-actions-trigger">
        <td className="align-middle text-nowrap"> <strong>Salary:</strong> </td>
        <td className="align-middle text-nowrap">{staffrecords.Salary?staffrecords.Salary:null}</td>
      </tr>

    </tbody>
  </table>
</div>

        </div>
       </div>

       
       
      </div>

    </div>
  </div>
  <div className="col-lg-6 pe-lg-2 mb-3">
    <div className="card h-lg-100">

                <div className="card-header bg-200 border-top border-info shadow">
                    <h6 className="p-1 mt-2">Re-generate Password</h6>
                </div>
            
                  
                <div className="card-body p-0 mt-2">
            
                    <div className="row">
                        <div className="col-auto m-2 text-center mt-2">
                            <button type="button" onClick={regeneratePassword} className="btn btn-outline-info btn-sm">Re-generate Password</button>
                        </div>
                    </div>

                    <div className="card-header bg-200 border-top border-info shadow mt-4">
                        <h6 className="p-1">Reset Password</h6>
                    </div>
                      <form onSubmit={resetpassword.handleSubmit}>
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group m-3">
                                  <label>New Password</label>
                                  <input type="text" onChange={resetpassword.handleChange} onBlur={resetpassword.handleBlur} value={resetpassword.values.password} placeholder="Enter New Passwrod" name="password" className="form-control form-control-sm w-75" />
                                  {resetpassword.errors.password && resetpassword.touched.password ? (
                                    <div
                                      className="alert alert-danger small alert-dismissible p-1 mt-2 w-75"
                                      role="alert"
                                    >
                                      {resetpassword.errors.password}
                                    </div>
                                  ) : null}
                              </div>

                              <div className="form-group m-3">
                                  <label>Confirm New Password</label>
                                  <input type="text" onChange={resetpassword.handleChange} onBlur={resetpassword.handleBlur} value={resetpassword.values.confirmPassword} placeholder="Confirm New Passwrod" name="confirmPassword" className="form-control form-control-sm w-75" />
                                  
                                  
                                  {resetpassword.errors.confirmPassword && resetpassword.touched.confirmPassword ? (
                                    <div
                                      className="alert alert-danger small alert-dismissible p-1 mt-2 w-75"
                                      role="alert"
                                    >
                                      {resetpassword.errors.confirmPassword}
                                    </div>
                                  ) : null}

                              </div>

                              <button className="btn btn-info btn-sm ms-3" type="submit"><i className="fas fa-save"></i> Submit</button>

                          </div>
                      </div>
                      </form>
            
                </div>

                





  




    </div>
  </div>
</div>

      </div>
      
    </div>
  );
};

export default StaffSatting;
