import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Template/Header";
import { useFormik } from "formik";
import { addDepartmentValidation,addSubDepartmentValidation } from "../../Validations/FormValidation";
import * as constant from "../../../Constant";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const initialDepartmentValues = {
	departmentName: "",
	subDepartmentName: "",
};
 const initialSubDepartmentValues = {
	departmentId: "",
	subDepartmentName: "",
  };

const AddDepartment = () => {
	const [departmentRecords, setDepartmentRecords] = React.useState([]);
	const navigate = useNavigate();

 

  const listDepartments = async () => {
	try {
		await axios.get(
			`${constant.SERVER_BASE_URL}department/get-departments`,
			{
				headers: {
					"Content-Type":"application/json",
					authorization: `Bearer ${localStorage.getItem("token")}`,
				}
			}).then((resp)=>{
				setDepartmentRecords(resp.data);
			});
	} catch (error) {
		toast.error("Something went wrong!!", {
			position: "top-center",
			className: "text-center",
		});
	}
};


	const saveDepartment = useFormik(
		{
			initialValues: initialDepartmentValues,
			validationSchema: addDepartmentValidation,
			onSubmit: (values, action) => {
				Swal.fire({
					title: "Are you sure?",
					text: "You want to save this Department!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, Save it!",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await axios
							.post(
								`${constant.SERVER_BASE_URL}department/save-department`,
								values,{
									headers:{
										"Content-Type":"application/json",
										authorization: `Beare ${localStorage.getItem('token')}`
									}
								}).then((resp) => {
									var result=resp.data;
									  if (result.status=="SUCCESS") {
											toast.success(result.message,
												{
													position: "top-center",
												}
											);
										action.resetForm();
									}else if(result.status=="ERROR"){
										toast.error(result.message,
											{
												position: "top-center",
											}
										);
									}
								});
							
					}
				});
			},
		},[]);
	  const saveSubDepartment =useFormik(
      {
        initialValues: initialSubDepartmentValues,
        validationSchema: addSubDepartmentValidation,
        onSubmit: (values, action) => {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to save this Sub-Department!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Save it!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              let result = await axios
                .post(`${constant.SERVER_BASE_URL}subdepartment/save-subdepartment`, values,{
					headers:{
						"Content-Type":"application/json",
						authorization: `Bearer ${localStorage.getItem('token')}`
					}
				})
                .then((resp) => {
                
                  if ("_id" in resp.data) {
                    toast.success(
                      `The Sub-Department ${values.subDepartmentName} added successfully`,
                      {
                        position: "top-center",
                      }
                    );
                    action.resetForm();
                  }
                });
            }
          });
        },
      },
      []
    );

	const departmentOptions=departmentRecords.map(item=>({
		value:item._id,
		label:item.departmentName
	}));
	
  useEffect(() => {
	listDepartments();
  }, []);
  

 
  

	return (
		<div className="content">
			<Header />
			<div className="row">
				<div className="col">
			<div className="card mb-3">
				{/* FORM SECTION START */}
				<form
					className="form-horizontal"
					onSubmit={saveDepartment.handleSubmit}
				>
					<div className="card-header">
					<div className="row flex-between-end">
        <div className="col-auto align-self-center">
          <h6 className="mb-0" data-anchor="data-anchor" id="example" style={{fontSize:"15px"}}>Add New Department<a className="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#example" style={{marginLeft: '0.1875em', paddingRight: '0.1875em', paddingLeft: '0.1875em'}} /></h6>
        </div>
        <div className="col-auto ms-auto">
          <div className="nav nav-pills nav-pills-falcon flex-grow-1" role="tablist">
             <Link className="btn btn-sm btn-outline-info" to="/list-department" style={{fontSize:"11px"}}> <i className="fas fa-user"></i> List Department</Link>
            </div>
        </div>
      </div>
					</div>
					<div className="card-body bg-body-tertiary">
						<div className="tab-content">
							<div
								className="tab-pane preview-tab-pane active"
								role="tabpanel"
								aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
								id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
							>
								

								{/* Department Name */}
								<div className="mb-3">
									<label
										className="form-label"
										htmlFor="exampleFormControlTextarea1"
									>
										Department Name
									</label>
									<input
										name="departmentName"
										type="text"
										placeholder="Enter Department Name"
										id="departmentName"
										className="form-control"
										value={
											saveDepartment.values.departmentName
										}
										onBlur={saveDepartment.handleBlur}
										onChange={saveDepartment.handleChange}
										
									/>
									{saveDepartment.errors.departmentName &&
									saveDepartment.touched.departmentName ? (
										<div
											className="alert alert-danger small alert-dismissible p-1"
											role="alert"
										>
											{
												saveDepartment.errors.departmentName
											}
										</div>
									) : null}
								</div>

								{/* Sub-Department Name (OPTIONAL)*/}
								<div className="mb-3">
									<label
										className="form-label"
										htmlFor="exampleFormControlTextarea2"
									>
										Sub-Department Name (Optional)
									</label>
									<input type="text" id="subDepartmentName" name="subDepartmentName" className="form-control" onBlur={saveDepartment.handleBlur} onChange={saveDepartment.handleChange} value={saveDepartment.values.subDepartmentName} placeholder="Enter Sub-Department Name" />
								</div>

								<button
									className="btn btn-sm btn-outline-info"
									type="submit"
								>
									<i className="fas fa-save"></i> Save
								</button>
							</div>
						</div>
					</div>
				</form>

			</div>
			</div>
			<div className="col">
          <div className="card mb-3">


		  <form className="form-horizontal" onSubmit={saveSubDepartment.handleSubmit}>
              <div className="card-header">
			  <div className="row flex-between-end">
				<div className="col-auto align-self-center">
					<h6 className="mb-0" data-anchor="data-anchor" id="example" style={{fontSize:"15px"}}>Add Sub Department<a className="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#example" style={{marginLeft: '0.1875em', paddingRight: '0.1875em', paddingLeft: '0.1875em'}} /></h6>
				</div>
        <div className="col-auto ms-auto">
          <div className="nav nav-pills nav-pills-falcon flex-grow-1" role="tablist">
             <Link className="btn btn-sm btn-outline-info" to="/list-sub-department" style={{fontSize:"11px"}} > <i className="fas fa-user"></i> List Sub Department</Link>
            </div>
        </div>
      </div>
              </div>
              <div className="card-body bg-body-tertiary">
                <div className="tab-content">
                  <div
                    className="tab-pane preview-tab-pane active"
                    role="tabpanel"
                    aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
                    id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
                  >
                    
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Select Department
                      </label>
					  
					  <Select
						name="departmentId"
						id="departmentId"
						placeholder="Select"
						onBlur={saveSubDepartment.handleBlur}
						onChange={(selectedOption) => {
							let event = {
								target: {
									name: "departmentId",
									value: selectedOption,
								},
							};
							saveSubDepartment.handleChange(event);
						}}
						options={departmentOptions}
					/>

                      {saveSubDepartment.errors.departmentId && saveSubDepartment.touched.departmentId ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveSubDepartment.errors.departmentId.label}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlTextarea2"
                      >
                        Sub-Department Name
                      </label>
                      <input
                        className="form-control"
                        onBlur={saveSubDepartment.handleBlur}
                        onChange={saveSubDepartment.handleChange}
                        value={saveSubDepartment.values.subDepartmentName}
                        id="subDepartmentName"
                        name="subDepartmentName"
                        type="text"
                        placeholder="Enter Sub-Department Name"
                      />
                      {saveSubDepartment.errors.subDepartmentName &&
                      saveSubDepartment.touched.subDepartmentName ? (
                        <div
                          className="alert alert-danger small alert-dismissible p-1"
                          role="alert"
                        >
                          {saveSubDepartment.errors.subDepartmentName}
                        </div>
                      ) : null}
                    </div>

                    <button
                      className="btn btn-sm btn-outline-info"
                      type="submit"
                    >
                      <i className="fas fa-save"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
			</div>
			{/*  FORM SECTION ENDS */}
		</div>
	);
};

export default AddDepartment;
