import React from 'react';

const CallRecordsSket=()=> {
    const styleTitle={
        width: '20%',
        display:'flex',
       height: '20px',
       flexDirection:"column",
       backgroundColor: '#e0e0e0',
       borderRadius: '4px',
       marginTop:'3px'
    };
    const styleAvatar={
        width: '30px',
        display:'flex',
        height: '30px',
        flexDirection:"column",
        backgroundColor: '#e0e0e0',
        borderRadius: '50%'
    };
    const styleLine={
        width: '100%',
        height: '15px',
        backgroundColor: '#e0e0e0',
        borderRadius: '4px'
    };
  return (
    <div className="skeleton-container" style={{display:"flex",flexDirection:"column",gap:"10px",margin:"10px 20px"}}>
        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
        </div>

        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
        </div>

        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
        </div>

        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
        </div>

        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
        </div>

        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-title" style={styleTitle}></div>
        </div>
        <div className="skeleton-container" style={{display:"flex",flexDirection:"column",gap:"10px",margin:"10px 20px"}}>
        <div style={{display:'flex',flexDirection:"row",gap:'10px',margin:"10px 20px"}}>
        <div className="skeleton-title" style={styleTitle}></div>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            <div className="skeleton-avatar" style={styleAvatar}></div>
            
            <div className="skeleton-title" style={styleTitle}></div>
            
        </div>
        </div>
    </div>
  );
}

export default CallRecordsSket;