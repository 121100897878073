import React,{useState,useEffect} from "react";
import Header from "../../Template/Header";
import {useFormik } from "formik";
import Swal from "sweetalert2";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import Select from "react-select";
import moment from "moment";
import {Link} from  "react-router-dom";

const AddSheets=()=>{

  const [selectedFiles,setSlectedFiles]=useState([]);

  const initialValues={
    dataFile:''
  };

  const updateFiles=(e)=>{
    setSlectedFiles(e.target.files);
  }

  const uploadSheet=useFormik({
    initialValues:initialValues,
    validationSchema:'',
    onSubmit: async (values,action)=>{
      
      const formData=new FormData();
      Array.from(selectedFiles).forEach(file=>{
        formData.append('dataFile',file);
      });
       Swal.fire({
        title: 'Are you sure?',
        text: "You want to upload datasheet!!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
            let result = await axios.post(
              `${constant.SERVER_BASE_URL}datasheet/upload-sheets`,
              formData,
              {
                headers:{
                  'Accept': 'application/json',
                  'Content-Type': 'multipart/form-data',
                  authorization:`Bearer ${localStorage.getItem('token')}`
                }
            }).then((resp)=>{
              if(resp.data.status==="SUCCESS"){
                toast.success(`${resp.data.message}`, {
                  position: "top-center"
                });
                //action.resetForm();
              }else{

                toast.error("Datasheet failed to upload", {
                  position: "top-center"
                });

              }
            });
        }
      })
    }
  },[]);




  return (
    <div className="content">
  
  <Header />

<div className="card mb-3">

<div className="card-header border-bottom">
					<div className="row flex-between-end">
						<div className="col-auto align-self-center">
							<h5
								className="mb-0"
								data-anchor="data-anchor"
								id="responsive-table"
							>
								Upload Sheets
								<a
									className="anchorjs-link "
									aria-label="Anchor"
									data-anchorjs-icon="#"
									href="#responsive-table"
									style={{
										marginLeft: "0.1875em",
										paddingRight: "0.1875em",
										paddingLeft: "0.1875em",
									}}
								/>
							</h5>
						</div>
						<div className="col-auto ms-auto">
							<div
								className="nav flex-grow-1 mt-2"
								role="tablist"
							>
								<Link
									className="btn btn-sm btn-outline-info"
									to="/list-sheets"
								>
									
									<i className="fas fa-plus-circle"></i> List Sheets
								</Link>
							</div>
						</div>
					</div>
				</div>
    

  <form className="form-horizontal"  onSubmit={uploadSheet.handleSubmit}>
      <div className="card-body bg-body-tertiary">
      <div className="tab-content">
      <div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e" id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e">
          <div className="row">
                <div className="col">
                <div className="">
                  <label className="form-label" htmlFor="exampleFormControlTextarea1">Upload Sheets</label>
                  <input className="form-control" type="file" multiple onChange={(e)=>{uploadSheet.setFieldValue("dataFile",updateFiles(e))}} name="dataFile" id="dataFile" />
                </div>
              </div>
            </div>
      <button className="btn btn-sm btn-outline-info mt-3" type="submit"> <i className="fas fa-save"></i> Upload Sheets</button>
      </div>
      </div>
      </div>
  </form>

</div>

<footer className="footer">
<div className="row g-0 justify-content-between fs-10 mt-4 mb-3">
<div className="col-12 col-sm-auto text-center">
<p className="mb-0 text-600">Thank you for creating with Falcon <span className="d-none d-sm-inline-block">| </span><br className="d-sm-none" /> 2024 © <a href="https://themewagon.com">Themewagon</a></p>
</div>
<div className="col-12 col-sm-auto text-center">
<p className="mb-0 text-600">v3.21.0</p>
</div>
</div>
</footer>

</div>
  )
}

export default AddSheets
