import React, { useEffect } from "react";
import Header from "../../Template/Header";
import * as constant from "../../../Constant";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PreviewImage from "../../Common/PreviewImage";

const ListStaff=()=>{

    const [allFirmsData, setAllFirmsData] = React.useState([]);
    const [allDepartmentsData, setAllDepartmentsData] = React.useState([]);
    const [allStaffData, setAllStaffData] = React.useState([]);
    let [serialNo, setSerialNo] = React.useState(1);
    const [selectedFile, setSelectedFile] = React.useState(null);

    	/** FETCH ALL FIRMS */
    const fetchFirms = async () => {
		try {
			const response = await axios.get(`${constant.SERVER_BASE_URL}firm/get-firms`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
			const firmData = response.data
			setAllFirmsData(firmData.map((firm) => ({
				firmId: firm._id,
				firmName: firm.firmName
			})));
		} catch (error) {
			console.log(error);
		}
  };

  	/** FETCH ALL DEPARTMENTS */
	const listDepartments = async () => {
		try {
			const response = await axios.get(
				`${constant.SERVER_BASE_URL}department/get-departments`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
			);
			setAllDepartmentsData(response.data);
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
  };

    const listStaffs = async () => {
      
        try {
            const response = await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employees`, {
                    headers: {
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((resp)=>{
                  setAllStaffData(resp.data);
                });
            
        } catch (error) {
            // console.log(error);
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };

    const deleteStaff = async (staffId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.patch(
                    `${constant.SERVER_BASE_URL}staff/update-employee/${staffId}`,
                    {
                        isDeleted: true,
                    }, {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                Swal.fire(
                  "Deleted!", 
                  "Staff has been deleted.", 
                  "success"
                );
                listStaffs();
            }})
    }

    

    useEffect(() => {
        fetchFirms();
        listDepartments();
        listStaffs();
    }, []);

    var ArrDesignation = ["ADMIN", "SUPER-ADMIN","MANAGER","TEAM-LEADER","DEPOSIT","WITHDRAWAL","CALLER"];

    return (
    <div className="content">
            <Header />
<div className="card">
  <div className="card-header border-bottom">
    <div className="row flex-between-end">
      <div className="col-auto align-self-center">
        <h5 className="mb-0" data-anchor="data-anchor" id="responsive-table">List Staff<a className="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#responsive-table" style={{marginLeft: '0.1875em', paddingRight: '0.1875em', paddingLeft: '0.1875em'}} /></h5>
        
      </div>
      <div className="col-auto ms-auto">
        <div className="nav nav-pills nav-pills-falcon flex-grow-1 mt-2" role="tablist">
            <Link className="btn btn-sm btn-outline-info" to="/add-staff"> <i className="fas fa-plus-circle"></i> Add Staff </Link>
        </div>
      </div>
    </div>
  </div>
  <div className="card-body pt-0">
    <div className="tab-content">
      <div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-1c5a7061-098a-4658-a94b-061184a7cbbb" id="dom-1c5a7061-098a-4658-a94b-061184a7cbbb">
        <div className="table-responsive scrollbar">
          <table className="table table-hover table-striped overflow-hidden">
            <thead>
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Firm</th>
                <th scope="col">Department</th>
              
                <th className="text-nowrap text-center" scope="col">Action</th>
              </tr>
            </thead>
            <tbody>

              {  allStaffData?allStaffData.map((item)=>
              <tr className="align-middle" key={item._id}>
                <td className="text-nowrap">{serialNo++}</td>
                <td className="text-nowrap">
                  <div class="avatar avatar-xl me-3">
                          <div class="avatar-name rounded-circle bg-primary-subtle text-dark">
                            <span class="fs-9 text-primary">{item.EmployeeName?item.EmployeeName[0]:'NA'}</span></div>
                          
                  
                </div>
                <p className="d-inline mt-1">{item.EmployeeName?item.EmployeeName:null}</p>
                </td>
                <td className="text-nowrap">{item.EmployeeEmail}</td>
                <td className="text-nowrap">{item.EmployeeMobile}</td>
                <td className="text-nowrap">{ArrDesignation.indexOf(item.Designation)>1?item.FirmID.firmName:'--'}</td>
                <td className="text-nowrap">{ArrDesignation.indexOf(item.Designation)>1?item.DepartmentId.departmentName:'--'}</td>
                
               
                <td className="text-end">
                <span className="badge badge rounded-pill d-block pr-2">
                  
									<Link		className="btn btn-sm btn-outline-info"	to={`/staff-setting/${item._id}`}	>
																			<i className="fas fa-cog"></i></Link>

                                      <Link		className="btn btn-sm btn-outline-info"	to={`/edit-staff/${item._id}`}	>
																			<i className="fas fa-edit"></i></Link>
									<button className="btn btn-sm btn-outline-danger" onClick={deleteStaff.bind(this)}>
																			<i className="fas fa-trash"></i>
									</button>
								 </span>
                </td>
              </tr>
              ):null
          }

            </tbody>
          </table>
        </div>
      </div>
    
    </div>
  </div>
</div>

</div>
);
}

export default ListStaff;