import React, { useEffect, useState } from "react";
import Header from "../../Template/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import { useFormik } from "formik";

const initialDepartmentValues = {
	departmentId: "",
	subDepartmentName:""
};

const EditSubDepartment = () => {

	const [allDepartmentsData, setAllDepartmentsData] = React.useState([]);
	const { subdepartmentid } = useParams();
	const navigate = useNavigate();

	const updateSubDepartment = useFormik(
		{
			initialValues: initialDepartmentValues,
			validationSchema: "",
			onSubmit: (values, action) => {
				Swal.fire({
					title: "Are you sure?",
					text: "You want to update this Sub-Department!!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, Save it!",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await axios
							.patch(
								`${constant.SERVER_BASE_URL}subdepartment/update-subdepartment/${subdepartmentid}`,values,{
									headers:{
										"Content-Type":"application/json",
										authorization:`Bearer ${localStorage.getItem('token')}`
									}
								})
							.then((resp) => {
								console.log(resp.data);
								if (resp.data.status==="SUCCESS") {
									toast.success(
										`The Sub-Department '${values.subDepartmentName}' updated successfully`,
										{
											position: "top-center",
											className: "text-center",
										}
									);
									navigate("/list-sub-department");
								}else{

									toast.success(
										`Sub-Department can not update`,
										{
											position: "top-center",
											className: "text-center",
										}
									);

								}
							}).catch((error) => {
								toast.error("Something went wrong!!", {
									position: "top-center",
									className: "text-center",
								})
							})
					}
				});
			},
		},
		[]
	);

	const listDepartments = async () => {
		try {
			const response = await axios.get(
				`${constant.SERVER_BASE_URL}department/get-departments`,
				{
					headers: {
						"Content-Type":"application/json",
						authorization: `Bearer ${localStorage.getItem("token")}`,
					}
				}).then((resp)=>{
					setAllDepartmentsData(resp.data);
				});
			
		} catch (error) {
			toast.error("Something went wrong!!", {
				position: "top-center",
				className: "text-center",
			});
		}
	};

	
	const getSingleSubDepartment=async ()=>{
		await axios.get(`${constant.SERVER_BASE_URL}subdepartment/get-subdepartment/${subdepartmentid}`,{
			headers:{
				"Content-Type":"application/json",
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then((resp)=>{
			console.log("Response",resp.data);
			updateSubDepartment.setValues({
				departmentId: resp.data.departmentId,
				subDepartmentName: resp.data.subDepartmentName
			});
		});
	}


	useEffect(() => {
		listDepartments();
		getSingleSubDepartment();
	},[]);

	return (
		<div className="content">
			<Header />

			<div className="card mb-3">
				<form
					className="form-horizontal"
					onSubmit={updateSubDepartment.handleSubmit}
				>
					<div className="card-header">
						<div className="row flex-between-end">
							<div className="col-auto align-self-center">
								<h5
									className="mb-0"
									data-anchor="data-anchor"
									id="example"
								>
									Edit Sub-Department
									
								</h5>
							</div>
							<div className="col-auto ms-auto">
								<div
									className="nav nav-pills nav-pills-falcon flex-grow-1"
									role="tablist"
								>
									<Link
										className="btn btn-sm btn-outline-info"
										to="/list-sub-department"
									>
									
										<i className="fas fa-user"></i> List
										Sub-Departments
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div className="card-body bg-body-tertiary">
						<div className="tab-content">
							<div className="tab-pane preview-tab-pane active" role="tabpanel"
								aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
								id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e"
							>
								{/* Department Name */}
								<div className="mb-3">
									<label className="form-label" htmlFor="exampleFormControlInput2"> Select Department</label>
									<select className="form-control" id="departmentId" name="departmentId" value={updateSubDepartment.values.departmentId} onChange={updateSubDepartment.handleChange}>
												<option value="">Select</option>
												{allDepartmentsData? allDepartmentsData.map((item) =>
														<option key={item._id}	value={item._id}>{item.departmentName}</option>
													)
												: null}
											</select>
									{updateSubDepartment.errors.departmentId &&
									updateSubDepartment.touched.departmentId ? (
										<div
											class="alert alert-danger small alert-dismissible"
											role="alert"
										>
											{
												updateSubDepartment.errors
													.departmentId
											}
										</div>
									) : null}
								</div>
								<div className="mb-3">
									<label	className="form-label"	htmlFor="exampleFormControlInput2">Sub-Department Name</label>
									<input className="form-control" id="subDepartmentName"	value={updateSubDepartment.values.subDepartmentName}
										onBlur={updateSubDepartment.handleBlur}
										onChange={updateSubDepartment.handleChange}
										name="subDepartmentName"
										type="text"
										placeholder="Enter Department Name"
									/>
									{updateSubDepartment.errors.subDepartmentName &&
									updateSubDepartment.touched.subDepartmentName ? (
										<div
											class="alert alert-danger small alert-dismissible"
											role="alert"
										>
											{
												updateSubDepartment.errors
													.subDepartmentName
											}
										</div>
									) : null}
								</div>
								<button	className="btn btn-sm btn-outline-info"	type="submit">
									<i className="fas fa-save"></i> Update
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default EditSubDepartment;
