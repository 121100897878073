import React, { useEffect } from "react";
import Header from "./Template/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import * as constant from "../Constant";


const History = () => {
	const [historydata,setHistorydata]=React.useState([]);
	let [serial,setSerial]=React.useState(1);

    const getHistory=async ()=>{
            await axios.get(`${constant.SERVER_BASE_URL}history/get-history`,
                {
                    headers:{
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            ).then((resp)=>{
                console.log(resp);
				setHistorydata(resp.data);
            });
    }

    useEffect(()=>{
        getHistory();
    },[]);



	return (
		<div className="content">
			<Header />
			<div className="card">
				<div className="card-header border-bottom">
					<div className="row flex-between-end">
						<div className="col-auto align-self-center">
							<h5
								className="mb-0"
								data-anchor="data-anchor"
								id="responsive-table"
							>
								History
								<a
									className="anchorjs-link "
									aria-label="Anchor"
									data-anchorjs-icon="#"
									href="#responsive-table"
									style={{
										marginLeft: "0.1875em",
										paddingRight: "0.1875em",
										paddingLeft: "0.1875em",
									}}
								/>
							</h5>
						</div>
                        {/* 
						<div className="col-auto ms-auto">
							<div
								className="nav nav-pills nav-pills-falcon flex-grow-1 mt-2"
								role="tablist"
							>
								<Link
									className="btn btn-sm btn-outline-info"
									to="/calling-records"
								>
								
									<i className="fas fa-chart-pie"></i> Calling Dash
								</Link>
							</div>
						</div> */}
                         
					</div>
				</div>
				<div className="card-body pt-0">
					<div className="tab-content">
						<div className="tab-pane preview-tab-pane active">
							<div className="table-responsive scrollbar">
								<table className="table table-hover table-striped overflow-hidden">
									<thead>
										<tr>
											<th>S.No.</th>
											<th>Operation</th>
											<th style={{width:"400px"}}>Action</th>
											<th>Date&Time</th>
											<th>IPAddress</th>
											<th style={{width:"400px"}}>Agent</th>
										</tr>
									</thead>
									<tbody>
									{ historydata?historydata.map((item)=>

									<tr	className="align-middle" key={item._id}>

										<td className="text-nowrap">{serial++}</td>
										<td className="text-nowrap fs-11">{item.operation}</td>
										<td className="text-nowrap fs-11">{item.action}</td>
										<td className="text-nowrap fs-11">{item.datetime}</td>
										<td className="text-nowrap fs-11">{item.ipaddress}</td>
										<td className="text-nowrap fs-11">{item.agent}</td>

									</tr>	
									):null
									}												
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			
		</div>
	);
};

export default History;
