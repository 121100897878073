import React from 'react';
import './Skeleton.css'; // Import your CSS for the skeleton styles

function Skeleton() {
  return (
    <div className="skeleton-container">
      <div className="skeleton-title"></div>
      <div className="skeleton-line"></div>
      <div className="skeleton-line"></div>
      <div className="skeleton-line"></div>
      <div className="skeleton-title"></div>
      <div className="skeleton-line"></div>
      <div className="skeleton-line"></div>
      <div className="skeleton-line"></div>
      <div className="skeleton-title"></div>
      <div className="skeleton-avatar"></div>
    </div>
  );
}

export default Skeleton;