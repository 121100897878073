import React, { useEffect } from "react";
import Header from "../../Template/Header";
import * as constant from "../../../Constant";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ListCustomer =  () => {
	const [customers,setCustomers]=React.useState([]);
	let [serialNo, setSerialNo] = React.useState(1);
	const getAllCustomers= async ()=>{
	await axios.get(`${constant.SERVER_BASE_URL}customer/get-all-customers`,{
		
			headers:{
				"Content-Type":"application/json",
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		
	}).then((resp)=>{
		console.log(resp.data);
		setCustomers(resp.data);
	});
}

	useEffect(()=>{
		getAllCustomers();
	},[]);

	return (
		<div className="content">
			<Header />
	<div className="card">
		<div className="card-header border-bottom">
			<div className="row flex-between-end">
				<div className="col-auto align-self-center">
					<h5	className="mb-0" >List Firm</h5>
				</div>
			</div>
		</div>
		<div className="card-body pt-0">
			<div className="tab-content">
				<div
					className="tab-pane preview-tab-pane active">
					<div className="table-responsive scrollbar">
						<table className="table table-hover table-striped overflow-hidden">
							<thead>
								<tr>
									<th scope="col">S.No.</th>
									<th scope="col">Name</th>
									<th scope="col">Mobile No.</th>
									<th scope="col">Ref.</th>
									<th	scope="col"	className="text-nowrap text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								{customers?customers.map((item)=>
								<tr	className="align-middle" key={item._id}>
									<td className="text-nowrap">
										<div className="d-flex align-items-center">
											<div className="ms-2">{serialNo++}</div>
										</div>
									</td>
									<td className="text-nowrap">{item.name}</td>
									<td className="text-nowrap">{item.mobile}</td>
									<td className="text-nowrap">{item.staffid.EmployeeName}</td>
									<td>
										
									</td>
								</tr>
								):null
								}	





							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
	);
};

export default ListCustomer;
