import * as Yup from "yup";

/** ADD FIRM VALIDATION */
export const addFirmValidation = Yup.object({
	firmName: Yup.string().min(5).max(200).required("Firm Name is required"),
	firmAddress: Yup.string()
		.min(5)
		.max(300)
		.required("Firm Address is required"),
});

/** ADD STAFF VALIDATION */
export const staffSchemaValidation = Yup.object().shape({
	
	
	  /* DepartmentId: Yup.object().shape({
		label: Yup.string().required('Department is required'),
		value: Yup.string().required('Department is required'),
	  }),
	
	  FirmID: Yup.object().shape({
		label: Yup.string().required('Firm is required'),
		value: Yup.string().required('Firm is required'),
	  }), */
	EmployeeName: Yup.string().required("Please enter your name"),
	EmployeeEmail: Yup.string().email("Invalid email format").required("Please enter your email address"),
	EmployeeMobile: Yup.string().min(10, "Please Enter minimum 10 character").required("Please enter phone number"),
	EmployeeAddress: Yup.string().required("Please enter your address"),
	VisaId: Yup.string().required("Please anter your VISA Id"),
	VisaExpiration: Yup.date().required("Date of birth is required"),
	/* EmpPhoto: Yup.mixed().required("Profile image is required")
		.test("fileFormat", "This is not a valid file format", (value) => {
			if (value) {
				const supportedFormats = ["png", "jpg", "jpeg", "webp"];
				return supportedFormats.includes(value.name.split(".").pop());
			} else {
				return true;
			}
		})
		.test("fileSize", "File size must be less than 3MB", (value) => {
			if (value) {
				return value.size <= 3145728;
			} else {
				return true;
			}
		}),
	EmpIdProof: Yup.mixed()
		.required("Profile image is required")
		.test("fileFormat", "This is not a valid file format", (value) => {
			if (value) {
				const supportedFormats = ["png", "jpg", "jpeg", "webp"];
				return supportedFormats.includes(value.name.split(".").pop());
			} else {
				return true;
			}
		})
		.test("fileSize", "File size must be less than 3MB", (value) => {
			if (value) {
				return value.size <= 3145728;
			} else {
				return true;
			}
		}), */
	Designation: Yup.string().required("Please select a designation"),
	Salary: Yup.string().required("Please enter your salary"),
	OfficeTime: Yup.string().required("Please enter your office time"),
});


  

  export const addDepartmentValidation=Yup.object().shape({
   /*  firmId: Yup.object().shape({
      label: Yup.string().required('Firm Name is required'),
      value: Yup.string().required('Firm Name is required'),
    }), */
    departmentName:Yup.string().min(3).max(100).required("Department name is required")
   
  });
  
  export const LoginValidation=Yup.object({
    username:Yup.string().min(5).max(100).email("Please enter valid email address").required("User Name is required"),
    password:Yup.string().min(5).max(20).required("Password is required")
});

export const addSubDepartmentValidation = Yup.object().shape({
	departmentId: Yup.object().shape({
		label: Yup.string().required('Deparment  is required'),
		value: Yup.string().required('Deparment Name is required'),
	  }),
	  subDepartmentName:Yup.string().min(3).max(100).required("Sub-Department is required")
  });
  

  export const addMainMenuValidation = Yup.object({
    MainMenu: Yup.string().required("Please enter main menu"),
	mainmenuicon: Yup.string().required("Icon is required"),
	rank:Yup.number().required("Rank is required")
 });
 export const addOtherNotes = Yup.object({
    notes: Yup.string().min(3).max(200).required("Notes field is required"),
 });

 
  export const addSubMenuValidation = Yup.object().shape({
	mainmenuid: Yup.object().shape({
		label: Yup.string().required('Main Menu is required'),
		value: Yup.string().required('Main Menu is required'),
	  }),
    MenuUrl: Yup.string().required("Please enter menu url"),
    SubMenu: Yup.string().label("Your name").when("ConsistCheck", {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
	}),
	});
	
  // Staff Setting Update Calling Records
  export const updateCallingRecords = Yup.object({
    callingFromNo:  Yup.string()
	.matches(
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
		"Calling From Number is not valid, Please enter numeric value"
	).min(10, "Calling From must have 10 digits").max(10, "Calling From must have 10 digits").required("Please enter Calling From Number"),
    callingAgentId: Yup.number().required("Calling Id is required"),
 });

 export  const validationSchemaNav = Yup.object().shape({
	data: Yup.array().of(
	  Yup.object().shape({
		navname: Yup.string().required('Nav name is required'),
		navurl: Yup.string().url('Invalid URL').required('Nav URL is required'),
		navserverurl: Yup.string().url('Invalid URL').required('Server URL is required')
	  })
	)
  });
  

 export const addCallerValidation = Yup.object().shape({
	staffid: Yup.object().shape({
		label: Yup.string().required('Staff is required'),
		value: Yup.string().required('Staff is required'),
	  }),
    caller_number: Yup.string().matches(
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
		"Mobile Number is not valid, Please enter numeric value"
	).min(10, "Mobile Number must have 10 digits").max(10, "Mobile Number must have 10 digits").required("Mobile Number is required"),
    extension: Yup.string().required("Extension is required"),
    commissiontype: Yup.string().required().oneOf(['FIXED_AMOUNT' , 'PERCENTAGE']),
	});

	export const AllocateSheetValidate = Yup.object().shape({
		staffid: Yup.object().shape({
			label: Yup.string().required('Staff is required'),
			value: Yup.string().required('Staff is required'),
		  })
		});


		export const resetStaffPassword =Yup.object({
			password: Yup.string().required("Password is required"),
			confirmPassword:Yup.string().oneOf([Yup.ref('password'), null], 'Confirm Password must match with password').required('Confirm Password is required')
		 });
	