import React,{useEffect} from "react";
import Header from "../../Template/Header";
import axios from "axios";
import * as constant from "../../../Constant";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import CallRecordsSket from "../../Skeleton/Call/CallrecordsSket";



const initialSearchValue={
    staffid:null,
    date:''
};

const CallRecords=()=>{
    const [params,setParams]=React.useState(null);
    const [metadata,setMetadata]=React.useState([]);
    const [searchcount,setSearchcount]=React.useState(0);
    const [skip,setSkip]=React.useState(0);
    const [callStaff,setCallerStaff]=React.useState([]);
    const [loader,setLoader]=React.useState(false);
    const [showclear,setShowclear]=React.useState(false);
    const [showskeleton,setShowskeleton]=React.useState(false);





    const sheetmetaRecords=async (offset=0,limit=10,params)=>{
        await axios.get(`${constant.SERVER_BASE_URL}datasheet/get-sheet-metadata-all-records`,{
            params:{
                offset:offset,
                limit:limit,
                qp:params
            },
            headers:{
                "Content-Type":"application/json",
                authorization:`Bearer ${localStorage.getItem('token')}`
            }
        }).then((resp)=>{
            setShowskeleton(true);
            if(resp.data.status==='success'){
              setShowskeleton(false);
              setSearchcount(resp.data.totalcount);
              setMetadata(resp.data.value);
              setLoader(false);
            }else{
              setShowskeleton(false);
            }
           
        })
    }


    const listCallerStaffs = async () => {
      
        try {
      


              await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employees`, {
                    headers: {
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((resp)=>{
                  var staffArray=resp.data;
                  let filteredUsers = staffArray.filter((user) => {
                    return user.Designation==="CALLER";
                });
                    setCallerStaff(filteredUsers);
                });


            
        
           
            
        } catch (error) {
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };


    const optionStaff=callStaff.map((item)=>({
        value:item._id,
        label:item.EmployeeName
    
    }));



    const searchRecords=useFormik({
        initialValues:initialSearchValue,
        validationSchema:'',
        onSubmit:(values,action)=>{
              setParams(values);
              setLoader(true);
              setShowclear(true);
              sheetmetaRecords(0,1000,values);
              
        }
      });

      const ChangeCaller=(e)=>{

        let event={
          target: {
            name: "staffid",
            value: e,
          },
        };
        searchRecords.handleChange(event);
      };

    const renderViewStatus=(status,notes)=>{
        switch (status) {
          case 'Others':
              return (
                <>
                <span className="badge rounded-pill ms-2 badge-subtle-success" style={{fontSize: 12}}>Others</span>
                  <p className="fs-11 text-500 bg-light mt-1 p-1 rounded">{notes}</p>
                </>
              );
          case 'Not Answered':
            return (
              <span className="badge rounded-pill ms-2 badge-subtle-warning" style={{fontSize: 12}}>Not Answered</span>
            );
          case 'Call Another Time':
            return (
              <span className="badge rounded-pill ms-2 badge-subtle-info" style={{fontSize: 12}}>Call Another Time</span>
            );
          case 'Number Invalid':
            return (
              <span className="badge rounded-pill ms-2 badge-subtle-danger" style={{fontSize: 12}}>Call Another Time</span>
            );
          case 'Not Interested':
            return (
              <span className="badge rounded-pill ms-2 bg-400" style={{fontSize: 12}}>Call Another Time</span>
            );
          default:
            return <></>;
        }
      }

      const AgentAvatar=(remarks,name)=>{
        switch (remarks) {
            case 'Others':
                return (
                  <>
                    <div className="avatar-name rounded-circle bg-success-subtle text-dark">
                        <span className="fs-9 text-success">{name.charAt(0)}</span>
                    </div>
                  </>
                );
            case 'Not Answered':
              return (
                <div className="avatar-name rounded-circle bg-warning-subtle text-dark">
                        <span className="fs-9 text-warning">{name.charAt(0)}</span>
                    </div>
              );
            case 'Call Another Time':
              return (
                <div className="avatar-name rounded-circle bg-info-subtle text-dark">
                        <span className="fs-9 text-info">{name.charAt(0)}</span>
                    </div>
              );
            case 'Number Invalid':
              return (
                <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
                        <span className="fs-9 text-danger">{name.charAt(0)}</span>
                    </div>
              );
            case 'Not Interested':
              return (
                <div className="avatar-name rounded-circle bg-400 text-dark">
                <span className="fs-9 text-dark">{name.charAt(0)}</span>
            </div>
              );
            default:
              return <></>;
          }
      }


      
const handlePageClick=(data)=>{
    let limit=10;
    let offset=data.selected*limit;
    setSkip(offset);
    sheetmetaRecords(offset,limit,params);
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      height:'10px!important',
      marginRight:'0px'
     
    })
};

const clearFilter=()=>{
  setShowclear(false);
  sheetmetaRecords(0,10,null);
 
}

  const searchdate=searchRecords.values.date;
  var selectedstaff=null;
   if(searchRecords.values.staffid){
      if('value' in searchRecords.values.staffid){
        selectedstaff=searchRecords.values.staffid.value;
      }
    
  }
 if(searchdate!=='' || selectedstaff!==null){
  var element=document.getElementById('btnsearch');
  if(element){
    element.classList.remove('disabled');
  }
 }

    useEffect(()=>{
        sheetmetaRecords();
        listCallerStaffs();
    },[]);
    return (
        <div className="content">
        <Header />
       
        <div className="card">
            <div className="card-header bg-200 border-top border-info shadow d-flex">
                <div className="d-flex me-2 mt-3">
                  <h6>Call Records</h6>
                </div>
                <form onSubmit={searchRecords.handleSubmit } className="ms-2">
                <div className="row justify-content-end">
                  
                   
                    <div className="col-auto m-0">
                    <div className="form-group d-inline-flex m-1">
                            <Select 
                            styles={customStyles}
                            name="staffid"
                            id="staffid"
                            placeholder="Select"
                            onBlur={searchRecords.handleBlur}
                            onChange={ChangeCaller.bind(this)}
                            options={optionStaff}
                        />

                            </div>
                            <div className="form-group d-inline-flex m-1">
                                    <input type="date" onChange={searchRecords.handleChange} onBlur={searchRecords.handleBlur} name="date"  value={searchRecords.values.date} className="form-control form-control-md" style={{height:'37px',background:'white'}}  />
                            </div>
                            <div className="form-group d-inline-flex m-1">
                                    {
                                      loader===false?
                                      <button className="btn btn-info disabled" id="btnsearch" type="submit">
                                        <i className="fa fa-search" aria-hidden="true"></i> Search
                                    </button>
                                    :null
                                    }
                                    {
                                      loader===true?
                                      <button className="btn btn-info disabled" type="submit">

                                        <div style={{display:'inline-flex'}} className="mb-2">
                                          <p className="mb-0">Loading...</p>
                                          <Spinner animation="border" variant="info" size="sm" />
                                        </div>

                                    </button>
                                    :null
                                    }
                                    {
                                      showclear?
                                      <button type="button" id="clearbutton" className="btn btn-danger ms-2" onClick={clearFilter}> <i className="fas fa-times-circle"></i> Clear Filter</button>
                                      :null
                                    }
                                    
                                    
                            </div>
                    </div>

                  

                </div>
                </form>
            </div>


          <div className="card h-lg-100 overflow-hidden">
                
                <div className="card-body p-0">
                  {showskeleton?<CallRecordsSket />:null}
                    { metadata? metadata.map((item)=>
                    <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200" key={item._id}>
                    <div className="col ps-x1 py-1 position-static">
                        <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl me-3">
                            {AgentAvatar(item.remarks,item.updatedby.EmployeeName)}
                          
                        </div>
                        <div className="flex-1">
                            <h6 className="mb-0 d-flex align-items-center">{item.updatedby.EmployeeName}</h6>
                        </div>
                        </div>
                    </div>
                   
                    <div className="col ps-x1 py-1 position-static">
                        <div className="d-flex align-items-center">
                        <div className="flex-1">
                            <h6 className="mb-0 d-flex align-items-center">{item.mobileno}</h6>
                            {item.isCustomer?
                                <span className="badge rounded-pill badge-subtle-success" style={{fontSize:'9px'}}><i className="fas fa-check"></i> Customer</span>
                            :null
                            }
                        </div>
                        </div>
                    </div>
                    <div className="col ps-x1 py-1 position-static">
                        <div className="d-flex align-items-center">
                        <div className="flex-1">
                           { renderViewStatus(item.remarks,item.notes)}
                        </div>
                        </div>
                    </div>
                    <div className="col py-1">
                        <div className="row flex-end-center g-0">
                        <div className="col-auto pe-2">
                            <div className="fs-10 fw-semi-bold">{moment(item.updatedAt).format('DD, MMM YYYY, h:mm')}</div>
                        </div>
                       
                        </div>
                    </div>
                    </div>
                    ):<p>No records found</p>
                    }

                </div>

                { searchcount>10?
                <div className="bg-200 p-3 border-info border-bottom">
                <ReactPaginate
                    previousLabel={`Previous`}
                    nextLabel={`Next`}
                    breakLabel={`...`}

                    pageCount={searchcount/10}
                    onPageChange={handlePageClick}
                    containerClassName="pagination pagination-sm m-0 float-right"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    activeClassName="active"
                    activeLinkClassName="active"
                    />
                    </div>
                    :null
                }
                    </div>
                


            {/* Place content here */}
                    
        </div>
    </div>
    );
}
export default CallRecords;