import React, { useEffect } from "react";
import Header from "../../Template/Header";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import axios from "axios";
import * as constant from "../../../Constant";
import Select from "react-select";
import {useFormik } from "formik";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import {useNavigate,useParams} from  "react-router-dom";
import {AllocateSheetValidate} from "../../Validations/FormValidation";
import Skeleton from "../../Template/Skeleton";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";




const initialAllocationValue = {
  staffid:""
};

const SheetDetails =()=>{
  const redirect=useNavigate();
  const {sheetid}=useParams();


  const [callerStaff,setCallerStaff]=React.useState([]);
  const [single_sheet,setSingle_sheet]=React.useState([]);
  const [skeleton_flag_col1,setSkeleton_flag_col1]=React.useState(false);
  const [skeleton_flag_col2,setSkeleton_flag_col2]=React.useState(false);
  const [sheetmetadata,setSheetmetadata]=React.useState([]);
  const [loader,setLoader]=React.useState(false);

  let [serialNo, setSerialNo] = React.useState(1);



            const getCallerRecords=async ()=>{

                await axios.get(
                      `${constant.SERVER_BASE_URL}staff/get-employees`, {
                          headers: {
                              "Content-Type":"application/json",
                              authorization: `Bearer ${localStorage.getItem("token")}`,
                          },
                      }).then((resp)=>{
                        var staffArray=resp.data;
                        let filteredUsers = staffArray.filter((user) => {
                          return user.Designation==="CALLER";
                        });
                        console.log(filteredUsers);
                          setCallerStaff(filteredUsers);
                      });
                    }


              const getSingleSheetRecords=async ()=>{

                await axios.get(
                      `${constant.SERVER_BASE_URL}datasheet/get-single-sheet-records`, {
                        params:{sheetid:sheetid},
                          headers: {
                              "Content-Type":"application/json",
                              authorization: `Bearer ${localStorage.getItem("token")}`,
                          },
                      }).then((resp)=>{

                        if(resp.data.status==="SUCCESS"){
                              // console.log("Single Sheet",resp.data.value);
                              setSkeleton_flag_col1(true);
                              
                              setSingle_sheet(resp.data.value);
                        }

                      });
                    }


    
              const getSheetRecords=async ()=>{
              await axios.get(`${constant.SERVER_BASE_URL}datasheet/get-sheet-records/${sheetid}`,{
                headers:{
                  "Content-Type":"application/json",
                  authorization:`Bearer ${localStorage.getItem('token')}`
                }
              }).then((resp)=>{
                if(resp.data.status==="SUCCESS"){
                  setSheetmetadata(resp.data.value);
                }else{
                  /* toast.error(`${resp.data.message}`, {
                    position: "top-center"
                    }); */
                }
              });
            }

            const saveAllocation=useFormik({
              initialValues:initialAllocationValue,
              validationSchema:AllocateSheetValidate,
              onSubmit:(values,action)=>{
                Swal.fire({
                  title: "Are you sure?",
                  text: `You want to allocate this sheet to ${values.staffid.label} !!`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Update it!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    setLoader(true);
                    
                    // console.log(values);
                    const dataObj={
                        staffid:values.staffid.value,
                        sheetid:sheetid,
                        StaffName:values.staffid.label
                    };
                    
                      await axios.get(`${constant.SERVER_BASE_URL}datasheet/add-new-sheet`,
                      {
                        params:dataObj,
                        headers:{
                          "Content-Type":"application/json",
                          authorization:`Bearer ${localStorage.getItem('token')}`
                        }
                        
                      }).then((resp)=>{
                        if(resp.data.status==="SUCCESS"){
                          setLoader(true);
                          getSingleSheetRecords();
                          getSheetRecords();

                          toast.success(resp.data.message, {
                            position: "top-center",
                            className: "text-center",
                          });
                          
                        }else if(resp.data.status==="ERROR"){
                          setLoader(false);
                          toast.error(resp.data.message, {
                            position: "top-center",
                            className: "text-center",
                          });
                        }else if(resp.data.status==="TOKEN_EXPIRED"){
                          localStorage.removeItem("adminLogin");
                          localStorage.removeItem("token");
                          redirect("/login");
                          window.location.reload();
                        }
                      }).then((err)=>{
                        toast.error(err, {
                          position: "top-center",
                          className: "text-center",
                        });
                        setLoader(false);
                      });
          
                  }
                });
              }
            });

              const optionStaff=callerStaff.map((item)=>({
                value:item._id,
                label:item.EmployeeName
            }));

            const ChangeCaller=(e)=>{
              let event={
                target: {
                  name: "staffid",
                  value: e,
                },
              };
              saveAllocation.handleChange(event);
            };


            const renderViewStatus=(status,notes,updated)=>{
              switch (status) {
                case 'Others':
                    return (
                      <>
                      <span className="badge rounded-pill ms-2 badge-subtle-success" style={{fontSize: 12}}>Others</span>
                        <p className="fs-11 text-500 bg-light mt-1 p-1 rounded">{notes}</p>
                        <p class="text-500 fs-11 mb-0 ms-3"><i>Update At: {moment(updated).format('DD MMM,YYYY HH:mm')}</i></p>
                      </>
                    );
                case 'Not Answered':
                  return (
                    <>
                    <span className="badge rounded-pill ms-2 badge-subtle-warning" style={{fontSize: 12}}>Not Answered</span>
                    <p class="text-500 fs-11 mb-0 ms-3"><i>Update At: {moment(updated).format('DD MMM,YYYY HH:mm')}</i></p>
                    </>
                  );
                case 'Call Another Time':
                  return (
                    <>
                    <span className="badge rounded-pill ms-2 badge-subtle-info" style={{fontSize: 12}}>Call Another Time</span>
                    <p class="text-500 fs-11 mb-0 ms-3"><i>Update At: {moment(updated).format('DD MMM,YYYY HH:mm')}</i></p>
                    </>
                  );
                case 'Number Invalid':
                  return (
                    <>
                    <span className="badge rounded-pill ms-2 badge-subtle-danger" style={{fontSize: 12}}>Call Another Time</span>
                    <p class="text-500 fs-11 mb-0 ms-3"><i>Update At: {moment(updated).format('DD MMM,YYYY HH:mm')}</i></p>
                    </>
                  );
                case 'Not Interested':
                  return (
                    <>
                    <span className="badge rounded-pill ms-2 bg-400" style={{fontSize: 12}}>Call Another Time</span>
                    <p class="text-500 fs-11 mb-0 ms-3"><i>Update At: {moment(updated).format('DD MMM,YYYY HH:mm')}</i></p>
                    </>
                  );
                default:
                  return <></>;
              }
            }

            useEffect(()=>{
              getCallerRecords();
              getSingleSheetRecords();
              getSheetRecords();
            },[]);

      // console.log('single_sheet',single_sheet.length);
return (
<div className="content">
<Header />

<div>
<div className="row g-3 mb-3">
<div className="col-lg-6 pe-lg-2 mb-3">
    <div className="card h-lg-100 overflow-hidden">
      <div className="card-header bg-300 border-top border-info">
        <div className="row align-items-center">
          <div className="col">
            <h6 className="mb-0">Allocate Excel Sheet</h6>
          </div>
        
        </div>
      </div>
      {skeleton_flag_col1?
      <div className="card-body p-0" style={{height:'500px'}}>

        <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
          

          <div className="col py-1">
          <form className="form-horizontal" onSubmit={saveAllocation.handleSubmit}>
            <div className="row justify-content-center g-0">

              <div className="col-10">
                
                   <div className="form-group">
                      <label>File Name:  <i className="fs-11 text-500" data-tooltip-id="filename_tooltip" data-tooltip-content={`${single_sheet?single_sheet.filename:null}`}>{single_sheet?single_sheet.filename:null}</i></label>
                      <ReactTooltip id="filename_tooltip" />
                    </div>
                  { 
                  
                  single_sheet.status==="TAKEN" || single_sheet.status==="ALLOCATED"?
                <div className="form-group">
                <label>Allocated To:  <i className="fs-11 text-500">{single_sheet.allocatedto.EmployeeName}</i></label>
                </div>
                :
                  <>
                  <div className="form-group">
                      <Select 
                          name="staffid"
                          id="staffid"
                          placeholder="Select Caller"
                          onBlur={saveAllocation.handleBlur}
                        
                          onChange={ChangeCaller.bind(this)}
                          options={optionStaff}
                         
                        />
                          {saveAllocation.errors.staffid && saveAllocation.touched.staffid ? (
                        <div className="alert alert-danger small alert-dismissible p-1" role="alert">
                          {saveAllocation.errors.staffid.label}
                        </div>
                      ) : null}
                  </div>

                  <div className="form-group mt-4 mb-5 text-center">
                      { loader===false?
                      <button className="btn btn-outline-info btn-sm">
                        Allocate <i className="fas fa-paper-plane"></i>
                      </button>
                      :null
                        }

                        { loader===true?
                        <button className="btn btn-outline-info btn-sm disabled">
                          <div style={{display:'inline-flex'}} className="mb-2">
                            <p className="mb-0">Loading...</p>
                            <Spinner animation="border" variant="info" size="sm" />
                          </div>
                     </button>
                     :null
                     }
                    
                    


                     
                     
                      
                      
                  </div>
                  </>
                    
                    }
               
              </div>


            </div>
            </form>
          </div>
        </div>
     
    
       
       
      </div>
      :null
      }
      {/* <div className="card-footer bg-body-tertiary p-0">
        <a className="btn btn-sm btn-link d-block w-100 py-2" href="#!">Show More<span className="fas fa-chevron-right ms-1 fs-11" /></a>
        </div>  */}
    </div>
  </div>

  <div className="col-lg-6 pe-lg-2 mb-3">
    <div className="card h-md-100">
      <div className="card-header border-top border-info bg-300">
      <div className="row align-items-center">
          <div className="col">
            <h6 className="mb-0">Sheet Metadata</h6>
          </div>
        </div>
      </div>
      <div className="card-body justify-content-end">
        {skeleton_flag_col2?<Skeleton />:null}
        { sheetmetadata?sheetmetadata.map((item)=>
        <div className="row justify-content-between border-bottom border-200" key={item._id}>
                <div className="col ps-x1 py-1 position-static">
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-xl me-3">
                      <div className="avatar-name rounded-circle bg-primary-subtle text-dark">
                        <span className="fs-9 text-primary">{serialNo++}</span>
                      </div>
                    </div>
                    <div className="flex-1">
                      <h6 className="mb-0 d-flex align-items-center">
                        <span className="text-800 stretched-link">{item.mobileno}</span>
                      </h6>
                    </div>
                  </div>
                </div>
                 <div className="col py-1">
                  <div className="row flex-end-center g-0">
                    <div className="col-12" style={{zIndex: 999}}>
                      <div>
                          {
                            renderViewStatus(item.remarks,item.notes,item.updatedAt)
                          }
                         

                      </div>
                    </div>
                  </div>
                </div>
          </div>
        ):null
          }


      </div>
    </div>
  </div>


</div>
</div>
</div>
);
}
export default SheetDetails;