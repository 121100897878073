import React from "react";

const PreviewImage=({file})=>{
    const [preview,setPreview]=React.useState(null);
    const reader=new FileReader();
    reader.readAsDataURL(file);
    reader.onload=()=>{
        setPreview(reader.result);
    };

    return (
        <div>
            <img src={preview} className="rounded-circle img-fluid" alt="Preview" width={`70px`} height={`70px`}/>
        </div>
    );
}

export default PreviewImage;