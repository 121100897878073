import React, { useEffect } from "react";
import * as constant from "../../Constant";
import { Link, json, useNavigate } from "react-router-dom";




const Header = () => {
	const redirect = useNavigate();
	const [authdata, setAuthdata] = React.useState({});

	const auth = localStorage.getItem("adminLogin");

	
	
	





	useEffect(() => {
		if (!auth) {
			localStorage.removeItem("token");
			redirect("/login");
			window.location.reload();
		} else {
			const jsonAuth = JSON.parse(auth);
			var dataObj = {
				name: jsonAuth.EmployeeName,
				email: jsonAuth.EmployeeEmail,
				mobile: jsonAuth.EmployeeMobile,
				photo: jsonAuth.EmployeePhoto,
				designation: jsonAuth.Designation
			};
			setAuthdata(dataObj);
		}
		
	}, []);


	const logout = () => {
		localStorage.removeItem("adminLogin");
		localStorage.removeItem("token");
		redirect("/login");
		window.location.reload();
	};

	const handleGoBack = () => {
		redirect(-1);
	  };
	  
	  const handleGoNext = () => {
		redirect(1);
	  };
    // console.log(window.history);
	return (
		<nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
			<button
				className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarVerticalCollapse"
				aria-controls="navbarVerticalCollapse"
				aria-expanded="false"
				aria-label="Toggle Navigation"
			>
				<span className="navbar-toggle-icon">
					<span className="toggle-line" />
				</span>
			</button>
			<Link className="navbar-brand me-1 me-sm-3" to="/">
				<div className="d-flex align-items-center">
					<img
						className="me-2"
						src={`${constant.CLIENT_BASE_URL}/assets/img/icons/clt-crm-pro.png`}
						alt="logo"
						width={100}
					/>
				</div>
			</Link>

			<ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
				<li className="nav-item ps-2 pe-0 mb-4">
				<button onClick={handleGoBack} className="btn btn-outline-info btn-sm fs-9" style={{left:'15px',position:"absolute"}}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
				<button onClick={handleGoNext} className="btn btn-outline-info btn-sm fs-9" style={{left:'60px',position:"absolute"}}><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
				</li>
				<li className="nav-item ps-2 pe-0">
					<div className="dropdown theme-control-dropdown">
						
						<a
							className="nav-link d-flex align-items-center dropdown-toggle fa-icon-wait fs-9 pe-1 py-0"
							href="#"
							role="button"
							id="themeSwitchDropdown"
							data-bs-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<svg
								className="svg-inline--fa fa-sun fa-w-16 fs-7"
								data-fa-transform="shrink-2"
								data-theme-dropdown-toggle-icon="light"
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="sun"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								data-fa-i2svg
								style={{ transformOrigin: "0.5em 0.5em" }}
							>
								<g transform="translate(256 256)">
									<g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)">
										<path
											fill="currentColor"
											d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"
											transform="translate(-256 -256)"
										/>
									</g>
								</g>
							</svg>
							{/* <span class="fas fa-sun fs-7" data-fa-transform="shrink-2" data-theme-dropdown-toggle-icon="light"></span> Font Awesome fontawesome.com */}
							<svg
								className="svg-inline--fa fa-moon fa-w-16 fs-7 d-none"
								data-fa-transform="shrink-3"
								data-theme-dropdown-toggle-icon="dark"
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="moon"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								data-fa-i2svg
								style={{ transformOrigin: "0.5em 0.5em" }}
							>
								<g transform="translate(256 256)">
									<g transform="translate(0, 0)  scale(0.8125, 0.8125)  rotate(0 0 0)">
										<path
											fill="currentColor"
											d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z"
											transform="translate(-256 -256)"
										/>
									</g>
								</g>
							</svg>
							{/* <span class="fas fa-moon fs-7 d-none" data-fa-transform="shrink-3" data-theme-dropdown-toggle-icon="dark"></span> Font Awesome fontawesome.com */}
							<svg
								className="svg-inline--fa fa-adjust fa-w-16 fs-7 d-none"
								data-fa-transform="shrink-2"
								data-theme-dropdown-toggle-icon="auto"
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="adjust"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								data-fa-i2svg
								style={{ transformOrigin: "0.5em 0.5em" }}
							>
								<g transform="translate(256 256)">
									<g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)">
										<path
											fill="currentColor"
											d="M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z"
											transform="translate(-256 -256)"
										/>
									</g>
								</g>
							</svg>
							{/* <span class="fas fa-adjust fs-7 d-none" data-fa-transform="shrink-2" data-theme-dropdown-toggle-icon="auto"></span> Font Awesome fontawesome.com */}
						</a>
						<div
							className="dropdown-menu dropdown-menu-end dropdown-caret border py-0 mt-3"
							aria-labelledby="themeSwitchDropdown"
						>
							<div className="bg-white dark__bg-1000 rounded-2 py-2">
								<button
									className="dropdown-item d-flex align-items-center gap-2 active"
									type="button"
									value="light"
									data-theme-control="theme"
								>
									<svg
										className="svg-inline--fa fa-sun fa-w-16"
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="sun"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										data-fa-i2svg
									>
										<path
											fill="currentColor"
											d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"
										/>
									</svg>
									{/* <span class="fas fa-sun"></span> Font Awesome fontawesome.com */}
									Light
									<svg
										className="svg-inline--fa fa-check fa-w-16 dropdown-check-icon ms-auto text-600"
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="check"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										data-fa-i2svg
									>
										<path
											fill="currentColor"
											d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
										/>
									</svg>
									{/* <span class="fas fa-check dropdown-check-icon ms-auto text-600"></span> Font Awesome fontawesome.com */}
								</button>
								<button
									className="dropdown-item d-flex align-items-center gap-2"
									type="button"
									value="dark"
									data-theme-control="theme"
								>
									<svg
										className="svg-inline--fa fa-moon fa-w-16"
										data-fa-transform
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="moon"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										data-fa-i2svg
									>
										<path
											fill="currentColor"
											d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z"
										/>
									</svg>
									{/* <span class="fas fa-moon" data-fa-transform=""></span> Font Awesome fontawesome.com */}
									Dark
									<svg
										className="svg-inline--fa fa-check fa-w-16 dropdown-check-icon ms-auto text-600"
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="check"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										data-fa-i2svg
									>
										<path
											fill="currentColor"
											d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
										/>
									</svg>
									{/* <span class="fas fa-check dropdown-check-icon ms-auto text-600"></span> Font Awesome fontawesome.com */}
								</button>
								<button
									className="dropdown-item d-flex align-items-center gap-2"
									type="button"
									value="auto"
									data-theme-control="theme"
								>
									<svg
										className="svg-inline--fa fa-adjust fa-w-16"
										data-fa-transform
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="adjust"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										data-fa-i2svg
									>
										<path
											fill="currentColor"
											d="M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z"
										/>
									</svg>
									{/* <span class="fas fa-adjust" data-fa-transform=""></span> Font Awesome fontawesome.com */}
									Auto
									<svg
										className="svg-inline--fa fa-check fa-w-16 dropdown-check-icon ms-auto text-600"
										aria-hidden="true"
										focusable="false"
										data-prefix="fas"
										data-icon="check"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
										data-fa-i2svg
									>
										<path
											fill="currentColor"
											d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
										/>
									</svg>
									{/* <span class="fas fa-check dropdown-check-icon ms-auto text-600"></span> Font Awesome fontawesome.com */}
								</button>
							</div>
						</div>
					</div>
				</li>
				
				<li className="nav-item dropdown">
					<a
						className="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
						id="navbarDropdownNotification"
						role="button"
						data-bs-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						data-hide-on-body-scroll="data-hide-on-body-scroll"
					>
						<svg
							className="svg-inline--fa fa-bell fa-w-14"
							data-fa-transform="shrink-6"
							style={{
								fontSize: 33,
								transformOrigin: "0.4375em 0.5em",
							}}
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="bell"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
							data-fa-i2svg
						>
							<g transform="translate(224 256)">
								<g transform="translate(0, 0)  scale(0.625, 0.625)  rotate(0 0 0)">
									<path
										fill="currentColor"
										d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
										transform="translate(-224 -256)"
									/>
								</g>
							</g>
						</svg>
						{/* <span class="fas fa-bell" data-fa-transform="shrink-6" style="font-size: 33px;"></span> Font Awesome fontawesome.com */}
					</a>
					<div
						className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
						aria-labelledby="navbarDropdownNotification"
					>
						<div className="card card-notification shadow-none">
							<div className="card-header">
								<div className="row justify-content-between align-items-center">
									<div className="col-auto">
										<h6 className="card-header-title mb-0">
											Notifications
										</h6>
									</div>
									<div className="col-auto ps-0 ps-sm-3">
										<a
											className="card-link fw-normal"
											href="#"
										>
											Mark all as read
										</a>
									</div>
								</div>
							</div>
							<div
								className="scrollbar-overlay"
								style={{ maxHeight: "19rem" }}
								data-simplebar="init"
							>
								<div
									className="simplebar-wrapper"
									style={{ margin: 0 }}
								>
									<div className="simplebar-height-auto-observer-wrapper">
										<div className="simplebar-height-auto-observer" />
									</div>
									<div className="simplebar-mask">
										<div
											className="simplebar-offset"
											style={{ right: 0, bottom: 0 }}
										>
											<div
												className="simplebar-content-wrapper"
												tabIndex={0}
												role="region"
												aria-label="scrollable content"
												style={{
													height: "auto",
													overflow: "hidden",
												}}
											>
												<div
													className="simplebar-content"
													style={{ padding: 0 }}
												>
													<div className="list-group list-group-flush fw-normal fs-10">
														<div className="list-group-title border-bottom">
															NEW
														</div>
														<div className="list-group-item">
															<a
																className="notification notification-flush notification-unread"
																href="#!"
															>
																<div className="notification-avatar">
																	<div className="avatar avatar-2xl me-3">
																		<img
																			className="rounded-circle"
																			src="assets/img/team/1-thumb.png"
																			alt="icon"
																		/>
																	</div>
																</div>
																<div className="notification-body">
																	<p className="mb-1">
																		<strong>
																			Emma
																			Watson
																		</strong>{" "}
																		replied
																		to your
																		comment
																		: "Hello
																		world
																		😍"
																	</p>
																	<span className="notification-time">
																		<span
																			className="me-2"
																			role="img"
																			aria-label="Emoji"
																		>
																			💬
																		</span>
																		Just now
																	</span>
																</div>
															</a>
														</div>
														<div className="list-group-item">
															<a
																className="notification notification-flush notification-unread"
																href="#!"
															>
																<div className="notification-avatar">
																	<div className="avatar avatar-2xl me-3">
																		<div className="avatar-name rounded-circle">
																			<span>
																				AB
																			</span>
																		</div>
																	</div>
																</div>
																<div className="notification-body">
																	<p className="mb-1">
																		<strong>
																			Albert
																			Brooks
																		</strong>{" "}
																		reacted
																		to{" "}
																		<strong>
																			Mia
																			Khalifa's
																		</strong>{" "}
																		status
																	</p>
																	<span className="notification-time">
																		<svg
																			className="svg-inline--fa fa-gratipay fa-w-16 me-2 text-danger"
																			aria-hidden="true"
																			focusable="false"
																			data-prefix="fab"
																			data-icon="gratipay"
																			role="img"
																			xmlns="http://www.w3.org/2000/svg"
																			viewBox="0 0 496 512"
																			data-fa-i2svg
																		>
																			<path
																				fill="currentColor"
																				d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm114.6 226.4l-113 152.7-112.7-152.7c-8.7-11.9-19.1-50.4 13.6-72 28.1-18.1 54.6-4.2 68.5 11.9 15.9 17.9 46.6 16.9 61.7 0 13.9-16.1 40.4-30 68.1-11.9 32.9 21.6 22.6 60 13.8 72z"
																			/>
																		</svg>
																		{/* <span class="me-2 fab fa-gratipay text-danger"></span> Font Awesome fontawesome.com */}
																		9hr
																	</span>
																</div>
															</a>
														</div>
														<div className="list-group-title border-bottom">
															EARLIER
														</div>
														<div className="list-group-item">
															<a
																className="notification notification-flush"
																href="#!"
															>
																<div className="notification-avatar">
																	<div className="avatar avatar-2xl me-3">
																		<img
																			className="rounded-circle"
																			src="assets/img/icons/weather-sm.jpg"
																			alt="weather"
																		/>
																	</div>
																</div>
																<div className="notification-body">
																	<p className="mb-1">
																		The
																		forecast
																		today
																		shows a
																		low of
																		20℃ in
																		California.
																		See
																		today's
																		weather.
																	</p>
																	<span className="notification-time">
																		<span
																			className="me-2"
																			role="img"
																			aria-label="Emoji"
																		>
																			🌤️
																		</span>
																		1d
																	</span>
																</div>
															</a>
														</div>
														<div className="list-group-item">
															<a
																className="border-bottom-0 notification-unread  notification notification-flush"
																href="#!"
															>
																<div className="notification-avatar">
																	<div className="avatar avatar-xl me-3">
																		<img
																			className="rounded-circle"
																			src="assets/img/logos/oxford.png"
																			alt="oxford"
																		/>
																	</div>
																</div>
																<div className="notification-body">
																	<p className="mb-1">
																		<strong>
																			University
																			of
																			Oxford
																		</strong>{" "}
																		created
																		an event
																		:
																		"Causal
																		Inference
																		Hilary
																		2019"
																	</p>
																	<span className="notification-time">
																		<span
																			className="me-2"
																			role="img"
																			aria-label="Emoji"
																		>
																			✌️
																		</span>
																		1w
																	</span>
																</div>
															</a>
														</div>
														<div className="list-group-item">
															<a
																className="border-bottom-0 notification notification-flush"
																href="#!"
															>
																<div className="notification-avatar">
																	<div className="avatar avatar-xl me-3">
																		<img
																			className="rounded-circle"
																			src="assets/img/team/10.jpg"
																			alt="10"
																		/>
																	</div>
																</div>
																<div className="notification-body">
																	<p className="mb-1">
																		<strong>
																			James
																			Cameron
																		</strong>{" "}
																		invited
																		to join
																		the
																		group:
																		United
																		Nations
																		International
																		Children's
																		Fund
																	</p>
																	<span className="notification-time">
																		<span
																			className="me-2"
																			role="img"
																			aria-label="Emoji"
																		>
																			🙋‍
																		</span>
																		2d
																	</span>
																</div>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="simplebar-placeholder"
										style={{ width: 0, height: 0 }}
									/>
								</div>
								<div
									className="simplebar-track simplebar-horizontal"
									style={{ visibility: "hidden" }}
								>
									<div
										className="simplebar-scrollbar"
										style={{ width: 0, display: "none" }}
									/>
								</div>
								<div
									className="simplebar-track simplebar-vertical"
									style={{ visibility: "hidden" }}
								>
									<div
										className="simplebar-scrollbar"
										style={{ height: 0, display: "none" }}
									/>
								</div>
							</div>
							<div className="card-footer text-center border-top">
								<a
									className="card-link d-block"
									href="app/social/notifications.html"
								>
									View all
								</a>
							</div>
						</div>
					</div>
				</li>
				<li className="nav-item dropdown px-1">
					<a
						className="nav-link fa-icon-wait nine-dots p-1"
						id="navbarDropdownMenu"
						role="button"
						data-hide-on-body-scroll="data-hide-on-body-scroll"
						data-bs-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={16}
							height={43}
							viewBox="0 0 16 16"
							fill="none"
						>
							<circle cx={2} cy={2} r={2} fill="#6C6E71" />
							<circle cx={2} cy={8} r={2} fill="#6C6E71" />
							<circle cx={2} cy={14} r={2} fill="#6C6E71" />
							<circle cx={8} cy={8} r={2} fill="#6C6E71" />
							<circle cx={8} cy={14} r={2} fill="#6C6E71" />
							<circle cx={14} cy={8} r={2} fill="#6C6E71" />
							<circle cx={14} cy={14} r={2} fill="#6C6E71" />
							<circle cx={8} cy={2} r={2} fill="#6C6E71" />
							<circle cx={14} cy={2} r={2} fill="#6C6E71" />
						</svg>
					</a>
					<div
						className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-caret-bg"
						aria-labelledby="navbarDropdownMenu"
					>
						<div className="card shadow-none">
							<div className="scrollbar-overlay nine-dots-dropdown" data-simplebar="init" >
								<div className="simplebar-wrapper" style={{ margin: 0 }} >
									<div className="simplebar-height-auto-observer-wrapper">
										<div className="simplebar-height-auto-observer" />
									</div>
									<div className="simplebar-mask">
										<div className="simplebar-offset" style={{ right: 0, bottom: 0 }} >
											<div className="simplebar-content-wrapper" tabIndex={0} role="region"								aria-label="scrollable content"
												style={{
													height: "auto",
													overflow: "hidden",
												}}
											>
												<div className="simplebar-content"
													style={{ padding: 0 }}
												>
													<div className="card-body px-3">
														<div className="row text-center gx-0 gy-0">
															<div className="col-4">
																<Link
																	className="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
																	to="/my-profile"
																>
																	<div className="avatar avatar-2xl">
																		
																		<img
																			className="rounded-circle"
																			src={`${constant.SERVER_BASE_URL}images/${authdata.photo?authdata.photo:'profile-avatar.png'}`}
																			alt="profile"
																		/>
																	</div>
																	<p className="mb-0 fw-medium text-800 text-truncate fs-11">
																		Account
																	</p>
																</Link>
															</div>
															<div className="col-4">
																<Link
																	className="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
																	to="/history"
																	
																>
																	<img
																		className="rounded"
																		src={`${constant.CLIENT_BASE_URL}assets/img/icons/delay.png`}
																		alt="delay"
																		width={
																			40
																		}
																		height={
																			40
																		}
																	/>
																	<p className="mb-0 fw-medium text-800 text-truncate fs-11 pt-1">
																		History
																	</p>
																</Link>
															</div>







														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="simplebar-placeholder"
										style={{ width: 0, height: 0 }}
									/>
								</div>
								<div
									className="simplebar-track simplebar-horizontal"
									style={{ visibility: "hidden" }}
								>
									<div
										className="simplebar-scrollbar"
										style={{ width: 0, display: "none" }}
									/>
								</div>
								<div
									className="simplebar-track simplebar-vertical"
									style={{ visibility: "hidden" }}
								>
									<div
										className="simplebar-scrollbar"
										style={{ height: 0, display: "none" }}
									/>
								</div>
							</div>
						</div>
					</div>
				</li>
				<li className="nav-item dropdown">
					<a
						className="nav-link pe-0 ps-2"
						id="navbarDropdownUser"
						role="button"
						data-bs-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<div className="avatar avatar-xl">
							<img
								className="rounded-circle"
								src={`${constant.SERVER_BASE_URL}images/${authdata.photo?authdata.photo:''}`}
								alt="profile"
							/>
						</div>
					</a>
					<div
						className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
						aria-labelledby="navbarDropdownUser"
					>
						<div className="bg-white dark__bg-1000 rounded-2 py-2">
							<a
								className="dropdown-item fw-bold text-warning"
								href="#!"
							>


								<span>{authdata.name?authdata.name:null}</span>

							</a>
							<span className="text-warning text-center small dropdown-item fw-bold text-warning">({authdata.designation?authdata.designation:null})</span>
							<div className="dropdown-divider" />
							<Link
								className="dropdown-item"
								to="/my-profile"
							>
								Profile &amp; account
							</Link>

							<div className="dropdown-divider" />

							<button
								onClick={logout}
								className="dropdown-item"

							>
								Logout
							</button>
						</div>
					</div>
				</li>
			</ul>
		</nav>
	);
};

export default Header;
