import React, { useEffect } from "react";
import Header from "../Template/Header";
import * as constant from "../../Constant";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
 import * as echarts from "echarts";


const CallingDash =()=>{
const [addedsheets,setAddedsheets]=React.useState([]);
const [takencount,setTakencount]=React.useState(0);

  const addNewSheet=async ()=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to add a new sheet!!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Add New!'
    }).then(async (result) => {
      if (result.isConfirmed) {

        await axios.get(`${constant.SERVER_BASE_URL}datasheet/add-new-sheet`,{
          headers:{
            "Content-Type":"application/json",
            authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }).then((resp)=>{
         if(resp.data.status==="SUCCESS"){
            toast.success(`${resp.data.message}`, {
              position: "top-center"
            });
            getAllocatedSheets();
         }else{
          toast.error(`${resp.data.message}`, {
            position: "top-center"
          });
         }
        });
      }
    });
  }





  const getAllocatedSheets=async ()=>{
    await axios.get(`${constant.SERVER_BASE_URL}datasheet/get-allocated-sheet`,{
      headers:{
        "Content-Type":"application/json",
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((resp)=>{
     if(resp.data.status==="SUCCESS"){
      var sheetArr=resp.data.value;
      var takenSheetArr=sheetArr.filter((item) => {
        return (item.status==="TAKEN");
      });      
      setTakencount(takenSheetArr.length);
      setAddedsheets(sheetArr);
      setTimeout(() => {
        chartCallResponse(sheetArr);
      }, 500);
      
     }else{
      toast.error(`${resp.data.message}`, {
        position: "top-center"
      });
     }
    });
  }



  useEffect(()=>{
    getAllocatedSheets();
    
  },[]);

  
  const chartCallResponse=(addedsheets)=>{
    
  for(let item of addedsheets){
  
    var chartDom = document.getElementById(`chart-call-responses-${item._id}`);
    var myChart = echarts.init(chartDom);    
    var colorPalette = ['#e63757', '#f5803e', '#d8e2ef','#27bcfd','#00d27a'];
  var  option = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: `${item.datetime}`,
          type: 'pie',
          showBackground:true,
          radius: ['50%', '43%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 18,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },

          data: [
            { value: `${item.noinv.value}`, name: `${item.noinv.label}:` },
            { value: `${item.notans.value}`, name: `${item.notans.label}:` },
            { value: `${item.notint.value}`, name: `${item.notint.label}:` },
            { value: `${item.cat.value}`, name: `${item.cat.label}:` },
            { value: `${item.others.value}`, name: `${item.others.label}:` }
          ],
          color: colorPalette,
        }
      ],
      graph: {
      color : colorPalette
      }
    };
    
    option && myChart.setOption(option);

  }
    
  }




    const isFluid = JSON.parse(localStorage.getItem('isFluid'));
    if (isFluid) {
        var container = document.querySelector('[data-layout]');
        container.classList.remove('container');
        container.classList.add('container-fluid');
    }

    return (
      
            
    <div className="content">
        <Header />
    
      
      
      <div>
       
        <div className="row g-3 mb-3">

       <div className="col-md-12">
        <div className="card bg-100 shadow-none border">
            <div className="row gx-0 flex-between-center">
            <div className="col-sm-auto d-flex align-items-center"><img className="ms-n2" src="/assets/img/generic/crm-bar-chart.png" width={90} />
                <div>
                <h6 className="text-primary fs-10 mb-0">Welcome to </h6>
                <h5 className="text-primary fw-bold mb-0">Calling <span className="text-info fw-medium">Department</span></h5>
                </div><img className="ms-n4 d-md-none d-lg-block" src="/assets/img/generic/crm-line-chart.png" width={150} />
               
            </div>
            <div className="col-md-auto p-3">
                <form className="row align-items-center g-3">
                <div className="col-md-auto position-relative">
                      {
                       /*  takencount===0?
                        <button onClick={addNewSheet} type="button" className="btn btn-sm btn-outline-info"> <i className="fas fa-plus-circle"></i> Add New Sheet</button>
                        :
                        <button type="button" className="btn btn-sm btn-outline-info disabled"> <i className="fas fa-plus-circle"></i> Add New Sheet</button> */
                      }
                        
                    </div>
                </form>
            </div>
            </div>
        </div>
        </div>
        
        { 
        addedsheets?addedsheets.map((item)=>
          <div className="col-md-6 col-xxl-3" key={item._id}>
            <div className="card h-md-100">

         

              <div className="card-body">
                <div className="row h-100 justify-content-between g-0">
                <div className="row">
                      <div className="col-7">
                        <Link to={`/calling-list/${item._id}`}>
                            <h6 className="mt-1">{item.datetime}</h6>
                      </Link>
                      </div>

                      <div className="col-5" style={{position:'relative',zIndex:99}}>
                          <Link to={`/calling-list/${item._id}`}>
                                <h6 className="nav-link btn-sm me-1 small">View Details</h6>
                          </Link>
                            
                      </div>
                    </div>
<div className="row">
                  <div className="col-5 col-sm-5 col-xxl pe-2">
                  
                   
                    <div className="fs-11 mt-3">
                   

                      <div className="d-flex flex-between-center mb-1">
                        <div className="d-flex align-items-center">
                          <span className="dot bg-danger" />
                        <span className="fw-semi-bold">{item.noinv.label}</span></div>
                        <div className="d-xxl-none">{item.noinv.value}</div>
                      </div>


                      <div className="d-flex flex-between-center mb-1">
                        <div className="d-flex align-items-center">
                          <span className="dot bg-warning" />
                        <span className="fw-semi-bold">{item.notans.label}</span></div>
                        <div className="d-xxl-none">{item.notans.value}</div>
                      </div>

                      
                      <div className="d-flex flex-between-center mb-1">
                        <div className="d-flex align-items-center">
                          <span className="dot bg-300" />
                          <span className="fw-semi-bold">{item.notint.label}</span>
                          </div>
                        <div className="d-xxl-none">{item.notint.value}</div>
                      </div>

                      <div className="d-flex flex-between-center mb-1">
                        <div className="d-flex align-items-center">
                          <span className="dot bg-info" />
                          <span className="fw-semi-bold">{item.cat.label}</span>
                          </div>
                        <div className="d-xxl-none">{item.cat.value}</div>
                      </div>
                      <div className="d-flex flex-between-center mb-1">
                        <div className="d-flex align-items-center">
                          <span className="dot bg-success" />
                          <span className="fw-semi-bold">{item.others.label}</span>
                          </div>
                        <div className="d-xxl-none">{item.others.value}</div>
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-7">
                  <div className="col-auto">
                

                          <div className="chart-section position-relative" style={{width: '10%',left: '0.8rem',bottom: '2rem'}}>
                          <div id={`chart-call-responses-${item._id}`} className="position-absolute" style={{width: `${item.width}px`,height:`${item.height}px`, top:"-70%", left:"-40%"}}></div>
                          </div>

                  </div>
                  <div className="position-absolute translate-middle text-1100 fs-7 d-xxl-none" style={{top:"60.5%", left:"80%"}}>{item.totalrecords}</div>
                  </div>
                  </div>
                </div>
              </div>



            
            </div>
          </div>
        ):null
    }
        
         
        </div>
      
       
     
      
        <footer className="footer">
          <div className="row g-0 justify-content-between fs-10 mt-4 mb-3">
            <div className="col-12 col-sm-auto text-center">
              <p className="mb-0 text-600">Thank you for creating with Falcon <span className="d-none d-sm-inline-block">| </span><br className="d-sm-none" /> 2023 © <a href="https://themewagon.com">Themewagon</a></p>
            </div>
            <div className="col-12 col-sm-auto text-center">
              <p className="mb-0 text-600">v3.19.0</p>
            </div>
          </div>
        </footer>
      </div>
     
  


</div>

    );
}

export default CallingDash;