import React,{useEffect}  from "react";
import {useNavigate,Link} from "react-router-dom";
import Swal from "sweetalert2";
import  Axios  from "axios";
import { toast } from "react-toastify"; 
import { Formik,useFormik } from "formik";
import { LoginValidation } from "./Components/Validations/FormValidation";
import * as constant from "../src/Constant";
const initialValues={
  username:'',
  password:''
};

const Login=()=>{
const redirect=useNavigate();
const [toggle,setToggle]=React.useState(true);

useEffect(()=>{
  const auth=localStorage.getItem("adminLogin");
  if(auth){
      redirect("/");
  }else{
    redirect("/login");
  }
},[]);

const {values,onClick,touched,handleBlur,handleChange,handleSubmit,errors}=useFormik({
  initialValues:initialValues,
  validationSchema:LoginValidation,
  onSubmit: async (values,action)=>{
    
    Swal.fire({
      title: 'Are you sure?',
      text: "did you verify your credentials is correct!!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Log in!'
    }).then(async (result) => {
      if (result.isConfirmed) {
          let result = await Axios.post(
            `${constant.SERVER_BASE_URL}login/admin-login`,
            values,
            {
              headers:{
                "Content-Type":"application/json"
              }
          }).then((resp)=>{
            
              if('auth' in resp.data){
                toast.success(resp.data.res.message, {
                  position: "top-center"
                });
                var authdata=resp.data.res.value;
                delete authdata.password;
              localStorage.setItem("adminLogin",JSON.stringify(authdata));
              localStorage.setItem('token',resp.data.auth);
              redirect("/");
              window.location.reload();
            }else{
              toast.error(resp.data.message, {
                position: "top-center"
              });
            }
          });
      }
    })
  }
});


const passAppear=(tg)=>{
  toggle?setToggle(false):setToggle(true);
}

return (
<main className="main" id="top">
  <div className="container" data-layout="container">
    <div className="row flex-center min-vh-100 py-6">
      <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <Link className="d-flex flex-center mb-4" to="/">
          <img className="me-2" src="assets/img/icons/clt-crm-pro.png" alt width={168} />
          
          </Link>
        <div className="card">
          <div className="row flex-between-center mb-2 p-3">
            <div className="col text-center">
              <h5>Log In</h5>
            </div>
          </div>
          <div className="card-body p-4 p-sm-5">
            <form  onSubmit={handleSubmit}>
              <div className="mb-3">
                <input className="form-control" type="email" onChange={handleChange} onBlur={handleBlur} value={values.username}  name="username" id="username" placeholder="Email address" />
              </div>
              {
                  errors.username && touched.username?<div class="alert alert-danger small alert-dismissible" role="alert">
                    <strong>Error!</strong> {errors.username}
                  </div>:null
                  }

              <div className="mb-3">
                <input className="form-control" type={`${toggle?'password':'text'}`} onBlur={handleBlur} onChange={handleChange} value={values.password} name="password" placeholder="Password" />
                  <div className="text-end" onClick={passAppear.bind(this,toggle)} >
                    { toggle?
                    <div className="text-end" style={{position:'relative',width:'25px',right:'20px',float:'inline-end',top:'-30px',opacity:'0.5'}}>
                    <i className="fas fa-eye-slash"></i>
                    </div>:''
                    }

                  { !toggle ?
                    <div  className="text-end" style={{position:'relative',width:'25px',right:'20px',float:'inline-end',top:'-30px',opacity:'0.5'}}>
                    <i className="fas fa-eye"></i>
                    </div>:''
                }
              
                  </div>
                </div>
                

{
        errors.password && touched.password?<div className="alert alert-danger small alert-dismissible" role="alert">
          <strong>Error!</strong> {errors.password}
        </div>:null
        }

              <div className="mb-3">
                <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Log in</button>
                </div> 
            </form>
            <div className="position-relative mt-4">
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
);
}

export default Login;
