import React,{useState,useEffect} from "react";
import Header from "../../Template/Header";
import {useFormik } from "formik";
import Swal from "sweetalert2";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../../Constant";
import Select from "react-select";
import {Link,useNavigate,useParams} from  "react-router-dom";

const initialAddCommissionValues = {
    staffid:"",
    caller_number:"",
    caller_name:"",
    extension:"",
    commissiontype:"",
    fixedAmount: "",
    percentage: "",
  };
const EditCaller=()=>{
    const redirect=useNavigate();
    const [callStaff,setCallerStaff]=React.useState([]);
    const [callername,setCallername]=React.useState("");
    var {callerid}=useParams();

    const updateCaller=useFormik({
        initialValues:initialAddCommissionValues,
        validationSchema:"",
        onSubmit:(values,action)=>{
          Swal.fire({
            title: "Are you sure?",
            text: "You want to update commission!!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update it!",
          }).then(async (result) => {
            if (result.isConfirmed) {
                
              if(values.commissiontype==="FIXED_AMOUNT"){
                values.percentage=0;
              }

              if(values.commissiontype==="PERCENTAGE"){
                values.fixedAmount=0;
              }
              values.caller_name=callername;
             
                await axios.put(`${constant.SERVER_BASE_URL}staff/update-caller/${callerid}`,
                  values,
                {
                  headers:{
                    "Content-Type":"application/json",
                    authorization:`Bearer ${localStorage.getItem('token')}`
                  }
                }).then((resp)=>{
                  if(resp.data.status==="SUCCESS"){
                    toast.success(resp.data.message, {
                      position: "top-center",
                      className: "text-center",
                    });
                  }else if(resp.data.status==="ERROR"){
                    toast.error(resp.data.message, {
                      position: "top-center",
                      className: "text-center",
                    });
                  }else if(resp.data.status==="TOKEN_EXPIRED"){
                    localStorage.removeItem("adminLogin");
                    localStorage.removeItem("token");
                    redirect("/login");
                    window.location.reload();
                  }
                }).then((err)=>{
                  toast.error(err, {
                    position: "top-center",
                    className: "text-center",
                  });
                });
    
            }
          });
        }
      });

      const listStaffs = async () => {
      
        try {
      
              await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employees`, {
                    headers: {
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((resp)=>{
                    var staffArray=resp.data;
                  let filteredUsers = staffArray.filter((user) => {
                    return user.Designation==="CALLER";
                });
                    setCallerStaff(filteredUsers);
                });
            
        } catch (error) {
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };



    const getSingleCallerDetails=async ()=>{
        await axios.get(`${constant.SERVER_BASE_URL}staff/get-single-caller-records/${callerid}`,{
            headers:{
                "Content-Type":"application/json",
                 authorization:`Bearer ${localStorage.getItem('token')}`
            }
        }).then((resp)=>{
            if(resp.data.status==="SUCCESS"){      
                updateCaller.setValues({
                    staffid:resp.data.value.staffid,
                    caller_number:resp.data.value.caller_number,
                    extension:resp.data.value.extension,
                    commissiontype:resp.data.value.commissiontype,
                    fixedAmount:resp.data.value.fixedAmount,
                    percentage:resp.data.value.percentage
                });
                setCallername(resp.data.value.caller_name);
            }else{
                toast.error(resp.data.message, {
                    position: "top-center",
                    className: "text-center",
                });
            }
        });
    }


    useEffect(()=>{
        listStaffs();
        getSingleCallerDetails();
    },[]);

    const changeCaller=(event)=>{
        updateCaller.handleChange(event);
        var selectedtext=event.target.selectedOptions[0].text;
        setCallername(selectedtext);
    }
  return (
    <div className="content">
         <Header />
<div className="card mb-3">
<div className="card-header border-bottom">
<div className="row flex-between-end">
    <div className="col-auto align-self-center">
        <h5 className="mb-0"    data-anchor="data-anchor"  id="responsive-table">
            Add Caller          
        </h5>
    </div>
    <div className="col-auto ms-auto">
        <div
            className="nav flex-grow-1 mt-2"
            role="tablist"
        >
            <Link
                className="btn btn-sm btn-outline-info"
                to="/list-caller"
            >
                
                <i className="fas fa-plus-circle"></i> List Caller
            </Link>
        </div>
    </div>
</div>
</div>
    

<form className="form-horizontal" onSubmit={updateCaller.handleSubmit}>


<div className="card-body bg-body-tertiary">


<div className="tab-content">

    
<div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e" id="dom-edacbffe-6cbb-4ffb-9ba6-3fc869b4084e">
              <div className="row">
                    <div className="col">
                      <label className="form-label" htmlFor="exampleFormControlInput1"> Select Caller</label>
                      

                      <select className="form-control"	name="staffid" value={updateCaller.values.staffid} onChange={changeCaller.bind(this)}>
                        <option value="">Select</option>
                        {
                            callStaff?callStaff.map((item)=>
                                <option key={item._id}	value={item._id}>{item.EmployeeName}</option>
                            ):null
                        }
                        
                    </select>

                    </div>
                   
                    <div className="col">
                    <div className="">
                      <label className="form-label" htmlFor="exampleFormControlTextarea1">Mobile Number</label>
                      <input className="form-control" value={updateCaller.values.caller_number} onChange={updateCaller.handleChange} onBlur={updateCaller.handleBlur} type="text" name="caller_number" id="caller_number" placeholder="Mobile No." />
                    </div>
                  </div>

                  <div className="col">
                    <div className="">
                      <label className="form-label" htmlFor="exampleFormControlTextarea1">Extension</label>
                      <input className="form-control" onChange={updateCaller.handleChange} onBlur={updateCaller.handleBlur} value={updateCaller.values.extension} type="text" name="extension" id="extension" placeholder="i.e.10-99" />
                    </div>
                  </div>

               </div>

               <div className="row py-3 mt-3">
                      <div className="col-md-auto">
                       <label className="form-label" htmlFor="exampleFormControlTextarea1">Commission Type</label>
                        <div>
                            
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" checked={updateCaller.values.commissiontype==="FIXED_AMOUNT"} id="amount"  type="radio" name="commissiontype" value="FIXED_AMOUNT" onChange={updateCaller.handleChange} onBlur={updateCaller.handleBlur} />
                            <label className="form-check-label small" htmlFor="amount">Fixed Amount</label>
                        </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" checked={updateCaller.values.commissiontype==="PERCENTAGE"}   id="commision" type="radio"  name="commissiontype" value="PERCENTAGE" onChange={updateCaller.handleChange} onBlur={updateCaller.handleBlur} />
                                <label className="form-check-label small" htmlFor="commision">Variable Amount</label>
                            </div>
                        </div>


                        { updateCaller.values.commissiontype==="FIXED_AMOUNT"?
                        <div className="mb-3">
                            <label className="form-label" htmlFor="exampleFormControlTextarea2">Amount</label>
                            <input type="text" value={updateCaller.values.fixedAmount} onBlur={updateCaller.handleBlur} onChange={updateCaller.handleChange} id="fixedAmount" name="fixedAmount" className="form-control" placeholder="Enter Amount" /></div>:null
                        }

                        { updateCaller.values.commissiontype==="PERCENTAGE"?
                            <div className="mb-3">
                                <label className="form-label" htmlFor="exampleFormControlTextarea2">Percentage</label>
                                <input type="text" value={updateCaller.values.percentage} onBlur={updateCaller.handleBlur} onChange={updateCaller.handleChange} id="percentage" name="percentage" className="form-control" placeholder="Enter Percentage"/></div>:null
                        }

                        
                        </div>
                        </div>


 <button className="btn btn-sm btn-outline-info mt-3" type="submit">
     <i className="fas fa-save"></i> Save</button>
</div>
</div>
</div>
</form>

</div>


</div>
  )
}

export default EditCaller;
