import React,{useEffect} from "react";
import * as constant from '../../Constant'; 
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";
import Skeleton from "./Skeleton";

const Sidebar=()=>{
  const redirect = useNavigate();
const [privileges,setPrivileges]=React.useState([]);
  const getSidebar=async ()=>{
   
    await axios.get(`${constant.SERVER_BASE_URL}privilege/get-assigned-privileges`,{
      headers:{
        "Content-Type":"application/json",
        authorization:`Bearer ${localStorage.getItem('token')}`
      }
    }).then((resp)=>{

        if(resp.data.status==="TOKEN_EXPIRED"){
          localStorage.removeItem("adminLogin");
          localStorage.removeItem("token");
          redirect("/login");
          window.location.reload();
        }else{
          setPrivileges(resp.data);
        }
        
      
     
    });
  }

  useEffect(()=>{
    getSidebar();
  },[]);

  
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== 'transparent') {
        document.querySelector('.navbar-vertical').classList.add(`navbar-${navbarStyle}`);
    }
    
    return (
       <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
  <div className="d-flex align-items-center">
    <div className="toggle-icon-wrapper">
      <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Toggle Navigation" data-bs-original-title="Toggle Navigation">
        <span className="navbar-toggle-icon"><span className="toggle-line" /></span></button>
    </div>
    <Link className="navbar-brand" to="/">
      <div className="d-flex align-items-center py-3">
        <img className="me-2" src={`${constant.CLIENT_BASE_URL}assets/img/icons/clt-crm-pro.png`} alt="Logo" width={100} />
      
      </div>
    </Link>
  </div>

  <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
    <div className="navbar-vertical-content scrollbar">
      <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
        <li className="nav-item">
          {/* parent pages*/}
          <Link className="nav-link" to="/">
            <div className="d-flex align-items-center">
              <span className="nav-link-icon">
                <svg className="svg-inline--fa fa-chart-pie fa-w-17" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-pie" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544 512" data-fa-i2svg>
                <path fill="currentColor" d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z" /></svg></span><span className="nav-link-text ps-1">Dashboard</span></div>
          </Link>
       
        </li>
        {privileges.length ===0? <Skeleton /> : null }
        { privileges?privileges.map((item)=>
        <li className="nav-item mb-3 mt-3" key={item._id}>
          <a className="nav-link dropdown-indicator" href={"#"+item.mainmenuid[0]} role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="dashboard">
            <div className="d-flex align-items-center">
             
              <span className="nav-link-text ps-1"> <i className={item.result[0].mainmenuicon}></i> {item.result[0].MainMenu}</span></div>
          </a>
          <ul className="nav collapse" id={item.mainmenuid[0]}>
            { item.records?item.records.map((sub)=>
            <li className="nav-item" key={sub._id}>
              <Link className="nav-link" to={"/"+sub.result_submenu[0].menuurl}>
                <div className="d-flex align-items-center"><span className="nav-link-text ps-1"> <i className={sub.result_submenu[0].submenuicon}></i> {sub.result_submenu[0].submenu} </span></div>
              </Link>
            </li>
            ):null
            }
          
           
          </ul>
        </li>
        ):null
    }
        

        
      </ul>
      
    </div>
  </div>

</nav>

    );
   
}

export default Sidebar;