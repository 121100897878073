import React, { useEffect } from "react";
import Header from "../../Template/Header";
import * as constant from "../../../Constant";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
import Skeleton from "../../Template/Skeleton";



const ListCallerLogs =  () => {
	const [callerlogs,setCallerlogs]=React.useState([]);
	const [audioplayer,setAudioplayer]=React.useState(null);
	const [offset,setOffset]=React.useState(0);
	const [pageno,setPageno]=React.useState(1);
	const [totalPage,setTotalPage]=React.useState(1);
	const [viewskeleton,setViewskeleton]=React.useState(true);
	let [serialNo, setSerialNo] = React.useState(1);

	const getCallerLogs=async (offset=0,pageno=1)=>{
		await axios.get(`${constant.SERVER_BASE_URL}staff/get-caller-logs`,
			{
				params:{
					limit:10,
					offset:offset,
					pageno:pageno
				},
				headers:{
					"Content-Type":"application/json",
					authorization:`Bearer ${localStorage.getItem('token')}`
				}
			}).then((resp)=>{
				
				 if(resp.data.status==="success"){
					setViewskeleton(false);

					var reminder=resp.data.data.total%10;
					var devidedValue=Math.floor(resp.data.data.total/10);
					var totalpg=0;
					if(reminder>0){
						totalpg=devidedValue+1;
					}else{
						totalpg=devidedValue;
					}

					setTotalPage(totalpg);
					setCallerlogs(resp.data.data.hits);
					
					
				}else{
					setViewskeleton(false);
					toast.error(resp.data.message, {
						position: "top-center",
						className: "text-center",
					  });
				}
			});
	}

	const gonext=(limit)=>{
		let page=pageno;
		let offset=(page*limit)+1;
		setPageno(page+1);
		setOffset(offset);
		getCallerLogs(offset,page+1);
	}

	const goprevious=(limit)=>{
		let page=pageno-1;
		var offset=0;
		if((page-1)===0){
			offset=0;
		}else{
			offset=((page-1)*limit)+1;
		}
		
		setPageno(page);
		setOffset(offset);

		getCallerLogs(offset,page);
	}


	const unlockRecording=async (filename,uniqueid)=>{
			await axios.get(`${constant.SERVER_BASE_URL}staff/download-call-recording`,
				{
					params:{
						filename:filename
					},
					headers:{
						"Content-Type":"application/json",
						authorization:`Bearer ${localStorage.getItem('token')}`
					}
				}).then((resp)=>{
					if(resp.data.status==="success"){
						var audiourl=resp.data.url;
						audiosection(audiourl,uniqueid);
						// return `<p>Hello ${audiourl}</p>`;
					}
				});
	}


	const audiosection=(url,uid)=>{
		var audioControl=`<audio controls>
							<source src=${url} type="audio/mp3" />
						</audio>`;
		document.getElementById('div_audio_'+uid).innerHTML=audioControl;
		return url;
	} 

   const callRemarks=(logs_details,ext)=>{

		if(logs_details.length===1){

			if(ext==="Extension"){
				if(logs_details[0].action==="missed"){
					return (
						<span>Incoming Calls {logs_details[0].action} By <del><span className="text-info">{logs_details[0].received_by[0].name}</span></del></span>
					);
				}else if(logs_details[0].action==="received"){
					return (
					<span>Incoming Calls {logs_details[0].action} By <span className="text-info">{logs_details[0].received_by[0].name}</span></span>
					);
				}else{
					return (<></>)
				}
			}else{
				if(logs_details[0].action==="received"){
					return ( 
					<span>Outgoing Calls {logs_details[0].action} By <span className="text-info">{logs_details[0].received_by[0].name}</span></span>
					);
				}else if(logs_details[0].action==="missed"){
					return(
						<span>Outgoing Calls {logs_details[0].action} By  <del><span className="text-info">{logs_details[0].received_by[0].name}</span></del></span>
						);
				}else{
					return (<></>)
				}
			}
			
		}else if(logs_details.length===2){
			if(ext==="Extension"){
				if(logs_details[0].action==="received" && logs_details[1].action==="received"){
					return (
						<span>Incoming Calls {logs_details[0].action} By <span className="text-info">{logs_details[0].received_by[0].name}</span> at <span className="text-info">(Extension)</span></span>
					);
				}else{
					return (
					<span>Incoming Calls {logs_details[0].action} By <del><span className="text-info">{logs_details[0].received_by[0].name}</span></del>. {logs_details[1].action} By Customer  at <span className="text-info">(Extension)</span></span>
					);
				}
			}else{
				if(logs_details[0].action==="received" && logs_details[1].action==="received"){
					return (
						<span>Outgoing Calls {logs_details[0].action} By <span className="text-info">{logs_details[0].received_by[0].name}</span> at <span className="text-info">(Extension)</span></span>
					);
				}else{
					return (
						<span>Outgoing Calls {logs_details[0].action} By <del><span className="text-info">{logs_details[0].received_by[0].name}.</span></del> at <span className="text-info">(Extension)</span></span>
					);
				}
			}
			
		}else if(logs_details.length===0){
			return <p>
				{
					ext==="Extension"?
					<span>Missed Calls at <span className="text-info">(Extension)</span></span>
					:
					<span>Call Missed</span>

				}
				</p>
		}
   }


	const callStatus=(logs_details,ext)=>{
		
			if(logs_details.length===1){

				if(ext==="Extension"){
						if(logs_details[0].action==="received"){
							return <div className="avatar-name rounded-circle bg-success-subtle text-dark">
							<span className="fs-9 text-success"><i className="fas fa-phone-alt"></i></span>
							</div>
						}else{
							return <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
								<span className="fs-9 text-danger"><i className="fas fa-phone-alt"></i></span>
								</div>
						}

				}else{

					if(logs_details[0].action==="received"){
						return <div className="avatar-name rounded-circle bg-primary-subtle text-dark">
						<span className="fs-9 text-primary"><i className="fas fa-phone-alt"></i></span>
						</div>
					}else{
						return <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
						<span className="fs-9 text-danger"><i className="fas fa-phone-alt"></i></span>
						</div>
					}
				}

			}else if(logs_details.length===2){

				if(ext==="Extension"){
						if(logs_details[0].action==="received" && logs_details[1].action==="received"){
							return <div className="avatar-name rounded-circle bg-success-subtle text-dark">
							<span className="fs-9 text-success"><i className="fas fa-phone-alt"></i></span>
							</div>
						}else{
							return <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
								<span className="fs-9 text-danger"><i className="fas fa-phone-alt"></i></span>
								</div>
						}

				}else{

					if(logs_details[0].action==="received" && logs_details[1].action==="received"){
						return <div className="avatar-name rounded-circle bg-primary-subtle text-dark">
						<span className="fs-9 text-primary"><i className="fas fa-phone-alt"></i></span>
						</div>
					}else{
						return <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
						<span className="fs-9 text-danger"><i className="fas fa-phone-alt"></i></span>
						</div>
					}
				}

			}else if(logs_details.length===0){
				return <div className="avatar-name rounded-circle bg-danger-subtle text-dark">
						<span className="fs-9 text-danger"><i className="fas fa-phone-alt"></i></span>
						</div>
			}
		

	}


	useEffect(()=>{
		getCallerLogs();
	},[]);
	
	const handleClick=(id)=>{
		console.log(id);
		document.getElementById(id).classList.toggle('collapse');
		document.getElementById(id).classList.toggle('collapsed');
	}

	const convertTime = (time) => {
		const [hours, minutes, seconds] = time.split(':').map(Number);
	
		const hoursText = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : '';
		const minutesText = minutes > 0 ? `${minutes} minute${minutes !== 1 ? 's' : ''}` : '';
		const secondsText = seconds > 0 ? `${seconds} second${seconds !== 1 ? 's' : ''}` : '';
	
		return `${hoursText}${hoursText && minutesText ? ', ' : ''}${minutesText}${(hoursText || minutesText) && secondsText ? ', ' : ''}${secondsText}`;
	  };


	  const currentdate = moment().format('DD-MM-YYYY');	
	return (
		<div className="content">
			<Header />
			<div className="card mb-3">
  <div className="card-header">
    <div className="row align-items-center justify-content-between">
      <div className="col-6 col-sm-auto d-flex align-items-center pr-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Caller Logs </h5>
      </div>
    
    </div>
  </div>
  <div className="card-body px-0 pt-0">
    <div className="dashboard-data-table">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
		<div className="row mx-1">
			<div className="col-sm-12 col-md-6 px-3" />
		<div className="col-sm-12 col-md-6 px-3" />
		</div>

		{viewskeleton?<Skeleton />:

		<div className="table-responsive">
			<table className="table table-sm table-striped table-bordered bg-200">
            <thead className="bg-200 text-900">
              <tr role="row">
			  <th className="no-sort pr-1 align-middle data-table-row-bulk-select sorting_asc">
                  <div className="custom-control custom-checkbox">
                    <input className="custom-control-input checkbox-bulk-select" id="checkbox-bulk-purchases-select" type="checkbox" data-checkbox-body="#purchases" data-checkbox-actions="#purchases-actions" data-checkbox-replaced-element="#dashboard-actions" />
					<label className="custom-control-label" htmlFor="checkbox-bulk-purchases-select" />
                  </div>
                </th>
				<th className="sort pr-1 align-middle sorting">Who</th>
				<th className="sort pr-1 align-middle sorting" >What</th>
				<th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0">When</th>
				</tr>
            </thead>
           
				
				{ callerlogs ? callerlogs.map((item)=>
				 <tbody key={item._source.additional_parameters[0].vl}>
              <tr data-toggle="collapse" onClick={() => handleClick(`row${item._source.additional_parameters[0].vl}`)} style={{borderBottom:'2px solid white'}}>
                <td className="align-middle sorting_1">
                  <div className="custom-control custom-checkbox">
                    <input className="custom-control-input checkbox-bulk-select-target" type="checkbox" id="checkbox-0" />
					<label className="custom-control-label" htmlFor="checkbox-0" />
                  </div>
                </td>
                <th className="align-middle">
                  <a href="javascript:void(0);">
					
					<div className="avatar avatar-xl me-3 fs-12" style={{top:'4px'}}>
							{ callStatus(item._source.log_details,item._source.department_id) }
					</div>
					
					  <span className="mb-3">{item._source.caller_number}</span>
					  </a>
                </th>
                <td className="align-middle fs-12">
					{callRemarks(item._source.log_details,item._source.department_id)}
				</td>
                <td className="align-middle fs-12">
					{currentdate===moment(item._source.start_time*1000).format('DD-MM-YYYY')?moment(item._source.start_time*1000).format('h:mm:ss a'):moment(item._source.start_time*1000).format('DD,MMM YYYY, h:mm:ss a')}
				</td>
                
                <td className="align-middle white-space-nowrap">
                  <div className="dropdown text-sans-serif">                     
                  </div>
                </td>
              </tr>
			  <tr className="collapse" id={`row${item._source.additional_parameters[0].vl}`}>
				<td></td>
				<td colSpan={5}>
					<table className="table table-bordered">
							<tbody>
								<tr>
									<td>UID: {item._source.additional_parameters[0].vl}</td>
									<td> <i className="fas fa-map-marker"></i> Region: {item._source.state}</td>
									<td> <i className="fa fa-clock"></i> Duration: {convertTime(item._source.duration)}</td>
									<td> 
									{ item._source.log_details.length>0? 
									<>
									{item._source.log_details[0].action==="received"?
										<div>
										<p>Recording:</p>
										<div style={{display:'inline-flex'}}>

											<div id={`div_audio_${item._source.additional_parameters[0].vl}`} style={{cursor:'pointer'}}>											
												<div className="avatar avatar-xl me-3" style={{top:'4px'}} onClick={unlockRecording.bind(this,`${item._source.filename}`,`${item._source.additional_parameters[0].vl}`)}>
												<div className="avatar-name rounded-circle bg-primary-subtle text-dark">
												<span className="fs-9 text-primary"><i className="fa fa-play-circle"></i></span>
												</div>
												</div>
											</div>


										<a href={item._source.fileurl}>
										<div className="avatar avatar-xl me-3" style={{top:'4px'}}>
										<div className="avatar-name rounded-circle bg-primary-subtle text-dark">
										<span className="fs-9 text-primary"><i className="fas fa-download"></i></span>
										</div>
										</div>
										</a>

											
											</div>
											</div>
											:null
												}
											</>
											:null
								}


									</td>
								</tr>
							</tbody>
					</table>
					</td>
			  </tr>
			  </tbody>
				):null
				}
			 
			 
			
			  
			 
          </table>

		  
			<div className="p-2">
			{
				pageno===1?
				<button className="btn btn-outline-info btn-sm me-2" disabled>Previous</button>
				:<button className="btn btn-outline-info btn-sm me-2" onClick={goprevious.bind(this,10)}>Previous</button>
			}
			{
				pageno>=totalPage?
				<button className="btn btn-outline-info btn-sm ms-2" disabled>Next</button>
				:<button className="btn btn-outline-info btn-sm ms-2" onClick={gonext.bind(this,10)}>Next</button>
			}

			</div>
			
		  </div>


}

		  
		
		</div>
    </div>
  </div>
</div>
</div>
);
};

export default ListCallerLogs;
