import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
// import Header from "./Components/Template/Header";
import Sidebar from "./Components/Template/Sidebar";
import Dashboard from "./Components/Admin/Dashboard";
import AddFirm from "./Components/Admin/Firms/AddFirm";
import ListFirm from "./Components/Admin/Firms/ListFirm";
import AddDepartment from "./Components/Admin/Department/AddDepartment";
import ListDepartment from "./Components/Admin/Department/ListDepartment";
import AddStaff from "./Components/Admin/Staff/AddStaff";
import ListStaff from "./Components/Admin/Staff/ListStaff";
import EditFirm from "./Components/Admin/Firms/EditFirm";
import EditDepartment from "./Components/Admin/Department/EditDepartment";
import ListSubDepartment from "./Components/Admin/Department/ListSubDepartment";
import AddSheets from "./Components/Admin/Datasheets/AddSheets";
import PrivateComponent from "./Components/PrivateComponent";
import Login from "./Login";
import EditStaff from "./Components/Admin/Staff/EditStaff";
import ListSheets from "./Components/Admin/Datasheets/ListSheets";
import AddNav from "./Components/Navs/AddNav";
import GrantAccess from "./Components/Navs/GrantAccess";
import CallingDash from "./Components/Calling/CallingDash";
import CallingList from "./Components/Calling/CallingList";
import StaffSetting from "./Components/Admin/Staff/StaffSetting";
import Profile from "./Components/Profile";
import History from "./Components/History";
import ListCustomer from "./Components/Admin/Customer/ListCustomer";
import EditSubDepartment from "./Components/Admin/Department/EditSubDepartment";
import AddCaller from "./Components/Admin/Caller/AddCaller";
import ListCaller from "./Components/Admin/Caller/ListCaller";
import ListCallerLogs from "./Components/Admin/Caller/ListCallerLogs";
import EditCaller from "./Components/Admin/Caller/EditCaller";
import SheetDetails from "./Components/Admin/Datasheets/SheetDetails";
import CallRecords from "./Components/Admin/Caller/CallRecords";
function App() {
  const auth=localStorage.getItem("adminLogin");

  return (
    <main className="main" id="top">

   {
            auth ?
            (<div className="container" data-layout="container">
        <Router>
        <Sidebar />
            <Routes>
            <Route element={<PrivateComponent />} >
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/add-firm" element={<AddFirm />} />
                <Route exact path="/list-firm" element={<ListFirm />} />
                <Route exact path="/edit-firm/:firmId" element={<EditFirm />} />
                <Route exact path="/add-department" element={<AddDepartment />} />
                <Route exact path="/list-department" element={<ListDepartment />} />
                <Route exact path="/edit-department/:departmentId" element={<EditDepartment />} />
                <Route exact path="/edit-sub-department/:subdepartmentid" element={<EditSubDepartment />} />
                <Route exact path="/list-sub-department" element={<ListSubDepartment />} />
                <Route exact path="/add-staff" element={<AddStaff />} />
                <Route exact path="/list-staff" element={<ListStaff />} />
                <Route exact path="/add-caller" element={<AddCaller />} />
                <Route exact path="/edit-staff/:staffId" element={<EditStaff />} />
                <Route exact path="/list-caller" element={<ListCaller />} />
                <Route exact path="/edit-caller/:callerid" element={<EditCaller />} />
                <Route exact path="/list-calling-logs" element={<ListCallerLogs />} />
                <Route exact path="/call-detailed-records" element={<CallRecords />} />
                <Route exact path="/add-sheet" element={<AddSheets />} />
                <Route exact path="/list-sheets" element={<ListSheets/>} />
                <Route path="/datasheet-details/:sheetid" element={<SheetDetails />} />

                <Route exact path="/add-navigation" element={<AddNav />} />
                <Route exact path="/grant-access" element={<GrantAccess />} />
                <Route exact path="/calling-records" element={<CallingDash />} />
                <Route exact path="/calling-list/:sheetid" element={<CallingList />} />
                <Route exact path="/staff-setting/:staffid" element={<StaffSetting />} />
                <Route exact path="/my-profile" element={<Profile />} />
                <Route exact path="/history" element={<History />} />
                <Route exact path="/list-customer" element={<ListCustomer />} />
                
                

            </Route>
            </Routes>
        </Router>
        </div>) : (
            <Router>
                <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="" element={<Login />} />
                </Routes>
            </Router>
        )}
    </main> 
    );
}

export default App;
