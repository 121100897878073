import React,{useEffect, useState} from "react";
import Header from "../Template/Header";
          
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";
import axios  from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from "../../Constant";
import { useNavigate } from "react-router-dom";
const initialSubMenuValues = {
  accesstype: "",
  staffid: "",
  departmentid: '',
  subdepartmentid:'',
  checkbox_mainmenu:[],
  child_nav_id:''
};

const GrantAccess=()=>{
  const redirect=useNavigate();
  const [staffRecords,setStaffRecords]=React.useState([]);
  const [departmentRecords,setDepartmentRecords]=React.useState([]);
  const [subdepartmentRecords,setSubdepartmentRecords]=React.useState([]);
  const [submenuRecords,setSubmenuRecords]=React.useState([]);
  let [grantedMainMenu,setGrantedMainMenu]=React.useState([]);

  const saveAccessGranted=useFormik({

    initialValues:initialSubMenuValues,
    validationSchema:"",
    onSubmit:(values,action)=>{
      Swal.fire({
        title: "Are you sure?",
        text: "You want to grant access!!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Grant it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
            var formData=new FormData();
            values.checkbox_mainmenu=grantedMainMenu;
            var checkMainMenu=values.checkbox_mainmenu;
            
            for(var val of checkMainMenu){
              var array = []
              var checkboxes = document.getElementsByName(`check_${val}[]`);
              for (var i = 0; i < checkboxes.length; i++) {
                if(checkboxes[i].checked){
                array.push(checkboxes[i].value)
                }
              }
              formData.append("check_"+val, array);
            }
            formData.append("accesstype", saveAccessGranted.values.accessradio);
            formData.append("staffid", values.staffid.value);
            formData.append("child_nav_id", values.child_nav_id);
            formData.append("departmentid", values.departmentid);
            formData.append("subdepartmentid", values.subdepartmentid);
            formData.append("checkbox_mainmenu", values.checkbox_mainmenu);
            
            console.log(formData);

            await axios.post(`${constant.SERVER_BASE_URL}privilege/save-privileges`,
            formData,
            {
              headers:{
                "Content-Type":"application/json",
                authorization:`Bearer ${localStorage.getItem('token')}`
              }
            }).then((resp)=>{
              console.log(resp.data);
              if(resp.data.status==="SUCCESS"){
                toast.success(resp.data.message, {
                  position: "top-center",
                  className: "text-center",
                });
              }else if(resp.data.status==="ERROR"){
                toast.error(resp.data.message, {
                  position: "top-center",
                  className: "text-center",
                });
              }else if(resp.data.status==="TOKEN_EXPIRED"){
                localStorage.removeItem("adminLogin");
                localStorage.removeItem("token");
                redirect("/login");
                window.location.reload();
              }
           
            }).then((err)=>{

              toast.error(err, {
                position: "top-center",
                className: "text-center",
              });

            });

        }
      });
    }
  });

 


      const listStaffs = async () => {
        try {
             await axios.get(
                `${constant.SERVER_BASE_URL}staff/get-employees`, {
                    headers: {
                        "Content-Type":"application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((resp)=>{
                  console.log(resp.data);
                  setStaffRecords(resp.data);
                });
            
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong!!", {
                position: "top-center",
                className: "text-center",
            });
        }
    };


    const listDepartments = async () => {
      try {
        await axios.get(
          `${constant.SERVER_BASE_URL}department/get-departments`,
          {
            headers: {
              "Content-Type":"application/json",
              authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          }).then((resp)=>{
            console.log(resp);
            setDepartmentRecords(resp.data);
          });
        
      } catch (error) {
        toast.error("Something went wrong!!", {
          position: "top-center",
          className: "text-center",
        });
      }
    };


    const listSubDepartments = async () => {
      try {
         await axios.get(
          `${constant.SERVER_BASE_URL}subdepartment/get-subdepartments`,
          {
            headers: {
              "Content-Type":"application/json",
              authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          }).then((resp)=>{
            console.log(resp.data);
            setSubdepartmentRecords(resp.data);
          });
        
      } catch (error) {
        toast.error("Something went wrong!!", {
          position: "top-center",
          className: "text-center",
        });
      }
    };



    const listAccessingNavs=async ()=>{
      try{
        await axios.get(`${constant.SERVER_BASE_URL}privilege/get-accessing-navs`,{
          headers:{
            "Content-Type":"application/json",
            authorization:`Bearer ${localStorage.getItem("token")}`
          }
        }).then((resp)=>{
          console.log(resp);
          setSubmenuRecords(resp.data);
        }).catch((err)=>{
          toast.error(
            err,
            {
              position: "top-center",
            }
          );
        });
      }catch(err){
        toast.error(
          err,
          {
            position: "top-center",
          }
        );
      }
    };


    useEffect(()=>{
      listStaffs();
      listDepartments();
      listSubDepartments();
      listAccessingNavs();
    },[]);


 const staffOptions=staffRecords.map(item=>({
      value:item._id,
      label:item.EmployeeName
 }));

 const departmentOptions=departmentRecords.map(item=>({
      value:item._id,
      label:item.departmentName
 }));

 const subdepartmentOptions=subdepartmentRecords.map(item=>({
  value:item._id,
  label:item.subDepartmentName
}));
  
    const checkParent=(parentid)=>{
     
      let paraentCheckBox=document.getElementById("checkbox_main_"+parentid);
      var objIndex = submenuRecords.findIndex(obj => obj._id == parentid);
      if(paraentCheckBox.checked){
              const submenudata=submenuRecords[objIndex].records;
              submenuRecords[objIndex].checked=true;
              const JsonSubmenudata=JSON.parse(submenudata);
             var records=[];
             
            JsonSubmenudata.forEach(element => {
              element.checked=true;
              records.push(element);
            });
            submenuRecords[objIndex].records = JSON.stringify(records);
           
          //  var newArr=grantedMainMenu.push(parentid);
           setGrantedMainMenu([...grantedMainMenu, parentid]);
           
           console.log("Pushed",grantedMainMenu);
      }else{
        submenuRecords[objIndex].checked=false;
          const submenudata=submenuRecords[objIndex].records;
          const JsonSubmenudata=JSON.parse(submenudata);
          var records=[];
          JsonSubmenudata.forEach(element => {
            element.checked=false;
              records.push(element);
          });

          submenuRecords[objIndex].records = JSON.stringify(records);

          
           let indexNewEle = grantedMainMenu.indexOf(parentid);          
          const newItems = grantedMainMenu.filter((_, i) => i !== indexNewEle);
        
          setGrantedMainMenu(newItems);
      }
    };



const selectStaff=async (event)=>{
  console.log(event);
  let e={
    target: {
      name: "staffid",
      value: event,
    },
  };
  saveAccessGranted.handleChange(e);

  var accessradio=saveAccessGranted.values.accessradio;
  var params={};
  if(accessradio==="EMPLOYEE"){
    var staffid=event.value;
    params.staffid=staffid;
    params.accessradio=accessradio;
  }
  
  




  await axios.get(`${constant.SERVER_BASE_URL}privilege/get-assigned-privileges-byaccesstype`,
    {
      params: {
        params
      },
    headers:{
      "Content-Type":"application/json",
      authorization:`Bearer ${localStorage.getItem('token')}`
    }
  }).then((resp)=>{

      if(resp.data.status==="TOKEN_EXPIRED"){
        localStorage.removeItem("adminLogin");
        localStorage.removeItem("token");
        redirect("/login");
        window.location.reload();
      }else{
        var dataObj=resp.data;
        console.log("Assigned",dataObj);

        console.log("OverAll",submenuRecords);
        var gmmArr=getColumn(dataObj,"_id");
        
        setGrantedMainMenu(gmmArr);
        var newPriviArray1=[];
        for(let item of submenuRecords){
          
            var dataresult=dataObj.filter(function(val){
              return val.result[0]._id===item._id
            });
          

            if(dataresult.length>0){
              item.checked=true;
              
            }else{
              if("checked" in item){
                delete item.checked;
              }
            }
            
            var arr2=[];
            var recordsJson=JSON.parse(item.records);
            for(let jtm of recordsJson){
              jtm.checked=false;
                for(let obj of dataObj){
                  var objRecords=obj.records;
                    for(let j of objRecords){
                      if(jtm._id===j.result_submenu[0]._id){
                          jtm.checked=true;
                      }
                    }
                }
                arr2.push(jtm);
            }

            item.records=JSON.stringify(arr2);
         



            newPriviArray1.push(item);

          
        }
        
        setSubmenuRecords(newPriviArray1);
        
    

      } 
  });
  function getColumn(arr, columnIndex) {
    return arr.map(row => row[columnIndex]);
}
 

}



const [navpermission, setNavpermission] = useState([]);

const handleNavClick = (manuId, navId) => {
  console.log(manuId, navId);
  setNavpermission((prev) => {
    const existingItem = prev.find((nav) => nav.manuid === manuId);
    if (existingItem) {
      // If item is already in state, toggle navId
      const updatedNavlid = existingItem.navlid.includes(navId)
        ? existingItem.navlid.filter(id => id !== navId)
        : [...existingItem.navlid, navId];
      
      return updatedNavlid.length > 0
        ? prev.map(nav => nav.manuid === manuId ? { ...nav, navlid: updatedNavlid } : nav)
        : prev.filter(nav => nav.manuid !== manuId);
    } else {
      // If item is not in state, add it
      return [...prev, { manuid: manuId, navlid: [navId] }];
    }
  });
};

console.log(navpermission,"navpermissionnavpermission");

  return (
    <div className="content">
      <Header />
      <div className=" mb-3">
        <form onSubmit={saveAccessGranted.handleSubmit}>
      <div className="row g-0">
  <div className="col-lg-6 pe-lg-2 mb-3">
    <div className="card overflow-hidden" style={{height:"500px"}}>
      <div className="card-header bg-body-tertiary">
        <div className="row align-items-center">
          <div className="col">
            <h6 className="mb-0">Grant Access</h6>
          </div>
          
        </div>
      </div>
      <div className="card-body p-0">
      
      <div className="row align-items-center m-2">
        <div className="">
          
          <div className="form-check form-check-inline">
              <input className="form-check-input" id="employee" onChange={saveAccessGranted.handleChange} onBlur={saveAccessGranted.handleBlur} type="radio" name="accessradio" defaultValue="EMPLOYEE" />
              <label className="form-check-label small" htmlFor="employee">Employee</label>
          </div>
          <div className="form-check form-check-inline">
              <input className="form-check-input" id="department" type="radio"  onChange={saveAccessGranted.handleChange} onBlur={saveAccessGranted.handleBlur} name="accessradio" defaultValue="DEPARTMENT" />
              <label className="form-check-label small" htmlFor="department">Department</label>
          </div>
          <div className="form-check form-check-inline">
              <input className="form-check-input" id="sub-department" type="radio"  onChange={saveAccessGranted.handleChange} onBlur={saveAccessGranted.handleBlur} name="accessradio" defaultValue="SUBDEPARTMENT" />
              <label className="form-check-label small" htmlFor="sub-department">Sub-Department</label>
          </div>

        </div>

          <div className="col ps-x1 py-1 position-static">

            { saveAccessGranted.values.accessradio==="EMPLOYEE"?
              <div className="mb-3">
              <label className="form-label" htmlFor="staffid">Select Staff</label>
              <Select name="staffid" id="staffid" placeholder="Select" onBlur={saveAccessGranted.handleBlur} 
              onChange={selectStaff.bind(this)}  options={staffOptions}/>
              </div>:null
            }

{ saveAccessGranted.values.accessradio==="DEPARTMENT"?
          <>
          <div className="mb-3">
            <label className="form-label" htmlFor="departmentid">Select Firms</label>
            <Select name="departmentid" id="departmentid" placeholder="Select" onBlur={saveAccessGranted.handleBlur} 
            onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "departmentid",
                                value: selectedOption,
                              },
                            };
                            saveAccessGranted.handleChange(event);
                          }}
                          options={departmentOptions}
                        />
            </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="departmentid">Select Department</label>
            <Select name="departmentid" id="departmentid" placeholder="Select" onBlur={saveAccessGranted.handleBlur} onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "departmentid",
                                value: selectedOption,
                              },
                            };
                            saveAccessGranted.handleChange(event);
                          }}

                          options={departmentOptions}
                          
                        />
            </div>
          </>
          
          :null
}


{ saveAccessGranted.values.accessradio==="SUBDEPARTMENT"?
          
          <>
            <div className="mb-3">
              <label className="form-label" htmlFor="subdepartmentid">Select Sub Firms</label>
              <Select name="subdepartmentid" id="subdepartmentid" placeholder="Select" onBlur={saveAccessGranted.handleBlur} onChange={(selectedOption) => {
                              let event = {
                                target: {
                                  name: "subdepartmentid",
                                  value: selectedOption,
                                },
                              };
                              saveAccessGranted.handleChange(event);
                            }}

                            options={subdepartmentOptions}
                          />
              </div>
          

              <div className="mb-3">
              <label className="form-label" htmlFor="subdepartmentid">Select Department</label>
              <Select name="subdepartmentid" id="subdepartmentid" placeholder="Select" onBlur={saveAccessGranted.handleBlur} onChange={(selectedOption) => {
                              let event = {
                                target: {
                                  name: "subdepartmentid",
                                  value: selectedOption,
                                },
                              };
                              saveAccessGranted.handleChange(event);
                            }}

                            options={subdepartmentOptions}
                          />
              </div>


              <div className="mb-3">
              <label className="form-label" htmlFor="subdepartmentid">Select Sub Department</label>
              <Select name="subdepartmentid" id="subdepartmentid" placeholder="Select" onBlur={saveAccessGranted.handleBlur} onChange={(selectedOption) => {
                              let event = {
                                target: {
                                  name: "subdepartmentid",
                                  value: selectedOption,
                                },
                              };
                              saveAccessGranted.handleChange(event);
                            }}

                            options={subdepartmentOptions}
                          />
              </div>
          </>
          
          
          :null
}



        <div className="mb-3">
            <button className="btn btn-sm btn-outline-info">submit</button>
        </div>




          </div>

        </div>

       
       
      </div>

    </div>
  </div>
  <div className="col-lg-6 pe-lg-2 mb-3">
    <div className="card h-lg-100 overflow-hidden">
      <div className="card-header bg-body-tertiary">
        <div className="row align-items-center">
          <div className="col">
            <h6 className="mb-0">Navigations</h6>
          </div>
     
        </div>
      </div>
      <div className="card-body p-0">
            {submenuRecords?submenuRecords.map((item)=>
             <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200" key={item._id}>
             <div className="col ps-x1 py-1 position-static">
             <div className="d-flex align-items-center">
              
              <div className="flex-1">

                  <h6 className="mb-0 d-flex align-items-center" key={item._id}>
                    <div className="form-check fs-9 mb-0 d-flex align-items-center" onClick={checkParent.bind(this,item._id)}>
                    <input className="form-check-input" checked={item.checked===true?"checked":null}  id={`checkbox_main_${item._id}`}    value={item._id} onChange={saveAccessGranted.handleChange} onBlur={saveAccessGranted.handleBlur} type="checkbox" name="checkbox_mainmenu" />
                    </div>
                  
                    <label className="text-800 stretched-link" htmlFor={`checkbox_main_${item._id}`}><i className={item.mainmenuicon}></i> {item.MainMenu} {item.checked?item.checked:null}</label>
                  </h6>

                  {
                    item.records?JSON.parse(item.records).map((i)=>
                  <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200" key={i._id}>
                       <div className="col ps-x1 py-1 position-static">
                          <div className="d-flex align-items-center">
                          <div className="flex-1" key={i._id}>
                          <h6 className="mb-0 d-flex align-items-center" key={i._id}>
                          <div className="form-check fs-9 mb-0 d-flex align-items-center">
                            <input className="form-check-input" checked={i.checked?i.checked:null}  type="checkbox"   value={i._id} name={"check_"+item._id+"[]"} id={i._id} onChange={saveAccessGranted.handleChange} onBlur={saveAccessGranted.handleBlur} />
                            </div> 
                            <label className="text-800 stretched-link" htmlFor={i._id}><i className={i.submenuicon}></i> {i.submenu} </label>
                          </h6>
                          
                          </div>


                          </div>
                       </div>
                  </div>
                    ):null
                  }
                {
            item.navinfo.length > 0 && (
              <ul className="list-unstyled" style={{ marginLeft:"52px", cursor:"pointer" }}>
                <li>
                  <ul className="list-unstyled">
                  {item.navinfo.map(navItem => (
                  <li key={navItem?._id} className="align-items-left">
                    
                    
                    <label
                      className=" stretched-link badge rounded-pill bg-secondary text-white"

                    
                      style={{  cursor:"pointer" }}
                      onClick={() => handleNavClick(navItem.menuid, navItem?._id)}
                    >
                      {navItem?.navname}
                    
                    </label>
                  </li>
                ))}
                  </ul>
                </li>
               
             
          
              </ul>
            )
          }


              </div>
            </div>
            </div>
              
        </div>
            ):null
          }

      
        
     
      </div>

    </div>
  </div>
</div>
</form>
      </div>
      
    </div>
  );
};

export default GrantAccess;
